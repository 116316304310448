import FieldValidationError from "../components/error-messages/field-validation-error";
import Select from "react-select";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { useContext, useRef, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import PuffLoader from "react-spinners/PuffLoader";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../libs/auth";
import { SwitchProfile } from "../context/switchProfile.context";
import { toTitleCase } from "../utils/capitalize";

const SellerModal = ({ productDetail, buyerRole }) => {
  const CloseRef = useRef(null);
  const [we_are, setwe_are] = useState("");
  const [we_areval, setwe_areval] = useState(false);
  const navigate = useNavigate();
  const [dataCompany, setDataCompany] = useState({
    gst_number: "",
    address: "",
    pincode: "",
  });

  const [FilterAvail, setFilterAvail] = useState({
    state_Id: "",
    city_Id: "",
  });

  const [regionAvail, setRegionAvail] = useState({
    state_Id: "",
    city_Id: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: "",
    pincode: "",
  });

  const [stateList, setStateList] = useState({
    items: [],
    has_more: false,
  });
  const [cityList, setCityList] = useState([]);

  const [GSTValidation, setGSTValidation] = useState();
  const [companyData, setCompanyData] = useState();
  const [Nulldata, setNullData] = useState(false);
  const [NulldataForPan, setNullDataForPan] = useState(false);
  const [verify, setVerify] = useState(false);
  const GSTregx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const [gst_fetched_Data, setgst_fetched_Data] = useState({});
  const [gst_verification, setgst_verification] = useState(false);
  const [AlreadyExist, setAlreadyExist] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [GstLoading, setGstLoading] = useState(false);

  async function GstVerification() {
    const formdata = new FormData();
    formdata.append("gst_number", dataCompany.gst_number);

    await post("/general/get_gst_details", formdata)
      .then((res) => {
        setGstLoading(true);
        if (res.status === 200) {
          setgst_verification(true);

          setgst_fetched_Data(res.data.data);
          // alert(res.data.data.address);
          setDataCompany({ ...dataCompany, address: res.data.data.address });
          // setCompanyData({...companyData,address:res.data.data.address})
          setGstLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAlreadyExist(true);
          setGstLoading(false);
        }
        if (err.response.status === 429) {
          alert(
            "Too many request in the Short Time , Please Wait for Some time"
          );
          setGstLoading(false);
        }
      });
  }

  const stateRef = useRef(null);

  function IntialState() {
    get("/general/region_listing?region_id=1")
      .then((response) => {
        const sortedData = response?.data?.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setStateList((p) => ({
          ...p,
          items: sortedData,
        }));

        setStateList((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function City() {
    get(`/general/region_listing?region_id=${FilterAvail?.state_Id}`)
      .then((response) => {
        const sortedData = response.data.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setCityList(sortedData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  async function fetchData() {
    const formdata = new FormData();
    formdata.append("we_are", we_are);
    formdata.append("gst_number", dataCompany?.gst_number);
    formdata.append("pan_number", gst_fetched_Data?.pan_number);
    formdata.append("billing_address", gst_fetched_Data?.address);
    formdata.append(
      "billing_pin_code",
      gst_fetched_Data?.gst_details?.pradr?.addr?.pncd
    );
    formdata.append("billing_region_id", gst_fetched_Data?.region?.id);
    formdata.append("region_id", FilterAvail?.city_Id);
    formdata.append("pin_code", shippingAddress?.pincode);
    formdata.append("address", shippingAddress?.address);
    formdata.append("company_name", gst_fetched_Data?.gst_details?.tradeNam);

    try {
      const data = await postwithOu(
        "/user/account_switch",
        getAuthConfig(),
        formdata
      );
      if (data.status === 200) {
        window.location.reload();
        setLoading(false);
        navigate("/home");
        CloseRef.current.click();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  }

  return (
    <>
      <div
        className="modal fade post-social-modal"
        id="sellerModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="sellerModal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Seller Authentication
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="field-set signup-radio">
                <label className="form-label">
                  We are <span className="mendatory">*</span>
                </label>
                <div className="form-group row">
                  <div className="form-field col-sm">
                    <input
                      type="radio"
                      name="we_are"
                      id="Wholesaler"
                      onClick={(e) => {
                        setwe_are("wholesaler");
                      }}
                    />
                    <label htmlFor="Wholesaler">Wholesaler</label>
                  </div>
                  <div className="form-field col-sm">
                    <input
                      type="radio"
                      name="we_are"
                      id="Manufacture"
                      onClick={(e) => {
                        setwe_are("manufacturer");
                      }}
                    />
                    <label htmlFor="Manufacture">Manufacturer</label>
                  </div>
                  <div className="form-field col-sm">
                    <input
                      type="radio"
                      name="we_are"
                      id="Retailer"
                      onClick={(e) => {
                        setwe_are("retailer");
                      }}
                    />
                    <label htmlFor="Retailer">Retailer</label>
                  </div>
                  <div className="form-field col-sm">
                    <input
                      type="radio"
                      name="we_are"
                      id="Dealer"
                      onClick={(e) => {
                        setwe_are("dealer");
                      }}
                    />
                    <label htmlFor="Dealer">Dealer</label>
                  </div>
                  <div className="form-field col-sm">
                    <input
                      type="radio"
                      name="we_are"
                      id="Distributor"
                      onClick={(e) => {
                        setwe_are("distributor");
                      }}
                    />
                    <label htmlFor="Distributor">Distributor</label>
                  </div>
                </div>

                {we_areval === true ? (
                  <FieldValidationError name="work area" />
                ) : (
                  ""
                )}
              </div>

              <div className="steps__content">
                <strong>Company Information</strong>
                <p>
                  Kindly write your company information for authentication. This
                  will help you to create verified business profile.
                </p>
                <form>
                  <div className="personal-info">
                    <div className=" row">
                      <div className="form-field col-sm-6">
                        <label className="form-label">
                          GST number
                          <span className="tooltip-icon">
                            <OverlayTrigger
                              key="gst-tip"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-gst-tip">
                                  Kindly enter your company's GST number to
                                  verify
                                </Tooltip>
                              }
                            >
                              <InfoIcon />
                            </OverlayTrigger>
                          </span>
                        </label>

                        <div className="d-flex">
                          <input
                            type="text"
                            name="gst_number"
                            className="form-input"
                            placeholder="Enter your GST number"
                            value={dataCompany?.gst_number}
                            onChange={(e) => {
                              setgst_verification(false);
                              setDataCompany({
                                ...dataCompany,
                                gst_number: e.target.value,
                              });
                              setNullData(false);
                              setAlreadyExist(false);
                              if (e.target.value != "") {
                                if (GSTregx.test(e.target.value)) {
                                  setGSTValidation(true);
                                } else {
                                  setGSTValidation(false);
                                }
                              }
                              if (e.target.value === "") {
                                setGSTValidation();
                              }
                              setgst_verification(false);
                            }}
                            contentEditable={
                              companyData?.gst_number === null
                                ? "false"
                                : "true"
                            }
                            onBlur={(e) => {
                              if (GSTValidation === true) {
                                setVerify(true);
                              }
                            }}
                          />
                          <button
                            type="button"
                            className={
                              GSTValidation === true &&
                              gst_verification === true
                                ? "btn btn-sm button-green"
                                : GSTValidation === true
                                ? "btn btn-sm btn-warning"
                                : ` btn btn-sm btn-light  button-otp`
                            }
                            disabled={GSTValidation === true ? false : true}
                            onClick={(e) => {
                              GstVerification();
                            }}
                          >
                            {gst_verification === true ? (
                              <TickIcon />
                            ) : GstLoading === true ? (
                              <PuffLoader loading={true} size={15} />
                            ) : (
                              " Verify"
                            )}
                          </button>
                        </div>
                        {AlreadyExist === true ? (
                          <FieldValidationError message=" Gst Number is already taken " />
                        ) : (
                          ""
                        )}

                        {/* 
              {      gst_verification === false ? (
                <FieldValidationError message="verify Your GST " />
              ) : (
                ""
              )} */}
                        {GSTValidation === false ? (
                          <FieldValidationError message="Your GST Data Is Not Valid" />
                        ) : (
                          ""
                        )}

                        {Nulldata === true ? (
                          <FieldValidationError message="Please Add Valid GST Number" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field col-sm-6">
                        <label className="form-label">
                          PAN <span>(associated with GST)</span>
                        </label>
                        <input
                          type="text"
                          name="cname"
                          id="cname"
                          className="form-input"
                          value={gst_fetched_Data?.pan_number}
                          disabled
                          onChange={(e) => {
                            setCompanyData({
                              ...companyData,
                              pan_number: e.target.value,
                            });
                            setNullDataForPan(false);
                          }}
                        />
                        {NulldataForPan === true ? (
                          <FieldValidationError message="Please Add Valid PAN  Number" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-field col-sm-6">
                        <label className="form-label">
                          Company name
                          <span className="mendatory"> *</span>
                        </label>
                        <input
                          type="text"
                          name="cname"
                          id="cname"
                          className="form-input"
                          value={gst_fetched_Data?.gst_details?.tradeNam}
                          disabled
                        />
                      </div>
                      <div className="form-field col-sm-6">
                        <label className="form-label">
                          Billing Address <span className="mendatory">*</span>
                          <span className="tooltip-icon">
                            <OverlayTrigger
                              key="mobile-tip"
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-mobile-tip">
                                  You can modify the address if you need
                                </Tooltip>
                              }
                            >
                              <InfoIcon />
                            </OverlayTrigger>
                          </span>
                        </label>
                        <input
                          type="text"
                          name="cname"
                          id="cname"
                          className="form-input"
                          value={gst_fetched_Data?.address}
                          disabled
                        />
                      </div>
                    </div>
                    <div className=" row">
                      <div className="form-field col-sm-4">
                        <label className="form-label">
                          State <span className="mendatory">*</span>
                        </label>
                        {gst_fetched_Data?.region?.parent?.name === null ? (
                          <Select
                            ref={stateRef}
                            placeholder="Select State"
                            id="State"
                            onFocus={(e) => {
                              e.preventDefault();

                              IntialState();
                            }}
                            options={
                              stateList?.items &&
                              stateList?.items?.map(function (productArray) {
                                return {
                                  value: productArray.id,
                                  label: toTitleCase(productArray.name),
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              // searcCat(e.target.value);
                            }}
                            onChange={(e) => {
                              setRegionAvail((p) => ({
                                ...p,
                                state_Id: e.value,
                              }));
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                          ></Select>
                        ) : (
                          <>
                            <select className="form-input" disabled>
                              <option>
                                {toTitleCase(gst_fetched_Data?.region?.parent?.name)}
                              </option>
                            </select>
                          </>
                        )}
                      </div>

                      <div className="form-field col-sm-4">
                        <label className="form-label">
                          City <span className="mendatory">*</span>{" "}
                        </label>
                        {gst_fetched_Data?.region?.name === null ? (
                          <Select
                            placeholder="Select city"
                            id="city"
                            onFocus={(e) => {
                              e.preventDefault();
                              City();
                            }}
                            options={
                              cityList &&
                              cityList.map(function (productArray) {
                                return {
                                  value: productArray.id,
                                  label: toTitleCase(productArray.name),
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              // searcCat(e.target.value);
                            }}
                            onChange={(e) => {
                              setRegionAvail((p) => ({
                                ...p,
                                city_Id: e.value,
                              }));
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                          ></Select>
                        ) : (
                          <>
                            <select className="form-input" disabled>
                              <option>{toTitleCase(gst_fetched_Data?.region?.name)}</option>
                            </select>
                          </>
                        )}
                      </div>
                      <div className="form-field col-sm-4">
                        <label className="form-label">
                          Pincode <span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          name="pincode"
                          className="form-input"
                          placeholder="Enter your pincode"
                          value={
                            gst_fetched_Data?.gst_details?.pradr?.addr?.pncd
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className=" row">
                      <div className="form-field col-sm">
                        <label className="form-label">
                          Shipping Address <span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          name="shippingAddress"
                          className="form-input"
                          placeholder="Enter your Shipping Address"
                          value={shippingAddress?.address}
                          onChange={(e) => {
                            e.preventDefault();
                            setShippingAddress((p) => ({
                              ...p,
                              address: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className=" row">
                      <div className="form-field col-sm-4">
                        <label className="form-label">Shipping State</label>
                        <Select
                          ref={stateRef}
                          placeholder="Select State"
                          id="State"
                          onFocus={(e) => {
                            e.preventDefault();

                            IntialState();
                          }}
                          options={
                            stateList?.items &&
                            stateList?.items?.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          onKeyDown={(e) => {
                            // searcCat(e.target.value);
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              state_Id: e.value,
                            }));
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                        ></Select>
                      </div>
                      <div className="form-field col-sm-4">
                        <label className="form-label">City</label>
                        <Select
                          placeholder="Select city"
                          id="city"
                          onFocus={(e) => {
                            e.preventDefault();
                            City();
                          }}
                          options={
                            cityList &&
                            cityList.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          onKeyDown={(e) => {
                            // searcCat(e.target.value);
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              city_Id: e.value,
                            }));
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                          }}
                        ></Select>
                      </div>
                      <div className="form-field col-sm-4">
                        <label className="form-label">
                          Shipping Pincode <span className="mendatory">*</span>
                        </label>
                        <input
                          type="text"
                          name="pincode"
                          className="form-input"
                          placeholder="Enter your pincode"
                          value={shippingAddress?.pincode}
                          onChange={(e) => {
                            e.preventDefault();
                            setShippingAddress((p) => ({
                              ...p,
                              pincode: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="button button-primary"
                disabled={
                  gst_verification === true && we_are != "" ? false : true
                }
                onClick={() => {
                  fetchData();
                }}
              >
                {Loading === true ? (
                  <PuffLoader loading={true} size={15} />
                ) : (
                  "Save Details"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SellerModal;
