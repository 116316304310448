import { useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import Select from "react-select";
import FieldValidationError from "../components/error-messages/field-validation-error";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";

function ReportModal({ id }) {
  const [description, setDescription] = useState("");
  // const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(false);
  const [contentReason, setContentReason] = useState([]);
  const [reasonID, setReasonID] = useState();
  const closeRef = useRef();
  const [NullData, setNullData] = useState({
    reson: false,
    msg: false,
  });

  const reportUser = async () => {
    setLoading(true);
    try {
      if (reasonID === "") {
        setNullData((p) => ({ ...p, reson: true }));
        return false;
      }
      if (description === "") {
        setNullData((p) => ({ ...p, msg: true }));
        return false;
      }
      const reportUserData = new FormData();
      if (sessionStorage.getItem("post")) {
        reportUserData.append("reason_id", reasonID);
        reportUserData.append("type", "product");
        reportUserData.append("description", description);
        if (sessionStorage.getItem("type") === "social_post") {
          reportUserData.append(
            "social_post_id",
            sessionStorage.getItem("reportid")
          );
        } else {
          reportUserData.append(
            "product_id",
            sessionStorage.getItem("reportid")
          );
        }
      } else if (sessionStorage.getItem("type")) {
        reportUserData.append("reason_id", reasonID);
        reportUserData.append("type", "comment");
        reportUserData.append("description", description);
        if (sessionStorage.getItem("type") === "social_post") {
          reportUserData.append(
            "social_post_id",
            sessionStorage.getItem("reportid")
          );
        } else {
          reportUserData.append(
            "product_id",
            sessionStorage.getItem("reportid")
          );
        }
        reportUserData.append("comment_id", sessionStorage.getItem("comid"));
      } else {
        reportUserData.append("reason_id", reasonID);
        reportUserData.append("type", "user");
        reportUserData.append("user_id", id);
        reportUserData.append("description", description);
      }

      await postwithOu("/general/report", getAuthConfig(), reportUserData);

      if (sessionStorage.getItem("comid")) {
        swal("Success", "Comment Reported SuccessFully", "success");
      } else if (sessionStorage.getItem("post")) {
        swal("Success", "Post Reported SuccessFully", "success");
      } else {
        swal("Success", "User Reported SuccessFully", "success");
      }
      sessionStorage.removeItem("comid");
      sessionStorage.removeItem("reportid");
      sessionStorage.removeItem("type");
      sessionStorage.removeItem("post");
      setReasonID("");
      setDescription("");
      selectInputRef.current.commonProps.setValue("");

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
    closeRef.current.click();
  };

  const handleClose = () => {
    closeRef.current.click();
    sessionStorage.removeItem("comid");
    sessionStorage.removeItem("reportid");
    sessionStorage.removeItem("type");
    setReasonID("");
    setDescription("");
    selectInputRef.current.commonProps.setValue("");
  };

  const selectInputRef = useRef(null);

  const handleReasonDropdown = async () => {
    let url;

    if (sessionStorage.getItem("type") === "product") {
      url = `/general/content?search&type=product_report_reason`;
    } else if (sessionStorage.getItem("type") === "post_to_sell") {
      url = `/general/content?search&type=post_sell_report_reason`;
    } else if (sessionStorage.getItem("type") === "post_to_buy") {
      url = `/general/content?search&type=post_buy_report_reason`;
    } else if (sessionStorage.getItem("type") === "social_post") {
      url = `/general/content?search&type=social_post_report_reason`;
    } else {
      url = "/general/content?search&type=user_report_reason";
    }
    await get(url, getAuthConfig())
      .then((res) => {
        setContentReason(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade report-modal"
        id="ReportModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ReportModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Report
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
                onClick={(e) => {
                  e.preventDefault();
                  sessionStorage.removeItem("comid");
                  sessionStorage.removeItem("reportid");
                  sessionStorage.removeItem("type");
                  setReasonID("");
                  setDescription("");
                  selectInputRef.current.commonProps.setValue("");
                }}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <h6>What do you want to do?</h6>

              <div className="form-field">
                <Select
                  ref={selectInputRef}
                  placeholder="select reason"
                  onFocus={(e) => {
                    e.preventDefault();
                    handleReasonDropdown();
                  }}
                  options={contentReason.map(function (productArray) {
                    return {
                      value: productArray.id,
                      label: productArray.title,
                    };
                  })}
                  onChange={(e) => {
                    setReasonID(e.value);
                    setNullData((p) => ({ ...p, reson: false }));
                  }}
                ></Select>
                {NullData?.reson === true ? (
                  <FieldValidationError message="Please select reseaon" />
                ) : (
                  ""
                )}
              </div>

              <div className="form-field">
                <label className="form-label">Message</label>
                <textarea
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);

                    setNullData((p) => ({ ...p, msg: false }));
                  }}
                  className="form-input"
                  placeholder="Enter Your Message"
                ></textarea>
                {NullData?.msg === true ? (
                  <FieldValidationError message="Please enter reseaon" />
                ) : (
                  ""
                )}
              </div>
              <div className="report-info">
                Report post, comment, or message
              </div>
            </div>
            <div className="modal-footer">
              <button
                onClick={handleClose}
                type="button"
                className="button button-secondary"
              >
                Go Back
              </button>
              <button
                disabled={loading ? true : false}
                onClick={reportUser}
                type="button"
                className="button button-primary"
              >
                Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportModal;
