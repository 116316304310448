import { useContext, useEffect, useState } from "react";
import "../../common/scss/pages/settings.scss";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import FieldValidationError from "../error-messages/field-validation-error";
import { get, getAuthConfig, postwithOu, post } from "../../libs/http-hydrate";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { ReactComponent as TickIcon } from "../../assets/images/tick-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Auth from "../../libs/auth";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";

function AddCompanyDetails(Data) {
  const user = Auth.getCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    pan: "",
    companyName: "",
    shipping: "",
    billing: "",
    state: "",
    city: "",
    region_id: "",
    stateShipping: "",
    cityShipping: "",
    region_idShipping: "",
    pincode: "",
    pincodeShipping: "",
  });

  const [stateList, setStateList] = useState({
    items: [],
    has_more: false,
  });
  const [cityList, setCityList] = useState([]);
  const [FilterAvail, setFilterAvail] = useState({
    search: "",
    state_Id: "",
    city_Id: "",
    industry_Id: "",
    category_id: "",
    subcategory_id: "",
    Filter: "",
    count: "",
  });
  function IntialState() {
    get("/general/region_listing?region_id=1")
      .then((response) => {
        const sortedData = response?.data?.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setStateList((p) => ({
          ...p,
          items: sortedData,
        }));

        setStateList((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function City() {
    get(`/general/region_listing?region_id=${FilterAvail?.state_Id}`)
      .then((response) => {
        const sortedData = response.data.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setCityList(sortedData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const [companyData, setCompanyData] = useState();
  const [Nulldata, setNullData] = useState(false);
  const [Nulldatacompany, setNullDatacompany] = useState(false);
  const [NulldataForPan, setNullDataForPan] = useState(false);
  const [NulldataForbill, setNulldataForbill] = useState(false);
  const [Nulldatashipping, setNullDatashipping] = useState(false);

  const [GSTValidation, setGSTValidation] = useState();
  const [verify, setVerify] = useState(false);
  const GSTregx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const [dataCompany, setDataCompany] = useState({
    gst_number: "",
    address: "",
  });
  const [gst_fetched_Data, setgst_fetched_Data] = useState({});
  const [gst_verification, setgst_verification] = useState(false);
  const [Verification_of_gst, setVerification_of_gs] = useState(false);
  const [AlreadyExist, setAlreadyExist] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [GstLoading, setGstLoading] = useState(false);
  const [Logo, setLogo] = useState({ logo: "" });
  useEffect(() => {
    const da = Data.TestIngData;
    setCompanyData(da);
  });

  async function GstVerification(e) {
    const formdata = new FormData();
    formdata.append("gst_number", dataCompany.gst_number);

    await post("/general/get_gst_details", formdata)
      .then((res) => {
        if (res.status === 200) {
          setgst_verification(true);

          setgst_fetched_Data(res.data.data);

          if (res?.data?.data?.pan_number) {
            setCompany((p) => ({ ...p, pan: res.data.data.pan_number }));
          }
          if (res?.data?.data?.gst_details?.tradeNam) {
            setCompany((p) => ({
              ...p,
              companyName: res.data.data.gst_details?.tradeNam,
            }));
          }
          if (res?.data?.data?.address) {
            setCompany((p) => ({ ...p, billing: res.data.data?.address }));
          }
          if (res?.data?.data?.region?.parent?.name) {
            setCompany((p) => ({
              ...p,
              state: capitalizeFirstLetter(
                res?.data?.data?.region?.parent?.name
              ),
            }));
          }
          if (res?.data?.data?.region?.name) {
            setCompany((p) => ({
              ...p,
              state: capitalizeFirstLetter(res?.data?.data?.region?.name),
            }));
          }

          if (res?.data?.data?.region?.id) {
            // setFilterAvail((p) => ({
            //   ...p,
            //   city_Id: res?.data?.data?.region?.id,
            // }));
            setCompany((p) => ({ ...p, city: res?.data?.data?.region?.id }));
          }

          if (res?.data?.data?.gst_details?.pradr?.addr?.pncd) {
            setCompany((p) => ({
              ...p,
              pincode: res?.data?.data?.gst_details?.pradr?.addr?.pncd,
            }));
          }

          // alert(res.data.data.address);
          setDataCompany({ ...dataCompany, address: res.data.data.address });
          // setCompanyData({...companyData,address:res.data.data.address})
          setGstLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error(err?.response?.data?.message);
          setAlreadyExist(true);
          setGstLoading(false);
        }
        if (err.response.status === 429) {
          toast.error(
            "Too many request in the Short Time , Please Wait for Some time"
          );
          setGstLoading(false);
        }
      });
  }
  function capitalizeFirstLetter(str) {
    if (str) {
      // converting first letter to uppercase
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

      return capitalized;
    }
  }
  async function EditCompanyData(e) {
    e.preventDefault();

    const url = "/user/setting/company_details/edit/" + companyData?.id;
    const formdata = new FormData();

    formdata.append("logo", Logo.logo);
    formdata.append("gst_number", dataCompany?.gst_number);
    formdata.append("email", companyData?.email);
    formdata.append("billing_address", dataCompany?.address);
    formdata.append("mobile_number", companyData?.mobile_number);
    formdata.append("pan_number", gst_fetched_Data?.pan_number);
    formdata.append("region_id", gst_fetched_Data?.region?.id);
    formdata.append("name", gst_fetched_Data?.gst_details?.tradeNam);
    formdata.append("shipping_address", company?.shipping);
    formdata.append("billing_region_id", company?.city);
    formdata.append("shipping_pin_code", company?.pincodeShipping);
    formdata.append("billing_pin_code", company?.pincode);
    try {
      const response = await post(url, formdata, getAuthConfig());
      if (response.status === 200) {
        setLoading(false);
        // props.UpdateTrue();
        if (location?.state?.redirectid) {
          navigate("/product-company-detail", {
            state: { id: location?.state?.redirectid },
          });
        }
        Data.UpdateTrue();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function saveCompany(e) {
    e.preventDefault();

    if (company?.pan.length != 10) {
      setNullDataForPan(true);
      setLoading(false);
      return false;
    }

    if (company?.companyName === "") {
      setNullDatacompany(true);
      setLoading(false);
      return false;
    }

    if (company?.billing === "" || gst_fetched_Data?.address === "") {
      setNulldataForbill(true);
      setLoading(false);
      return false;
    }

    if (company?.shipping === "") {
      setNullDatashipping(true);
      setLoading(false);
      return false;
    }

    const url = "/user/setting/company_details/edit/" + companyData?.id;
    const formdata = new FormData();

    formdata.append("logo", Logo.logo);
    formdata.append("gst_number", dataCompany?.gst_number);
    formdata.append("email", companyData?.email);
    formdata.append("billing_address", company?.billing);
    formdata.append("mobile_number", companyData?.mobile_number);
    formdata.append("pan_number", company?.pan);
    formdata.append("region_id", company?.cityShipping);
    formdata.append("name", company?.companyName);
    formdata.append("shipping_address", company?.shipping);
    formdata.append("billing_region_id", company?.city);
    formdata.append("shipping_pin_code", company?.pincodeShipping);
    formdata.append("billing_pin_code", company?.pincode);

    try {
      const response = await post(url, formdata, getAuthConfig());
      setLoading(false);
      // props.UpdateTrue();
      if (location?.state?.redirectid) {
        navigate("/product-company-detail", {
          state: { id: location?.state?.redirectid },
        });
      }
      Data.UpdateTrue();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  return (
    <>
      <ToastContainer />
      <div className="edit-details">
        <div className="section-title">
          <h6>Enter Company Details</h6>
        </div>
        {user?.is_buyer == 0 && user?.verfied_at === null ? (
          <form onSubmit={EditCompanyData}>
            <div className="upload">
              <div className="upload-image">
                <input
                  type="file"
                  id="company_logo"
                  onChange={(e) => {
                    if (e.target.files[0].size > 2097152) {
                      alert("Not More than 2 MB is allowded");
                      return false;
                    }

                    setLogo({ ...Logo, logo: e.target.files[0] });
                  }}
                />
                <label htmlFor="company_logo">
                  <UploadIcon />

                  <span>
                    {Logo?.logo?.name === undefined ? (
                      <>
                        Drag n Drop here Or{" "}
                        <span className="color-primary">Browse</span>
                      </>
                    ) : (
                      <>
                        <img
                          src={URL.createObjectURL(Logo.logo)}
                          alt="Uploaded Logo"
                          className="preview-image"
                        />
                      </>
                    )}
                  </span>
                </label>
              </div>
              <div className="upload-fields">
                <div className="form-field">
                  <label className="form-label">GST No</label>
                  <div className="d-flex ">
                    <input
                      type="text"
                      name="cname"
                      id="cname"
                      className="form-input"
                      value={dataCompany?.gst_number}
                      onChange={(e) => {
                        setgst_verification(false);
                        setDataCompany({
                          ...dataCompany,
                          gst_number: e.target.value,
                        });
                        setNullData(false);
                        setAlreadyExist(false);
                        if (e.target.value != "") {
                          if (GSTregx.test(e.target.value)) {
                            setGSTValidation(true);
                          } else {
                            setGSTValidation(false);
                          }
                        }
                        if (e.target.value === "") {
                          setGSTValidation();
                        }
                        setgst_verification(false);
                      }}
                      contentEditable={
                        companyData?.gst_number === null ? "false" : "true"
                      }
                      onBlur={(e) => {
                        if (GSTValidation === true) {
                          setVerify(true);
                        }
                      }}
                    />

                    <button
                      type="button"
                      className={
                        GSTValidation === true && gst_verification === true
                          ? "btn btn-sm button-green"
                          : GSTValidation === true
                          ? "btn btn-sm btn-warning"
                          : ` btn btn-sm   button-otp`
                      }
                      disabled={GSTValidation === true ? false : true}
                      onClick={(e) => {
                        setGstLoading(true);
                        GstVerification();
                      }}
                    >
                      {gst_verification === true ? (
                        <TickIcon />
                      ) : GstLoading === true ? (
                        <PuffLoader loading={true} size={15} />
                      ) : (
                        " Verify"
                      )}
                    </button>
                  </div>

                  {AlreadyExist === true ? (
                    <FieldValidationError message=" Gst Number is already taken " />
                  ) : (
                    ""
                  )}
                  {GSTValidation === false ? (
                    <FieldValidationError message="Your GST Data Is Not Valid" />
                  ) : (
                    ""
                  )}

                  {Nulldata === true ? (
                    <FieldValidationError message="Please Add Valid GST Number" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">PAN No</label>
                  <input
                    type="text"
                    name="cname"
                    id="cname"
                    className="form-input"
                    value={gst_fetched_Data?.pan_number}
                    disabled
                    onChange={(e) => {
                      setCompanyData({
                        ...companyData,
                        pan_number: e.target.value,
                      });
                      setNullDataForPan(false);
                    }}
                  />
                  {NulldataForPan === true ? (
                    <FieldValidationError message="Please Add Valid PAN  Number" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">{`${
                    user?.is_buyer === 1
                      ? "Company Name / Bill To"
                      : "Company Name"
                  }`}</label>
                  <input
                    type="text"
                    name="cname"
                    id="cname"
                    className="form-input"
                    value={gst_fetched_Data?.gst_details?.tradeNam}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="form-field">
              <label className="form-label">Enter Shipping Address</label>
              <input
                type="text"
                name="cname"
                id="cname"
                className="form-input"
                value={company?.shipping}
                onChange={(e) => {
                  e.preventDefault();
                  setCompany((p) => ({ ...p, shipping: e.target.value }));
                  setNullDatashipping(false);
                }}
              />
              {Nulldatashipping === true ? (
                <FieldValidationError message="Please Add Valid Shipping Address" />
              ) : (
                ""
              )}
            </div>
            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">State</label>
                  <select className="form-input" disabled>
                    <option>
                      {capitalizeFirstLetter(
                        gst_fetched_Data?.region?.parent?.name
                      )}
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">City</label>
                  <select className="form-input" disabled>
                    <option>
                      {capitalizeFirstLetter(gst_fetched_Data?.region?.name)}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="form-field">
              <label className="form-label">Enter Shipping Pincode</label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="cname"
                id="cname"
                className="form-input"
                value={company?.pincodeShipping}
                onChange={(e) => {
                  e.preventDefault();
                  setCompany((p) => ({
                    ...p,
                    pincodeShipping: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="form-field">
              <label className="form-label">Enter Billing Address</label>
              <input
                type="text"
                name="cname"
                id="cname"
                className="form-input"
                value={dataCompany?.address}
                onChange={(e) => {
                  setDataCompany({
                    ...dataCompany,
                    address: e.target.value,
                  });

                  setNulldataForbill(false);
                }}
                disabled={gst_fetched_Data?.address ? true : false}
              />
              {NulldataForbill === true ? (
                <FieldValidationError message="Please Add Valid Billing Address" />
              ) : (
                ""
              )}
            </div>
            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">State</label>
                  <select className="form-input" disabled>
                    <option>
                      {capitalizeFirstLetter(
                        gst_fetched_Data?.region?.parent?.name
                      )}
                    </option>
                  </select>
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">City</label>
                  <select className="form-input" disabled>
                    <option>
                      {capitalizeFirstLetter(gst_fetched_Data?.region?.name)}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-field">
              <label className="form-label">Enter Billing Pincode</label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="cname"
                id="cname"
                className="form-input"
                value={company?.pincode}
                onChange={(e) => {
                  e.preventDefault();
                  setCompany((p) => ({ ...p, pincode: e.target.value }));
                }}
                disabled={
                  gst_fetched_Data?.gst_details?.pradr?.addr?.pncd
                    ? true
                    : false
                }
              />
            </div>
            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">Mobile Number</label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="cname"
                    id="cname"
                    className="form-input"
                    value={companyData?.mobile_number}
                    disabled
                  />
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">Email ID</label>
                  <input
                    type="email"
                    name="cname"
                    id="cname"
                    className="form-input"
                    value={companyData?.email}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="form-button">
              <button
                className="button button-primary"
                disabled={gst_verification === true ? false : true}
                onClick={() => {
                  setLoading(true);
                  EditCompanyData();
                }}
              >
                {Loading === true ? (
                  <PuffLoader loading={true} size={15} />
                ) : (
                  "Save Details"
                )}
              </button>

              <button className="button button-secondary">Cancel</button>
            </div>
          </form>
        ) : (
          <form onSubmit={saveCompany}>
            <div className="upload">
              <div className="upload-image">
                <input
                  type="file"
                  id="company_logo"
                  onChange={(e) => {
                    if (e.target.files[0].size > 2097152) {
                      alert("Not More than 2 MB is allowded");
                      return false;
                    }

                    setLogo({ ...Logo, logo: e.target.files[0] });
                  }}
                />
                <label htmlFor="company_logo">
                  {Logo?.logo?.name === undefined ? (
                    <>
                      <UploadIcon />

                      <span>
                        Drag n Drop here Or{" "}
                        <span className="color-primary">Browse</span>{" "}
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={URL.createObjectURL(Logo.logo)}
                        alt="Uploaded Logo"
                        className="preview-image"
                      />
                    </>
                  )}
                </label>
              </div>
              <div className="upload-fields">
                <div className="form-field">
                  <label className="form-label">GST No</label>

                  <div className="d-flex ">
                    <input
                      type="text"
                      name="cname"
                      id="cname"
                      className="form-input"
                      value={dataCompany?.gst_number}
                      onChange={(e) => {
                        setgst_verification(false);
                        setDataCompany({
                          ...dataCompany,
                          gst_number: e.target.value,
                        });
                        setNullData(false);
                        setAlreadyExist(false);
                        if (e.target.value != "") {
                          if (GSTregx.test(e.target.value)) {
                            setGSTValidation(true);
                          } else {
                            setGSTValidation(false);
                          }
                        }
                        if (e.target.value === "") {
                          setGSTValidation();
                        }
                        setgst_verification(false);
                      }}
                      contentEditable={
                        companyData?.gst_number === null ? "false" : "true"
                      }
                      onBlur={(e) => {
                        if (GSTValidation === true) {
                          setVerify(true);
                        }
                      }}
                    />

                    <button
                      type="button"
                      className={
                        GSTValidation === true && gst_verification === true
                          ? "btn btn-sm button-green"
                          : GSTValidation === true
                          ? "btn btn-sm btn-warning"
                          : ` btn btn-sm   button-otp`
                      }
                      disabled={GSTValidation === true ? false : true}
                      onClick={(e) => {
                        setGstLoading(true);
                        GstVerification();
                      }}
                    >
                      {gst_verification === true ? (
                        <TickIcon />
                      ) : GstLoading === true ? (
                        <PuffLoader loading={true} size={15} />
                      ) : (
                        " Verify"
                      )}
                    </button>
                  </div>

                  {AlreadyExist === true ? (
                    <FieldValidationError message=" Gst Number is already taken " />
                  ) : (
                    ""
                  )}

                  {/* 
              {      gst_verification === false ? (
                <FieldValidationError message="verify Your GST " />
              ) : (
                ""
              )} */}
                  {GSTValidation === false ? (
                    <FieldValidationError message="Your GST Data Is Not Valid" />
                  ) : (
                    ""
                  )}

                  {Nulldata === true ? (
                    <FieldValidationError message="Please Add Valid GST Number" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">PAN No</label>
                  <input
                    type="text"
                    className="form-input"
                    value={company?.pan}
                    onChange={(e) => {
                      setCompany((p) => ({ ...p, pan: e.target.value }));

                      setNullDataForPan(false);
                    }}
                    disabled={gst_fetched_Data?.pan_number ? true : false}
                  />
                  {NulldataForPan === true ? (
                    <FieldValidationError message="Please Add Valid PAN  Number" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-field">
                  <label className="form-label">{`${
                    user.is_buyer === 1
                      ? "Company Name / Bill To"
                      : "Company Name"
                  }`}</label>

                  <input
                    type="text"
                    className="form-input"
                    value={company?.companyName}
                    onChange={(e) => {
                      setCompany((p) => ({
                        ...p,
                        companyName: e.target.value,
                      }));
                    }}
                    disabled={
                      gst_fetched_Data?.gst_details?.tradeNam ? true : false
                    }
                  />
                  {Nulldatacompany === true ? (
                    <FieldValidationError message="company name is required " />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="form-field">
              <label className="form-label">Enter Shipping Address</label>
              <input
                type="text"
                className="form-input"
                value={company?.shipping}
                onChange={(e) => {
                  setCompany((p) => ({ ...p, shipping: e.target.value }));
                  setNullDatashipping(false);
                }}
              />
              {Nulldatashipping === true ? (
                <FieldValidationError message="Please Add Valid Shipping Address" />
              ) : (
                ""
              )}
            </div>
            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">State</label>

                  <Select
                    className=""
                    placeholder="Select State"
                    id="State"
                    onFocus={(e) => {
                      e.preventDefault();

                      IntialState();
                    }}
                    options={
                      stateList?.items &&
                      stateList?.items?.map(function (productArray) {
                        return {
                          value: productArray.id,
                          label: productArray.name,
                        };
                      })
                    }
                    onKeyDown={(e) => {
                      // searcCat(e.target.value);
                    }}
                    onChange={(e) => {
                      setFilterAvail((p) => ({ ...p, state_Id: e.value }));
                      setCompany((p) => ({ ...p, stateShipping: e.value }));
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                    }}
                  ></Select>
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">City</label>
                  <Select
                    className=" "
                    placeholder="Select city"
                    id="city"
                    onFocus={(e) => {
                      e.preventDefault();
                      City();
                    }}
                    options={
                      cityList &&
                      cityList.map(function (productArray) {
                        return {
                          value: productArray.id,
                          label: productArray.name,
                        };
                      })
                    }
                    onKeyDown={(e) => {
                      // searcCat(e.target.value);
                    }}
                    onChange={(e) => {
                      setCompany((p) => ({ ...p, cityShipping: e.value }));
                    }}
                    onBlur={(e) => {
                      e.preventDefault();
                    }}
                  ></Select>
                </div>
              </div>
              <div className="form-field">
                <label className="form-label">Enter Shipping Pincode</label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="cname"
                  id="cname"
                  className="form-input"
                  value={company?.pincodeShipping}
                  onChange={(e) => {
                    e.preventDefault();
                    setCompany((p) => ({
                      ...p,
                      pincodeShipping: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="form-field">
              <label className="form-label">Enter Billing Address</label>
              <input
                type="text"
                className="form-input"
                value={company?.billing}
                onChange={(e) => {
                  setCompany((p) => ({ ...p, billing: e.target.value }));

                  setNulldataForbill(false);
                }}
                disabled={gst_fetched_Data?.address ? true : false}
              />
              {NulldataForbill === true ? (
                <FieldValidationError message="Please Add Valid Billing Address" />
              ) : (
                ""
              )}
            </div>
            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">State</label>

                  {gst_fetched_Data?.region?.parent?.name ? (
                    <select className="form-input" disabled>
                      <option>
                        {capitalizeFirstLetter(
                          gst_fetched_Data?.region?.parent?.name
                        )}
                      </option>
                    </select>
                  ) : (
                    <Select
                      className=""
                      placeholder="Select State"
                      id="State"
                      onFocus={(e) => {
                        e.preventDefault();

                        IntialState();
                      }}
                      options={
                        stateList?.items &&
                        stateList?.items?.map(function (productArray) {
                          return {
                            value: productArray.id,
                            label: productArray.name,
                          };
                        })
                      }
                      onKeyDown={(e) => {
                        // searcCat(e.target.value);
                      }}
                      onChange={(e) => {
                        setFilterAvail((p) => ({ ...p, state_Id: e.value }));
                        setCompany((p) => ({ ...p, state: e.value }));
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                      }}
                    ></Select>
                  )}
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">City</label>
                  {gst_fetched_Data?.region?.name ? (
                    <select className="form-input" disabled>
                      <option>
                        {capitalizeFirstLetter(gst_fetched_Data?.region?.name)}
                      </option>
                    </select>
                  ) : (
                    <Select
                      className=""
                      placeholder="Select city"
                      id="city"
                      onFocus={(e) => {
                        e.preventDefault();
                        City();
                      }}
                      options={
                        cityList &&
                        cityList.map(function (productArray) {
                          return {
                            value: productArray.id,
                            label: productArray.name,
                          };
                        })
                      }
                      onKeyDown={(e) => {
                        // searcCat(e.target.value);
                      }}
                      onChange={(e) => {
                        setCompany((p) => ({ ...p, city: e.value }));
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                      }}
                    ></Select>
                  )}
                </div>
              </div>
              <div className="form-field">
                <label className="form-label">Enter Billing Pincode</label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="cname"
                  id="cname"
                  className="form-input"
                  value={company?.pincode}
                  onChange={(e) => {
                    e.preventDefault();
                    setCompany((p) => ({ ...p, pincode: e.target.value }));
                  }}
                  disabled={
                    gst_fetched_Data?.gst_details?.pradr?.addr?.pncd
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <div className="flex-box">
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">Mobile Number</label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="form-input"
                    value={companyData?.mobile_number}
                  />
                </div>
              </div>
              <div className="flex-item">
                <div className="form-field">
                  <label className="form-label">Email ID</label>
                  <input
                    type="email"
                    className="form-input"
                    value={companyData?.email}
                  />
                </div>
              </div>
            </div>
            <div className="form-button">
              <button
                className="button button-primary"
                onClick={(e) => {
                  setLoading(true);
                  saveCompany(e);
                }}
              >
                {Loading === true ? (
                  <PuffLoader loading={true} size={15} />
                ) : (
                  "Save Details"
                )}
              </button>

              <button className="button button-secondary">Cancel</button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default AddCompanyDetails;
