import React, { useState, useEffect, useRef, useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../common/header";
import Skeleton from "react-loading-skeleton";
import "../common/scss/pages/product-protfolio.scss";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Auth from "../libs/auth";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import { SwitchProfile } from "../context/switchProfile.context";
function ProductPortfolio() {
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  let navigate = useNavigate();
  const { setBuyerAccount, setSellerAccount } = useContext(SwitchProfile);

  const location = useLocation();

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [ProductDetail, setProductDetail] = useState({ test: "" });
  const [PortfolioData, setPortfolioData] = useState({ credit_policy: "" });
  const [creditPolicyData, setCreditPolicyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  let [num, setNum] = useState(0);
  let [num2, setNum2] = useState(0);
  const [DisableCredit, setDisableCredit] = useState(false);
  const [DisableAdvance, setDisableAdvance] = useState(false);
  const [SoValue, setSoValue] = useState(0);
  const [amount, setAmount] = useState(0);
  const [creditAmount, setCreditAmount] = useState(0);
  const [SoDetail, setSoDetail] = useState({});
  const [so, setSo] = useState({});
  const [addShortOrder, setAddShortOrder] = useState("");
  let soGrandTotal = SoDetail?.test?.sub_total;
  const [sameOrder, setSameOrder] = useState(false);
  const [sameOrderIndex, setSameOrderIndex] = useState(0);
  const [sameOrderQty, setSameOrderQty] = useState(0);
  useEffect(() => {
    if (SoDetail?.test?.payment_type === "advance") {
      setDisableCredit(true);
    } else if (SoDetail?.test?.payment_type === "credit") {
      setDisableAdvance(true);
    }

    // else {
    //   setDisableAdvance(false);
    //   setDisableCredit(false);
    // }
  }, [SoDetail]);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, [images]);

  const ShortOrderTooltip = (
    <Tooltip
      id="short-order-tooltip"
      style={{
        "margin-bottom": "10px",
      }}
    >
      To enable the short order you have to reach the "Minimum order value"
    </Tooltip>
  );

  const getProductDetails = async (advance, credit) => {
    setLoading(true);
    get(`/product/detail/${location?.state?.id}`, getAuthConfig())
      .then((response) => {
        setLoading(false);
        setCreditPolicyData(response?.data?.data?.credit_policy);
        setAmount(response?.data?.data?.product?.amount);
        setCreditAmount(response?.data?.data?.product?.credit_amount);
        setProductDetail((p) => ({
          ...p,
          test: response?.data?.data?.product,
        }));
        setSoDetail((p) => ({ ...p, test: response?.data?.data?.short_order }));
        setSo(response?.data?.data?.short_order);
        // setAmount(response.data.data.product);
        localStorage.setItem(
          "productNegotiation",
          response?.data?.data?.product?.negotiation
        );

        if (response?.data?.data?.short_order?.id) {
          response?.data?.data?.short_order.items.map((item, index) => {
            if (item?.product_id === location?.state?.id) {
              if (
                response?.data?.data?.short_order?.payment_type === "advance"
              ) {
                setNum(Number(item?.quantity));
                setSameOrder(true);
                setSameOrderIndex(index);
                setSameOrderQty(item?.quantity);
              } else if (
                response?.data?.data?.short_order?.payment_type === "credit"
              ) {
                setNum2(Number(item?.quantity));
                setSameOrderIndex(index);
                setSameOrder(true);
                setSameOrderQty(item?.quantity);
              }
            }
          });
        }
        response.data.data.product.media.map((itemt, indext) => {
          if (itemt.type == "attachment") {
            return false;
          } else {
            setImages((arr) => [...arr, itemt]);
          }

          if (advance === true) {
            setNum(Number(num) + 1);
            localStorage.setItem("PayType", "advance");
            setSoValue(Number(amount) * Number(num) + Number(amount));
            setDisableCredit(true);
          } else if (credit === true) {
            setNum2(Number(num2) + 1);
            localStorage.setItem("PayType", "credit");
            setSoValue(
              Number(creditAmount) * Number(num) + Number(creditAmount)
            );

            setDisableAdvance(true);
          }
        });
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  UseEffectOnce(() => {
    getProductDetails();
    GetCompanydDetails();
  }, []);

  const soQtyDetail = SoDetail?.test?.items?.map((item) => item?.quantity);
  const soQtyDetailNum = soQtyDetail?.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  );
  const settingsMain = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: "on-demand",
  };

  const user = Auth.getCurrentUser();

  const settingsThumbs = {
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const deleteProductfromCart = async (id) => {
    await get(`/short_order/cancel/${id}`, getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          toast.success("Order Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const addProductInCart = async (id, deleteId, button) => {
    if (
      Object?.keys(so)?.length !== 0 &&
      ProductDetail?.test?.user_id !== SoDetail?.test?.to_user_id
    ) {
      swal({
        title: "Are you sure?",
        text: "Items are already added from the different seller so you have to take this action",
        icon: "warning",
        dangerMode: true,
        buttons: ["Cancel", "Delete product"],
      }).then((willDelete) => {
        if (willDelete) {
          deleteProductfromCart(deleteId);
          getProductDetails();
        }
      });
    } else if (
      SoValue >= Number(ProductDetail?.test?.min_order_value) || // It will check whether SoValue or GrandTotal = MOQ
      soGrandTotal >= Number(ProductDetail?.test?.min_order_value)
    ) {
      const formData = new FormData();
      formData.append("product_id", id);
      formData.append("payment_type", localStorage.getItem("PayType"));
      formData.append(
        "quantity",
        DisableCredit ? num : DisableAdvance ? num2 : num
      );
      if (Object?.keys(so)?.length !== 0) {
        formData.append("order_id", SoDetail?.test?.id);
      }
      await postwithOu("/short_order/cart", getAuthConfig(), formData)
        .then((res) => {
          // localStorage.setItem("SOID", res?.data?.data?.id);

          if (localStorage.getItem("accountFor") === "seller") {
            if (button === "buttonView" && res?.data?.data?.id) {
              navigate(`/product-short-order`, {
                state: { id: res?.data?.data?.id },
              });

              // account_switch("buttonView", res?.data?.data?.id)
            } else {
              // account_switch("", res?.data?.data?.id)
            }
          } else {
            if (button === "buttonView" && res?.data?.data?.id) {
              navigate(`/product-short-order`, {
                state: { id: res?.data?.data?.id },
              });
            }
          }
        })
        .catch((error) => {});
    }
  };

  // const addProductInCart = async (id) => {
  //   if (ProductDetail?.test?.user_id !== SoDetail?.test?.to_user_id) {
  //     swal({
  //       title: "Are you sure?",
  //       text: "Items are already added from the different seller so you have to take this action",
  //       icon: "warning",
  //       dangerMode: true,
  //       buttons: ["Cancel", "Delete product"],
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         deleteProductfromCart();
  //       }
  //     });
  //   } else if (
  //     ProductDetail?.test?.user_id === SoDetail?.test?.to_user_id && // It will check whether product object user's id = short order object product's user id
  //     (SoValue >= Number(ProductDetail?.test?.min_order_value) || // It will check whether SoValue or GrandTotal = MOQ
  //       soGrandTotal >= Number(ProductDetail?.test?.min_order_value))
  //   ) {
  //     const formData = new FormData();
  //     formData.append("product_id", id);
  //     formData.append("payment_type", localStorage.getItem("PayType"));
  //     formData.append("quantity", DisableCredit ? num : num2);
  //     if (SoDetail?.test?.items?.length !== 0) {
  //       formData.append("order_id", SoDetail?.test?.id);
  //     }

  //     await postwithOu("/short_order/cart", getAuthConfig(), formData)
  //       .then((res) => {
  //       })
  //       .catch((error) => {
  //       });
  //   }
  // };

  let incNum = () => {
    if (num === 0) {
      if (
        Object?.keys(so)?.length !== 0 &&
        ProductDetail?.test?.user_id !== SoDetail?.test?.to_user_id
      ) {
        swal({
          title: "Are you sure?",
          text: "Items are already added from the different seller so you have to take this action",
          icon: "warning",
          dangerMode: true,
          buttons: ["Cancel", "Delete Short Order"],
        }).then((willDelete) => {
          if (willDelete) {
            deleteProductfromCart(SoDetail?.test?.id, true);
            getProductDetails(true);
          }
        });
      } else {
        setNum(Number(num) + 1);
        // setSameOrder( false)

        localStorage.setItem("PayType", "advance");
        setSoValue(Number(amount) * Number(num) + Number(amount));

        setDisableCredit(true);
      }
    } else {
      setNum(Number(num) + 1);
      // setSameOrder( false)
      localStorage.setItem("PayType", "advance");
      setSoValue(Number(amount) * Number(num) + Number(amount));

      setDisableCredit(true);
    }
  };

  let decNum = () => {
    if (num >= 0) {
      if (num == 1) {
        setNum(num - 1);
        // setSameOrder( false)

        setSoValue(Number(amount) * Number(num) - Number(amount));
        setDisableCredit(false);
      } else if (num == 0) {
        setDisableCredit(false);
        setSoValue(0);
      } else {
        setNum(num - 1);
        // setSameOrder( false)

        setSoValue(Number(amount) * Number(num) - Number(amount));
        setDisableCredit(true);
      }
    }
  };

  let handleChange = (e) => {
    if (e.target.value <= 0 && SoDetail?.test?.payment_type === "advance") {
      alert("You are in the Credit Order , You can only Add the Credit Orders");
    } else {
      if (SoDetail?.test?.payment_type === "credit") {
        setDisableCredit(true);
      } else {
        if (e.target.value == 0) {
          setDisableCredit(false);
        } else {
          setSoValue(Number(amount) * e.target.value + Number(amount));

          setDisableCredit(true);
        }
        setNum(e.target.value);
        // setSameOrder( false)
      }
    }
  };

  let incNum2 = () => {
    if (num2 === 0) {
      if (
        Object?.keys(so)?.length !== 0 &&
        ProductDetail?.test?.user_id !== SoDetail?.test?.to_user_id
      ) {
        swal({
          title: "Are you sure?",
          text: "Items are already added from the different seller so you have to take this action",
          icon: "warning",
          dangerMode: true,
          buttons: ["Cancel", "Delete Short Order"],
        }).then((willDelete) => {
          if (willDelete) {
            deleteProductfromCart(SoDetail?.test?.id, false, true);
            getProductDetails(false, true);
          }
        });
      } else {
        setNum2(Number(num2) + 1);
        localStorage.setItem("PayType", "credit");
        setSoValue(Number(creditAmount) * Number(num2) + Number(creditAmount));

        setDisableAdvance(true);
      }
    } else {
      setNum2(Number(num2) + 1);
      localStorage.setItem("PayType", "credit");
      setSoValue(Number(creditAmount) * Number(num2) + Number(creditAmount));

      setDisableAdvance(true);
    }
  };

  let decNum2 = () => {
    if (num2 >= 0) {
      if (num2 == 1) {
        setNum2(num2 - 1);
        // setSameOrder( false)
        setSoValue(Number(creditAmount) * Number(num) - Number(creditAmount));
        setDisableAdvance(false);
      } else if (num2 === 0) {
        setDisableAdvance(false);
        setSoValue(0);
      } else {
        setNum2(num2 - 1);
        // setSameOrder( false)

        setSoValue(Number(creditAmount) * Number(num) - Number(creditAmount));
        setDisableAdvance(true);
      }
    }
  };

  let handleChange2 = (e) => {
    if (e.target.value <= 0 && SoDetail?.test?.payment_type === "credit") {
      alert("You are in the Credit Order , You can only Add the Credit Orders");
    } else {
      if (SoDetail?.test?.payment_type === "credit") {
        setDisableAdvance(true);
      } else {
        if (e.target.value == 0) {
          setDisableAdvance(false);
        } else {
          setSoValue(
            Number(creditAmount) * e.target.value + Number(creditAmount)
          );

          setDisableAdvance(true);
        }
      }
      setNum2(e.target.value);
      // setSameOrder( false)
    }
  };

  function openInNewTab() {
    let Url = null;
    ProductDetail?.test?.media.map((item) => {
      if (item.type === "attachment") {
        Url = item.file;
      }
    });

    window.open(Url, "_blank", "noopener,noreferrer");
  }

  function GetCompanydDetails() {
    get("/user/setting/get_prefilled_details", getAuthConfig()).then((res) => {
      if (res?.data?.status === 200) {
        setPortfolioData((p) => ({
          ...PortfolioData,
          credit_policy: res.data.data.credit_policy,
        }));
      }
    });
  }

  async function account_switch(button, id) {
    const data = await postwithOu("/user/account_switch", getAuthConfig());
    if (data.status === 200) {
      setBuyerAccount(true);
      localStorage.setItem("accountFor", "buyer");
      if (button === "buttonView" && id) {
        navigate(`/product-short-order`, { state: { id: id } });
      }
    }
  }

  return (
    <>
      <ToastContainer />
      <Header home />
      <div className="page">
        <div className="container-fluid">
          <div className="content-main">
            {!loading ? (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{ overflow: "hidden" }}>
                  <li className="breadcrumb-item">
                    <Link
                      to={
                        user?.id === ProductDetail?.test?.user?.id
                          ? "/product-portfolio-initial"
                          : `/product-portfolio-initial/${ProductDetail?.test?.user?.id}`
                      }
                    >
                      Product Portfolio
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">
                      {ProductDetail?.test?.category?.parent?.name}
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">{ProductDetail?.test?.category?.name}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">{ProductDetail?.test?.name}</a>
                  </li>
                </ol>
              </nav>
            ) : (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{ overflow: "hidden" }}>
                  <Skeleton width={500} />
                </ol>
              </nav>
            )}
            {!loading ? (
              <div className="row productView">
                <div className="col-sm-4 productView-image">
                  <div className="product">
                    <div className="product-image">
                      <div className="main-image">
                        {images.length > 0 && (
                          <Slider
                            asNavFor={nav2}
                            ref={slider1}
                            arrows={true}
                            {...settingsMain}
                          >
                            {images.length > 0 &&
                              images.map((itemt, indext) => {
                                return (
                                  <>
                                    <div className="image-block">
                                      <img src={itemt?.file} alt="" />
                                    </div>
                                  </>
                                );
                              })}
                          </Slider>
                        )}
                      </div>

                      <div className="product-thumb">
                        <Slider
                          asNavFor={nav1}
                          ref={slider2}
                          swipeToSlide={true}
                          focusOnSelect={true}
                          {...settingsThumbs}
                        >
                          {images.length > 0 &&
                            images.map((itemt, indext) => {
                              return (
                                <>
                                  <div className="image-block">
                                    <img src={itemt?.file} alt="" />
                                  </div>
                                </>
                              );
                            })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 productView-detail">
                  <h1 className="product-title">{ProductDetail?.test?.name}</h1>
                  <div className="productView-price">
                    <ul>
                      <li>
                        Min Order Value:{" "}
                        <strong>
                          {ProductDetail?.test?.min_order_value}/-
                        </strong>
                      </li>
                      <li>
                        MOQ:{" "}
                        <strong>
                          {ProductDetail?.test?.order_qty}{" "}
                          {ProductDetail?.test?.unit?.name != undefined ||
                          ProductDetail?.test?.unit?.name != null
                            ? ProductDetail?.test?.unit?.name
                            : ""}
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: ProductDetail?.test?.description,
                    }}
                  ></h6>
                  <dl>
                    <dt>{ProductDetail?.test?.name}'s Product code:</dt>
                    <dd>
                      {ProductDetail?.test?.product_code
                        ? ProductDetail?.test?.product_code
                        : "N/A"}
                    </dd>

                    <dt>Product Differentiation :</dt>
                    <dd>
                      {ProductDetail?.test?.size ? (
                        <>
                          <dt> Size :</dt>
                          <dd>{ProductDetail?.test?.size}</dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>

                    <dd>
                      {ProductDetail?.test?.color_id ? (
                        <>
                          <dt> , Color : </dt>
                          <dd>{ProductDetail?.test?.color?.name}</dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>

                    <dd>
                      {ProductDetail?.test?.material_type ? (
                        <>
                          <dt> , Material type : </dt>
                          <dd>{ProductDetail?.test?.material_type}</dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>

                    <dd>
                      {ProductDetail?.test?.brand_id ? (
                        <>
                          <dt> , Brand : </dt>
                          <dd>
                            {ProductDetail?.test?.brand?.name
                              ? ProductDetail?.test?.brand?.name
                              : "N/A "}
                          </dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>

                    <dd>
                      {ProductDetail?.test?.shape ? (
                        <>
                          <dt> , Shape : </dt>
                          <dd>{ProductDetail?.test?.shape}</dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>

                    <dd>
                      {ProductDetail?.test?.storage_capacity ? (
                        <>
                          <dt> , Capacity : </dt>
                          <dd>{ProductDetail?.test?.storage_capacity}</dd>
                        </>
                      ) : (
                        ""
                      )}
                    </dd>
                  </dl>

                  <dl>
                    <dt>Delivery Time :</dt>

                    {ProductDetail?.test?.delivery_details
                      ?.delivery_outside_state ? (
                      <dd>
                        <dt>Delivery Outside State : </dt>
                        <dd>
                          {" "}
                          {
                            ProductDetail?.test?.delivery_details
                              ?.delivery_outside_state
                          }{" "}
                        </dd>
                      </dd>
                    ) : (
                      ""
                    )}

                    {ProductDetail?.test?.delivery_details
                      ?.delivery_outside_state ? (
                      <dd>
                        <dt>&nbsp; Delivery Within State : </dt>
                        <dd>
                          {" "}
                          {
                            ProductDetail?.test?.delivery_details
                              ?.delivery_within_state
                          }
                        </dd>
                      </dd>
                    ) : (
                      ""
                    )}
                    <dt>Tax Details:</dt>
                    <dd>
                      <dt>HSN Code : </dt>
                      <dd> {ProductDetail?.test?.hsn_code}</dd>
                    </dd>
                  </dl>

                  <p>&nbsp;</p>
                  {ProductDetail?.test?.certificate != "" ? (
                    <div className="product-certification">
                      <h6>Product Certificates</h6>

                      <a
                        href={ProductDetail?.test?.certificate}
                        target={"_blank"}
                      >
                        <AttachmentIcon />
                        View Certificates
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {!loading ? (
                    <>
                      {ProductDetail?.test?.user_id !== user?.id ? (
                        <div className="credit-policy">
                          <div className="row">
                            <div
                              className="col-sm-6"
                              style={{
                                opacity: DisableAdvance === true ? "30%" : "",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (DisableAdvance === true) {
                                  return false;
                                }
                              }}
                            >
                              <div className="credit-policy-box">
                                <div className="counter">
                                  <div className="counter-text">
                                    Advance
                                    <span>
                                      ₹{" "}
                                      {ProductDetail?.test?.amount
                                        ? ProductDetail?.test?.amount
                                        : "N/A"}{" "}
                                    </span>
                                  </div>
                                  <div class="input-group increment">
                                    <div class="input-group-prepend">
                                      <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        // onClick={decNum}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (DisableAdvance === true) {
                                            return false;
                                          } else {
                                            if (
                                              num <= 1 &&
                                              SoDetail?.test?.payment_type ===
                                                "advance"
                                            ) {
                                              alert(
                                                "You are in the Advance Payment type  , You can only Add the Payment Orders"
                                              );
                                            } else {
                                              decNum();
                                            }
                                          }
                                        }}
                                        disabled={
                                          ProductDetail?.test?.amount === null
                                            ? true
                                            : false
                                        }
                                      >
                                        -
                                      </button>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      class="form-control"
                                      value={num}
                                      onChange={handleChange}
                                      disabled={
                                        DisableAdvance === true ||
                                        ProductDetail?.test?.amount === null
                                          ? true
                                          : false
                                      }
                                    />
                                    <div class="input-group-prepend">
                                      <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        disabled={
                                          ProductDetail?.test?.amount === null
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (DisableAdvance === true) {
                                            return false;
                                          } else {
                                            incNum();
                                          }
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  {" "}
                                  {ProductDetail?.test?.freight_charges}%
                                  Freight and {ProductDetail?.test?.gst_rate}%
                                  GST extra
                                </p>
                              </div>
                            </div>
                            <div
                              className="col-sm-6"
                              style={{
                                opacity: DisableCredit === true ? "30%" : "",
                              }}
                            >
                              <div className="credit-policy-box">
                                <div className="counter">
                                  <div className="counter-text">
                                    Credit Terms
                                    <span>
                                      ₹
                                      {ProductDetail?.test?.credit_amount
                                        ? ProductDetail?.test?.credit_amount
                                        : "N/A"}
                                    </span>
                                  </div>
                                  <div class="input-group increment">
                                    <div class="input-group-prepend">
                                      <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        disabled={
                                          ProductDetail?.test?.credit_amount ===
                                          null
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (DisableCredit === true) {
                                            return false;
                                          } else {
                                            if (
                                              num2 <= 1 &&
                                              SoDetail?.test?.payment_type ===
                                                "credit"
                                            ) {
                                              alert(
                                                "You are in the Credit Order , You can only Add the Credit Orders"
                                              );
                                            } else {
                                              decNum2();
                                            }
                                          }
                                        }}
                                      >
                                        -
                                      </button>
                                    </div>
                                    <input
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      class="form-control"
                                      value={num2}
                                      onChange={handleChange2}
                                      disabled={
                                        DisableCredit === true ||
                                        ProductDetail?.test?.credit_amount ===
                                          null
                                          ? true
                                          : false
                                      }
                                    />
                                    <div class="input-group-prepend">
                                      <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        disabled={
                                          ProductDetail?.test?.credit_amount ===
                                          null
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (DisableCredit === true) {
                                            return false;
                                          } else {
                                            incNum2();
                                          }
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  {" "}
                                  {ProductDetail?.test?.freight_charges}%
                                  Freight and {ProductDetail?.test?.gst_rate}%
                                  GST extra
                                </p>
                              </div>
                            </div>
                          </div>
                          <Link
                            to=""
                            className="credit-policy-link"
                            data-toggle="modal"
                            data-target="#CreditPolicyModal"
                          >
                            <AttachmentIcon />
                            Credit Policy
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() =>
                              navigate("/product-portfolio-initial")
                            }
                            className="button button-primary"
                          >
                            Go To Product Portfolio
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="credit-policy">
                      <div className="col-sm-6">
                        <div className="row">
                          <div
                            className="credit-policy-box"
                            style={{ marginBottom: "15px" }}
                          >
                            <Skeleton width={590} height={80} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {ProductDetail?.test?.user_id !== user?.id ? (
                    <div className="productView-button">
                      <Link
                        to={`${
                          Object?.keys(so)?.length !== 0 &&
                          ProductDetail?.test?.user_id !==
                            SoDetail?.test?.to_user_id
                            ? ""
                            : `/product-portfolio-initial/${ProductDetail?.test?.user_id}`
                        } `}
                        className="button button-primary"
                        onClick={() =>
                          addProductInCart(
                            ProductDetail?.test?.id,
                            SoDetail?.test?.id
                          )
                        }
                      >
                        Add more in Short Order
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div className="row productView">
                <div className="col-sm-4 productView-image">
                  <div className="product">
                    <div className="product-image">
                      <div className="main-image">
                        <Skeleton width={450} height={400} />
                      </div>

                      <div className="product-thumb">
                        <Skeleton width={80} height={80} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 productView-detail">
                  <h1 className="product-title">
                    <Skeleton width={200} />
                  </h1>
                  <div className="productView-price">
                    <Skeleton width={500} height={250} />
                  </div>
                  {!loading ? (
                    <div className="credit-policy">
                      <div className="row">
                        <div
                          className="col-sm-6"
                          style={{
                            opacity: DisableAdvance === true ? "30%" : "",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (DisableAdvance === true) {
                              return false;
                            }
                          }}
                        >
                          <div className="credit-policy-box">
                            <div className="counter">
                              <div className="counter-text">
                                Advance
                                <span>
                                  ₹{" "}
                                  {ProductDetail?.test?.amount
                                    ? ProductDetail?.test?.amount
                                    : "N/A"}{" "}
                                </span>
                              </div>
                              <div class="input-group increment">
                                <div class="input-group-prepend">
                                  <button
                                    class="btn btn-outline-primary"
                                    type="button"
                                    // onClick={decNum}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (DisableAdvance === true) {
                                        return false;
                                      } else {
                                        decNum();
                                      }
                                    }}
                                    disabled={
                                      ProductDetail?.test?.amount === null
                                        ? true
                                        : false
                                    }
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={num}
                                  onChange={handleChange}
                                  disabled={
                                    DisableAdvance === true ||
                                    ProductDetail?.test?.amount === null
                                      ? true
                                      : false
                                  }
                                />
                                <div class="input-group-prepend">
                                  <button
                                    class="btn btn-outline-primary"
                                    type="button"
                                    disabled={
                                      ProductDetail?.test?.amount === null
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (DisableAdvance === true) {
                                        return false;
                                      } else {
                                        incNum();
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                            <p>
                              {" "}
                              {ProductDetail?.test?.freight_charges}% Freight
                              and {ProductDetail?.test?.gst_rate}% GST extra
                            </p>
                          </div>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{
                            opacity: DisableCredit === true ? "30%" : "",
                          }}
                        >
                          <div className="credit-policy-box">
                            <div className="counter">
                              <div className="counter-text">
                                Credit Terms
                                <span>
                                  ₹
                                  {ProductDetail?.test?.credit_amount
                                    ? ProductDetail?.test?.credit_amount
                                    : "N/A"}
                                </span>
                              </div>
                              <div class="input-group increment">
                                <div class="input-group-prepend">
                                  <button
                                    class="btn btn-outline-primary"
                                    type="button"
                                    disabled={
                                      ProductDetail?.test?.credit_amount ===
                                      null
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (DisableCredit === true) {
                                        return false;
                                      } else {
                                        decNum2();
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                </div>
                                <input
                                  type="text"
                                  class="form-control"
                                  value={num2}
                                  onChange={handleChange2}
                                  disabled={
                                    DisableCredit === true ||
                                    ProductDetail?.test?.credit_amount === null
                                      ? true
                                      : false
                                  }
                                />
                                <div class="input-group-prepend">
                                  <button
                                    class="btn btn-outline-primary"
                                    type="button"
                                    disabled={
                                      ProductDetail?.test?.credit_amount ===
                                      null
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (DisableCredit === true) {
                                        return false;
                                      } else {
                                        incNum2();
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                            <p>
                              {" "}
                              {ProductDetail?.test?.freight_charges}% Freight
                              and {ProductDetail?.test?.gst_rate}% GST extra
                            </p>
                          </div>
                        </div>
                      </div>
                      <Link
                        to=""
                        className="credit-policy-link"
                        data-toggle="modal"
                        data-target="#CreditPolicyModal"
                      >
                        <AttachmentIcon />
                        Credit Policy
                      </Link>
                    </div>
                  ) : (
                    <div className="credit-policy">
                      <div className="col-sm-6">
                        <div className="row">
                          <div
                            className="credit-policy-box"
                            style={{ marginBottom: "15px" }}
                          >
                            <Skeleton width={590} height={80} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {ProductDetail?.test?.user_id !== user?.id ? (
                    <div className="productView-button">
                      <Link to={""} className="button button-primary">
                        Add more in Short Order
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="productView-bottom">
            <div className="container-fluid">
              <Skeleton width={194} height={2.16} />
            </div>
          </div>
        ) : (
          <>
            {ProductDetail?.test?.user_id !== user?.id && (
              <div className="productView-bottom">
                <div className="container-fluid">
                  <ul>
                    <li>
                      Total SO value:{" "}
                      <strong>
                        <span className="heighlight">
                          {sameOrder === true
                            ? DisableAdvance === true
                              ? Number(soGrandTotal) +
                                Number(ProductDetail?.test?.credit_amount) *
                                  (num2 === sameOrderQty
                                    ? num2 - sameOrderQty
                                    : num2 - sameOrderQty)
                              : DisableCredit === true
                              ? Number(soGrandTotal) +
                                Number(ProductDetail?.test?.amount) *
                                  (num <= sameOrderQty
                                    ? num - sameOrderQty
                                    : num - sameOrderQty)
                              : soGrandTotal
                            : ""}
                        </span>
                        {sameOrder === true ? (
                          ""
                        ) : soGrandTotal !== 0 ? (
                          <span className="heighlight">
                            {soGrandTotal != undefined
                              ? DisableAdvance === true
                                ? Number(soGrandTotal) +
                                  Number(ProductDetail?.test?.credit_amount) *
                                    num2
                                : DisableCredit === true
                                ? Number(soGrandTotal) +
                                  Number(ProductDetail?.test?.amount) * num
                                : soGrandTotal
                              : DisableAdvance === true
                              ? Number(ProductDetail?.test?.credit_amount) *
                                num2
                              : DisableCredit === true
                              ? Number(ProductDetail?.test?.amount) * num
                              : 0}
                            &nbsp; INR
                          </span>
                        ) : (
                          <span className="heighlight">
                            {(DisableAdvance === false &&
                              DisableCredit === false) ||
                            soGrandTotal === 0 ||
                            soGrandTotal === undefined
                              ? 0
                              : DisableAdvance === true
                              ? ProductDetail?.test?.credit_amount * num2
                              : DisableCredit === true
                              ? ProductDetail?.test?.amount * num
                              : 0}
                            INR
                          </span>
                        )}
                        + Freight & GST
                      </strong>
                    </li>
                    <li>
                      Nos. of Qty in SO:{" "}
                      <strong>
                        <span className="heighlight">
                          {sameOrder === true
                            ? DisableAdvance === true
                              ? soQtyDetailNum +
                                (num2 === sameOrderQty
                                  ? num2 - sameOrderQty
                                  : num2 - sameOrderQty)
                              : DisableCredit === true
                              ? soQtyDetailNum +
                                (num <= sameOrderQty
                                  ? num - sameOrderQty
                                  : num - sameOrderQty)
                              : 0
                            : ""}
                          {sameOrder === true
                            ? ""
                            : soQtyDetailNum === undefined
                            ? num > 0
                              ? num
                              : num2 > 0
                              ? num2
                              : 0
                            : num > 0
                            ? soQtyDetailNum + num
                            : num2 > 0
                            ? soQtyDetailNum + num2
                            : soQtyDetailNum}
                        </span>
                      </strong>
                    </li>
                    <li>
                      Nos. of items in SO:{" "}
                      <strong>
                        <span className="heighlight">
                          {sameOrder === true
                            ? SoDetail?.test?.items?.length
                            : ""}
                          {sameOrder === true
                            ? ""
                            : SoDetail?.test?.items?.length
                            ? num > 0
                              ? SoDetail?.test?.items?.length + 1
                              : num2 > 0
                              ? SoDetail?.test?.items?.length + 1
                              : SoDetail?.test?.items?.length
                            : num > 0
                            ? 1
                            : num2 > 0
                            ? 1
                            : 0}
                        </span>
                      </strong>
                    </li>
                    {sameOrder === true &&
                    SoDetail?.test?.items?.length >= 1 ? (
                      ""
                    ) : soGrandTotal == undefined ? (
                      <>
                        {SoValue >=
                        Number(ProductDetail?.test?.min_order_value) ? (
                          <li style={{ cursor: "pointer" }}>
                            <span
                              style={{ border: "none" }}
                              className="button button-primary"
                              onClick={() =>
                                addProductInCart(
                                  ProductDetail?.test?.id,
                                  "",
                                  "buttonView"
                                )
                              }
                            >
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </span>
                          </li>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={ShortOrderTooltip}
                          >
                            <li>
                              <span
                                style={{
                                  background: "#c3c3c3",
                                  border: "none",
                                  color: "#747474",
                                }}
                                className="button button-primary"
                              >
                                View Short Order{" "}
                                <strong>
                                  <InfoIcon />
                                </strong>
                              </span>
                            </li>
                          </OverlayTrigger>
                        )}
                      </>
                    ) : (
                      <>
                        {soGrandTotal >=
                        Number(ProductDetail?.test?.min_order_value) ? (
                          <li style={{ cursor: "pointer" }}>
                            <span
                              className="button button-primary"
                              onClick={() =>
                                addProductInCart(
                                  ProductDetail?.test?.id,
                                  "",
                                  "buttonView"
                                )
                              }
                            >
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </span>
                          </li>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={ShortOrderTooltip}
                          >
                            <li>
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </li>
                          </OverlayTrigger>
                        )}
                      </>
                    )}

                    {sameOrder === true ? (
                      DisableAdvance === true ? (
                        Number(soGrandTotal) +
                          Number(ProductDetail?.test?.credit_amount) *
                            (num2 === sameOrderQty
                              ? num2 - sameOrderQty
                              : num2 - sameOrderQty) >=
                        Number(ProductDetail?.test?.min_order_value) ? (
                          <li style={{ cursor: "pointer" }}>
                            <span
                              className="button button-primary"
                              onClick={() =>
                                addProductInCart(
                                  ProductDetail?.test?.id,
                                  "",
                                  "buttonView"
                                )
                              }
                            >
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </span>
                          </li>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={ShortOrderTooltip}
                          >
                            <li>
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </li>
                          </OverlayTrigger>
                        )
                      ) : DisableCredit === true ? (
                        Number(soGrandTotal) +
                          Number(ProductDetail?.test?.amount) *
                            (num <= sameOrderQty
                              ? num - sameOrderQty
                              : num - sameOrderQty) >=
                        Number(ProductDetail?.test?.min_order_value) ? (
                          <li style={{ cursor: "pointer" }}>
                            <span
                              className="button button-primary"
                              onClick={() =>
                                addProductInCart(
                                  ProductDetail?.test?.id,
                                  "",
                                  "buttonView"
                                )
                              }
                            >
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </span>
                          </li>
                        ) : (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={ShortOrderTooltip}
                          >
                            <li>
                              View Short Order{" "}
                              <strong>
                                <InfoIcon />
                              </strong>
                            </li>
                          </OverlayTrigger>
                        )
                      ) : soGrandTotal >=
                        Number(ProductDetail?.test?.min_order_value) ? (
                        <li style={{ cursor: "pointer" }}>
                          <span
                            className="button button-primary"
                            onClick={() =>
                              addProductInCart(
                                ProductDetail?.test?.id,
                                "",
                                "buttonView"
                              )
                            }
                          >
                            View Short Order{" "}
                            <strong>
                              <InfoIcon />
                            </strong>
                          </span>
                        </li>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={ShortOrderTooltip}
                        >
                          <li>
                            View Short Order{" "}
                            <strong>
                              <InfoIcon />
                            </strong>
                          </li>
                        </OverlayTrigger>
                      )
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            )}
          </>
        )}

        <div
          className="modal fade credit-policy-modal"
          id="CreditPolicyModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="CreditPolicyModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Credit Policy Data
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <Close2Icon />
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="policy-info">
                  <form>
                    <div className="info-box row justify-content-start">
                      <div className="info-item col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Nos of Advance Transactions for Credit</label>

                        <span>{creditPolicyData?.advance_transaction}</span>
                      </div>
                      <div className="info-item  col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <label>Credit Period (Days)</label>

                        <span className="info-days">
                          {creditPolicyData?.credit_period}
                        </span>
                      </div>
                      <div className="info-item col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Interest Rate</label>

                        <span className="info-per">
                          {creditPolicyData?.delay_interest}
                        </span>
                      </div>
                      <div className="info-item col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Credit Period Starts from</label>

                        <span className="info-item">
                          {creditPolicyData?.credit_period_start}
                        </span>
                      </div>
                      <div className="info-item col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Interest Period</label>

                        <span contenteditable="true">
                          {creditPolicyData?.interest_period}
                        </span>
                      </div>
                      <div className="info-item col-lg-6 col-md-6 col-sm-12 col-12">
                        <label>Other Terms</label>

                        <span contenteditable="true">
                          {creditPolicyData?.other_terms}
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPortfolio;
