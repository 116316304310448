import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { getAuthConfig, postwithOu } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { SwitchProfile } from "../context/switchProfile.context";
import swal from "sweetalert";
const NegotiationModal = ({ productDetail, buyerRole }) => {
  // const [paymentType, setPaymentType] = useState("");
  const { setBuyerAccount, setSellerAccount } = useContext(SwitchProfile);
  const [selectedOption, setSelectedOption] = useState("advance");
  const [amount, setAmount] = useState();
  const [gst, setGst] = useState(0);
  const [DataState, setDataState] = useState({
    gst: 0,
    freightCharge: 0,
    amount: 0,
    credit: 0,
  });
  const [freightCharge, setFreightCharge] = useState(0);

  const [totalInvoice, setTotalInvoice] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const CloseRef = useRef(null);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setAmount(0)
  };

  const handleAmount = (e) => {
    if (!e.target.value) {
      setAmount();
    } else {
      setAmount(e.target.value);
    }
  };

  const handleQuantity = (e) => {
    if (e.target.value === 0) {
      setQuantity(productDetail?.order_qty);
    }
    setQuantity(e.target.value);
  };

  const addOrderToCart = async (button) => {
    const formData = new FormData();
    formData.append("product_id", productDetail?.id);
    formData.append("payment_type", selectedOption);
    formData.append(
      "quantity",
      quantity >= productDetail.order_qty ? quantity : productDetail.order_qty
    );
    formData.append(
      "gst",
      productDetail?.gst_rate !== null ? productDetail?.gst_rate : gst
    );
    formData.append(
      "amount",
      amount !== 0 || amount.length < 1
        ? amount
        : selectedOption === "advance"
        ? productDetail.amount
        : productDetail.credit_amount
    );
    formData.append("can_revise_offer", 1);
    formData.append("hsn_code", productDetail.hsn_code);
    await postwithOu(
      "/negotiation/create_from_product",
      getAuthConfig(),
      formData
    )
      .then((res) => {
        if (button === "buttonView") {
          CloseRef.current.click();
          if (buyerRole === 0) {
            toast.success(`Your Account is switched to Buyer`);
            accountSwitch();
          } else {
            navigate(`/negotiation-buyer`);
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const accountSwitch = async () => {
    await postwithOu("/user/account_switch", getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.data?.is_seller === 1) {
            setSellerAccount(true);
            localStorage.setItem("accountFor", "seller");
          }
          if (res?.data?.data?.is_buyer === 1) {
            setBuyerAccount(true);
            localStorage.setItem("accountFor", "buyer");
          }

         setTimeout(() => {
           navigate(`/negotiation-buyer`);
         }, [2000]);
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    totalFunction();
  }, [
    selectedOption,
    productDetail.amount,
    productDetail.credit_amount,
    productDetail.gst_rate,
    productDetail.freight_charges,
    productDetail.order_qty,
    quantity,
    amount,
  ]);

  useEffect(() => {
    if (productDetail?.amount != null) {
      setDataState((p) => ({ ...p, amount: productDetail?.amount }));
    }

    if (productDetail?.credit_amount != null) {
      setDataState((p) => ({ ...p, credit: productDetail?.credit_amount }));
    }
  }, []);

  const totalFunction = () => {
    let totalAmount;
    if (amount === 0 || amount === null || amount === undefined) {
      totalAmount =
        Number(
          quantity >= productDetail?.order_qty
            ? quantity
            : productDetail.order_qty
        ) *
        Number(
          selectedOption === "advance"
            ? productDetail.amount
            : productDetail.credit_amount
        );
    } else {
      totalAmount =
        Number(
          quantity >= productDetail?.order_qty
            ? quantity
            : productDetail.order_qty
        ) * Number(amount);
    }
    let totalFreight;
    if (productDetail?.freight_charges !== 0) {
      totalFreight =
        (Number(totalAmount) *
          Number(
            productDetail?.freight_charges
              ? productDetail?.freight_charges
              : freightCharge
          )) /
        100;
    } else {
      totalFreight = 0;
    }
    const totalAfterFreight = Number(totalAmount) + Number(totalFreight);
    const totalGst =
      (Number(totalAfterFreight) * Number(productDetail.gst_rate)) / 100;
    const total = totalAfterFreight + totalGst;
    setTotalInvoice(total);
  };

  // useEffect(()=>{
  //   let total = 0

  //   const totalGrossValue =
  // },[])

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="NegotiationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="NegotiationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Negotiation
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post-social-head row">
                <div className="select-wrap col-sm">
                  <div className="row">
                    <div className="col-sm">
                      <label className="">Payment Type</label>
                      <br />
                      <input
                        type="radio"
                        name="paymentTypes"
                        value="advance"
                        id="advances"
                        checked={selectedOption === "advance"}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor="advances"
                        style={{ fontWeight: "normal" }}
                      >
                        Advance
                      </label>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        name="paymentTypes"
                        value="credit"
                        id="credits"
                        checked={selectedOption === "credit"}
                        onChange={handleOptionChange}
                      />
                      <label htmlFor="credits" style={{ fontWeight: "normal" }}>
                        Credit
                      </label>
                      <div className="form-field">
                        <label className="form-label">
                          Price from seller (Per Qty)
                        </label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="paymentAmount"
                          id="paymentAmount"
                          disabled={true}
                          className="form-input"
                          placeholder="Amount"
                          value={
                            selectedOption === "advance"
                              ? productDetail?.amount
                              : productDetail?.credit_amount
                          }
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">Quantity  {productDetail?.unit?.name ?  "/ " + productDetail?.unit?.name : ""}</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="quantity"
                          value={
                            quantity !== 0 ||
                            quantity >= productDetail.order_qty
                              ? quantity
                              : productDetail?.order_qty
                          }
                          id="quantity"
                          onChange={handleQuantity}
                          className="form-input"
                          placeholder="Quantity"
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">My Price (Per Qty)</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="amount"
                          value={amount}
                          id="amount"
                          onChange={(e)=>{
                            if(selectedOption === "advance" &&  Number(e.target.value) >=  Number(productDetail?.amount)){

                              swal("warning", "Negotiation price must be less than seller offer price", "warning");
                            }
                            else if(selectedOption === "credit" &&  Number(e.target.value) >=  Number(productDetail?.credit_amount)){
                              swal("warning", "Negotiation price must be less than seller offer price", "warning");

                            }
                            else{
                              handleAmount(e)
                            }
                            }
                          }
                          className="form-input"
                          placeholder="Please enter your price"
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">GST (%)</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="gst"
                          id="gst"
                          value={productDetail?.gst_rate}
                          disabled
                          className="form-input"
                          placeholder="GST"
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">Freight Charge (%)</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="freight"
                          id="freight"
                          className="form-input"
                          placeholder="Freight"
                          value={productDetail.freight_charges}
                          disabled
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">HSN Code</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="hsn"
                          id="hsn"
                          value={productDetail?.hsn_code}
                          disabled
                          className="form-input"
                          placeholder="HSN Code"
                        />
                      </div>
                      <span>
                        <b>Total Invoice Value</b> : {`${totalInvoice}`}
                      </span>
                      <div>{/* {`Total Invoice Value : ${}`} */}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="button button-primary"
                onClick={() => addOrderToCart("buttonView")}
              >
                Send Negotiation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NegotiationModal;
