import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";

const SupportOrderListing = ({ order }) => {
  return (
    <>
      <Link state={{ id: order?.id , order:order }} to={"/support"}>
        <div className="order-item">
          <div className="image-block">
            <img src={order?.products[0]?.thumb_image?.file} alt="ProductImg" />
          </div>
          <div className="content-block">
            <h6>{order?.products[0]?.name}</h6>
            <div className="company-name">
              <img src={order?.to_user?.company?.logo} alt="" />
              {order?.to_user?.company?.name}
            </div>
            <ul>
              <li>
                <label>Order ID</label>:<span>#{order?.id}</span>
              </li>
            </ul>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SupportOrderListing;