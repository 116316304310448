import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { getAuthConfig, postwithOu } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";
import { Editor } from "@tinymce/tinymce-react";

const BuyerTerms = ({ POID, OID, UPDATEData }) => {
  const CloseRef = useRef(null);
  const [buyerTerms, setbuyerTerms] = useState("");
  const [description, setDescription] = useState("");

  async function GeneratePurchaseOrder() {
    const formdata = new FormData();
    formdata.append("po_id", POID);
    formdata.append("order_id", OID);
    formdata.append("buyer_terms", buyerTerms);
    formdata.append("description", description);

    const data = await postwithOu(
      "/short_order/update/purchase-order",
      getAuthConfig(),
      formdata
    )
      .then((datta) => {
        if (datta.status === 200) {
          CloseRef.current.click();
          UPDATEData();
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          swal(
            "Warning",
            "You dont have right to update this transaction",
            "warning"
          );
        }
      });
  }

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="buyerTerms"
        tabindex="-1"
        role="dialog"
        aria-labelledby="buyerTerms"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Buyer Terms
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div class="col-sm">
                  <div className="form-field">
                    <label className="form-label">Buyer Terms</label>
                    <Editor
                      apiKey="5ti6dhxcp0v40nnj6xwi874c19ixiq2qtqucc2zlvfxxxs8j"
                      value={buyerTerms}
                      init={{
                        height: 200,
                        menubar: false,
                      }}
                      onEditorChange={(content, editor) => {
                        setbuyerTerms(content);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-field">
                <label className="form-label">Order Description</label>
                <input
                  type="textarea"
                  name="description"
                  id="description"
                  className="form-input"
                  placeholder="Order Description"
                  value={description}
                  onChange={(e) => {
                    e.preventDefault();
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="card-footer">
              <button
                className="button button-primary"
                onClick={(e) => {
                  e.preventDefault();
                    GeneratePurchaseOrder();
                }}
              >
                Generate Purchase Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerTerms;
