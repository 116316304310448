import React from 'react'
import '../common/scss/pages/short-order.scss'
import '../common/scss/pages/ads.scss'
import Header from '../common/header'
import { ReactComponent as BusiMeetPremiumIcon } from '../assets/images/busimeet-premium.svg'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { Link, useNavigate } from 'react-router-dom';
function SubscriptionModal () {
  return (
    <>
      <Header home />
      <div className='grey-bg subscription-modal'>
        <div className='container-fluid'>
          <div className='subscription-modal-inner'>
            <div className='row'>
              <div className='col-sm-7 left-content'>
                <h2>Simple pricing for your Business</h2>
                <p>We have several powerful plans to showcase your business and get discovered as a creative entrepreneurs. Everything you need.</p>
                <div className='subscription-plan'>
                  <ul>
                    <li>You can avail one slot for campaign and one slot for featured ads</li>
                    <li>Cancel anytime</li>
                  </ul>
                </div>
              </div>
              <div className='col-sm-5'>
              <Accordion className='plans-accordion'>
                <AccordionItem activeClassName="accordion__item active">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <div className='subscription-head'>
                          <BusiMeetPremiumIcon /> <strong>BusiMeet</strong>
                        </div>
                        BusiMeet Elite
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Exercitation in fugiat est ut ad ea cupidatat ut in
                            cupidatat occaecat ut occaecat consequat est minim minim
                            esse tempor laborum consequat esse adipisicing eu
                            reprehenderit enim.
                        </p>
                        <div className='row'>
                          <div className='col-sm'><div className='subscription-price'>1199<span>/Year</span><span className='old-price'>2400</span></div></div>
                          <div className='col-sm btn-wrap'><button className='button button-black'>Buy Subscription</button></div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem activeClassName="accordion__item active">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <div className='subscription-head'>
                          <BusiMeetPremiumIcon /> <strong>BusiMeet</strong>
                        </div>
                        BusiMeet Pro
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                        </p>
                        <div className='row'>
                          <div className='col-sm'><div className='subscription-price'>1199<span>/Year</span><span className='old-price'>2400</span></div></div>
                          <div className='col-sm btn-wrap'><button className='button button-black'>Buy Subscription</button></div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem activeClassName="accordion__item active">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <div className='subscription-head'>
                          <BusiMeetPremiumIcon /> <strong>BusiMeet</strong>
                        </div>
                        BusiMeet Premium
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Pro account gives you freedom choose Campaign week slot in advanced for yearly, give primium badge and also give you 24/7 customer support.</p>
                        <div className='row'>
                          <div className='col-sm'><div className='subscription-price'>1199<span>/Year</span><span className='old-price'>2400</span></div></div>
                          <div className='col-sm btn-wrap'><button className='button button-black'>Buy Subscription</button></div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionModal
