import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { ReactComponent as SaveDraftIcon } from "../assets/images/save-draft.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { cloneDeep } from "lodash";

function ProductShortOrder() {
  const location = useLocation();
  const SOID =
    location?.state?.shortOrder === true
      ? location?.state?.id
      : location?.state?.id;

  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState();
  const [value, setValue] = useState([]);
  const [GstAvg, setGstAvg] = useState(0);
  const navigate = useNavigate();
  const [TotalData, setTotalData] = useState({
    total: 0,
    freightCharge: 0,
    afterFreight: 0,
    gst: 0,
    totolInvoice: 0,
  });

  useEffect(() => {
    TotalDataFunc();
  }, [products]);

  useEffect(() => {
    TotalDataFunc();
  }, [value]);

  useEffect(() => {
    FreightCharfe();
  }, [TotalData?.total]);
  useEffect(() => {
    afterFreight();
  }, [TotalData?.freightCharge]);

  useEffect(() => {
    GstWithAvg();
  }, [TotalData?.afterFreight]);

  useEffect(() => {
    FinalInvoic();
  }, [TotalData?.gst]);

  function TotalDataFunc() {
    let ttal = 0;

    products?.map((item, index) => {
      if (value[index] === undefined) {
        ttal = ttal + Number(item?.quantity) * Number(item?.amount);
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        ttal = ttal + Number(item?.quantity) * Number(item?.amount);
      } else {
        // alert(value[index])
        ttal = ttal + Number(item?.quantity) * Number(value[index]);
      }
    });
    setTotalData((p) => ({ ...p, total: ttal }));
  }

  function FreightCharfe() {
    if (TotalData?.total > 0) {
      let fData =
        (Number(shortOrderDetails?.freight_charge?.percentage) *
          Number(TotalData?.total)) /
        100;
      setTotalData((p) => ({ ...p, freightCharge: fData }));
      afterFreight()
    }
  }

  function afterFreight() {
    let Aftr = TotalData?.total + TotalData?.freightCharge;
    setTotalData((p) => ({ ...p, afterFreight: Aftr }));
  }
  function GstWithAvg() {
    let gst = 0;
    let freight = 0;

    products?.map((item, index) => {
      freight = 0;

      if (value[index] === undefined) {
        freight =
          (Number(item?.quantity) *
            Number(item?.amount) *
            Number(shortOrderDetails?.freight_charge?.percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.amount) + freight) *
            Number(item?.gst?.percentage)) /
            100;
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        freight =
          (Number(item?.quantity) *
            Number(item?.amount) *
            Number(shortOrderDetails?.freight_charge?.percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.amount) + freight) *
            Number(item?.gst?.percentage)) /
            100;
      } else {
        // alert(value[index])
        freight =
          (Number(item?.quantity) *
            Number(value[index]) *
            Number(shortOrderDetails?.freight_charge?.percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(value[index]) + freight) *
            Number(item?.gst?.percentage)) /
            100;
        // gst = gst + (Number(item?.quantity) * Number(value[index]));
      }
    });
    let gstW = (TotalData?.total * GstAvg) / 100;
    setTotalData((p) => ({ ...p, gst: gst }));
  }
  function FinalInvoic() {
    let Final = TotalData?.afterFreight + TotalData?.gst;

    setTotalData((p) => ({ ...p, totolInvoice: Final }));
  }

  const sendNegotiation = async (action) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one field");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price ");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      formData.append("order_id", shortOrderDetails?.id);
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        // check if price is greater than 0
        const price = value[i];
        if (price > 0) {
          // append order_item_id and price to formData
          formData.append(`negotiation[${i}][order_item_id]`, orderItemId);
          formData.append(`negotiation[${i}][price]`, price);
        }
      }
      formData.append("type", action);
      await post("/short_order/move_to_negotiation", formData, getAuthConfig())
        .then((res) => {
          toast.success("Offer is sent successfully");
          setTimeout((e) => {
            navigate("/negotiation-buyer");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  const getProductCartSO = async () => {
    setLoading(true);
    let url;
    if (location?.state?.shortOrder === true) {
      url = `/short_order/details/${SOID}`;
    } else {
      url = `/negotiation/details/${SOID}`;
    }

    await get(url, getAuthConfig())
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
        let gstSumm = 0;

        res?.data?.data?.items.map((item, index) => {
          gstSumm = gstSumm + Number(item?.gst?.percentage);
        });
        setGstAvg(
          parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
        );
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
          navigate("/negotiation-buyer");
        }
      });
  };

  const handleBuyerNegotiationPrice = (e, index) => {
    const enteredPrice = e.target.value;
    setValue((prev) => {
      const newDivisions = cloneDeep(prev);
      newDivisions[index] = e.target.value;
      setIndex(index);

      // Total Gross Field Calculation
      const item = products[index];
      const qty = item?.quantity;
      const subTotalDifference = enteredPrice * qty;
      // const subTotalDifference = enteredPrice;

      // const td1 = e.target.parentNode.nextElementSibling;
      // td1.innerText = `₹${subTotalDifference}`;

      // Total Field Calculation
      let total = 0;
      let grandTotalDifference = 0;

      for (let i = 0; i < newDivisions.length; i++) {
        const grandTotal = shortOrderDetails?.sub_total;
        total += parseFloat(newDivisions[i] || 0);
        grandTotalDifference = grandTotal - total;
      }
      return newDivisions;
    });
  };

  UseEffectOnce(() => {
    getProductCartSO();
  });

  const deleteProductCartSO = async (item) => {
    setLoading(true);
    if (
      shortOrderDetails?.sub_total - item?.sub_total <
      shortOrderDetails?.min_order_value
    ) {
      setLoading(false);
      toast.error(
        "Minimum order value not fulfilled if you remove this product from cart"
      );
    } else {
      await get(`/short_order/delete_item/${item?.id}`, getAuthConfig())
        .then((res) => {
          if (
            (Number(res?.data?.data?.sub_total) <
              Number(shortOrderDetails?.min_order_value),
            "0---compare")
          );
          setLoading(false);
          getProductCartSO();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order product-order">
            <div className="page-title">
              <h6>Buyer Send Negotiation Price / Buyer View</h6>
            </div>
            <div className="order-table">
              <span className="order-id">
                <strong>Order ID #BUM234324</strong>
              </span>
              {!loading && products?.length ? (
                <table>
                  <thead>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Category, Product Sub Category</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      <th className="height-light">Buyer Negotiation Price</th>
                      <th>Total Gross Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?.id}>
                            <td>{index + 1}</td>
                            <td>{`${item?.product?.category?.name}`}</td>
                            <td>
                              {item?.product?.brand?.name &&
                                `Brand: ${item?.product?.brand?.name},`}
                              {item?.product?.size &&
                                `Size: ${item?.product?.size},`}
                              {item?.product?.color?.name &&
                                `Color: ${item?.product?.color?.name}`}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.gst?.percentage}</td>
                            <td>₹{item?.amount}</td>
                            <td className="height-light">
                              <input
                                placeholder="Enter Value"
                                value={value[index]}
                                type="number"                         onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  if (
                                    Number(e.target.value) >=
                                    Number(item?.amount)
                                  ) {
                                    swal(
                                      "warning",
                                      "Negotiation price must be less than seller offer price",
                                      "warning"
                                    );
                                  } else {
                                    handleBuyerNegotiationPrice(e, index);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              ₹
                              {value[index] != undefined && value[index] > 0
                                ? parseFloat(
                                    value[index] * item?.quantity
                                  ).toFixed(2)
                                : item?.sub_total}
                            </td>
                            <td>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to remove this item from cart ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      deleteProductCartSO(item);
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total</td>
                      <td className="height-light">
                        ₹{parseFloat(TotalData?.total).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light percent">
                        {shortOrderDetails?.freight_charge?.percentage}% Freight
                      </td>
                      <td className="height-light charge">
                        ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total after freight</td>
                      <td className="height-light totalFreight">
                        ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">GST </td>
                      <td className="height-light gst">
                        ₹{parseFloat(TotalData?.gst)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="footer">
                      <td className="height-light">Profile ID</td>
                      <td className="height-light">
                        {shortOrderDetails?.user?.id}
                      </td>
                      <td colSpan={"2"} className="height-light">
                        Payment Terms
                      </td>
                      <td colSpan={"2"} className="height-light">
                        {shortOrderDetails?.payment_type}
                      </td>
                      <td className="height-light">Total Invoice Value</td>
                      <td className="height-light invoice">
                        ₹{parseFloat(TotalData?.totolInvoice).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}

              <div className="btn-wrap">
                <button
                  // to={"/product-company-detail"}
                  className="button button-primary button-primary--bordered"
                  onClick={() => {
                    sendNegotiation("initial_offer");
                  }}
                >
                  <SendOfferIcon />
                  Send Revise Offer
                </button>
                <button
                  className="button button-blue button-blue--bordered"
                  onClick={() => {
                    sendNegotiation("draft");
                  }}
                >
                  <SaveDraftIcon />
                  Save Draft
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductShortOrder;
