import { useContext, useEffect, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { ReactComponent as DeleteIcon } from "../assets/images/delete-icon.svg";
import { ReactComponent as UploadIcon } from "../assets/images/upload-icon.svg";
import FieldValidationError from "../components/error-messages/field-validation-error";
import { useParams } from "react-router-dom";

function EditVisitingCard(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const user = Auth.getCurrentUser();
  let editVisitingCardData = props.data;

  const [logoImage, setLogoImage] = useState("");
  const [logoImage2, setLogoImage2] = useState("");
  const [imgPreview, setImgPreview] = useState();
  const [imgPreview2, setImgPreview2] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isUpdated2, setIsUpdated2] = useState(false);
  const [deleteVisitingCard, setDeleteVisitingCard] = useState(true);
  const [deleteVisitingCard2, setDeleteVisitingCard2] = useState(true);
  const [dataIsUrl, setDataIsUrl] = useState(false);
  const [dataIsUrl2, setDataIsUrl2] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);
  const [showDeletionError, setShowDeletionError] = useState(false);
  const [showDeletionError2, setShowDeletionError2] = useState(false);

  useEffect(() => {
    if (
      (editVisitingCardData !== null || editVisitingCardData !== undefined) &&
      typeof editVisitingCardData === "string"
    ) {
      setDeleteVisitingCard(false);
      setLogoImage(editVisitingCardData);
      setDataIsUrl(true);
    }
  }, []);

  const params = useParams();

  const handleUploadVisitingCard = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    try {
      // Handle front side
      if (typeof logoImage === "string" && logoImage.startsWith("https://")) {
        const response = await fetch(logoImage);
        const blob = await response.blob();
        const file = new File(
          [blob],
          `${logoImage.substring(logoImage.lastIndexOf("/") + 1)}`,
          { type: blob.type }
        );
        formData.append("visiting_card", file, file.name);
      } else if (logoImage) {
        formData.append("visiting_card", logoImage.data, logoImage.data.name);
      }

      // Handle back side
      if (logoImage2) {
        if (
          typeof logoImage2 === "string" &&
          logoImage2.startsWith("https://")
        ) {
          const response = await fetch(logoImage2);
          const blob = await response.blob();
          const file = new File(
            [blob],
            `${logoImage2.substring(logoImage2.lastIndexOf("/") + 1)}`,
            { type: blob.type }
          );
          formData.append("visiting_card_back", file, file.name);
        } else {
          formData.append(
            "visiting_card_back",
            logoImage2.data,
            logoImage2.data.name
          );
        }
      }

      // Log FormData entries for debugging
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      // Make the single API call
      const response = await post(`/user/visiting_card`, formData, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      alert("Something went wrong with the upload");
      console.error("Upload error:", error);
    }
  };

  const handleImageChange = (e) => {
    let img = { data: e.target.files[0] };
    setIsUpdated(true);
    setLogoImage(img);
    setImgPreview(img.data);
    setDeleteVisitingCard(false);
  };
  const handleImageChangeBackside = (e) => {
    let img = { data: e.target.files[0] };
    setIsUpdated2(true);
    setLogoImage2(img);
    setImgPreview2(img.data);
    setDeleteVisitingCard2(false);
  };
  const handleDeleteVisitingCard = () => {
    setLogoImage();
    setImgPreview();
    setDeleteVisitingCard(true);
    setDataIsUrl(false);
    setIsUpdated(false);

    post(
      `/user/visiting_card`,
      { visiting_card: null },
      { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {})
      .catch((e) => {
        setShowDeletionError(true);
      });
  };

  const handleDeleteBackSideVisitingCard = () => {
    setLogoImage2();
    setImgPreview2();
    setDeleteVisitingCard2(true);
    setDataIsUrl2(false);
    setIsUpdated2(false);

    post(
      `/user/visiting_card`,
      { visiting_card: null },
      { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {})
      .catch((e) => {
        setShowDeletionError2(true);
      });
  };

  return (
    <>
      <div
        className="modal fade certifications-modal"
        id="VisitingCardModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="VisitingCardModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">{`${
                user?.slug === params.slug
                  ? "Edit Your Visiting Card"
                  : "Visiting Card"
              }`}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span style={{ fontSize: "16px", fontWeight: 700 }}>
                    Front Side
                  </span>
                  <div className="upload">
                    <div className="upload-image" style={{ margin: "auto" }}>
                      {isUpdated ? (
                        <>
                          <div className="img-block">
                            {user?.slug === params.slug ? (
                              <>
                                <button
                                  onClick={() => handleDeleteVisitingCard()}
                                  className="btn btn-danger"
                                  style={{ float: "right" }}
                                >
                                  <DeleteIcon />
                                </button>

                                <input
                                  className="img-block"
                                  type="file"
                                  disabled
                                  placeholder="Upload New Visiting Card"
                                  onChange={handleImageChange}
                                />
                                <img src={URL.createObjectURL(imgPreview)} />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {dataIsUrl ? (
                        <>
                          <div className="img-block">
                            {user?.slug === params.slug && (
                              <>
                                <button
                                  onClick={() => handleDeleteVisitingCard()}
                                  className="btn btn-danger"
                                  style={{ float: "right" }}
                                >
                                  <DeleteIcon />
                                </button>

                                <input
                                  className="img-block"
                                  disabled
                                  type="file"
                                  placeholder="Upload New Visiting Card"
                                  onChange={handleImageChange}
                                />
                              </>
                            )}
                            <img src={logoImage} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {deleteVisitingCard && user?.slug === params.slug ? (
                        <>
                          <div>
                            <input
                              type="file"
                              className="img-block"
                              onChange={handleImageChange}
                            />
                            <label htmlFor="company_logo">
                              <UploadIcon />
                              <span>
                                Drag n Drop here Or{" "}
                                <span className="color-primary">Browse</span>
                              </span>
                            </label>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {user?.slug != params.slug &&
                      editVisitingCardData === null
                        ? "No Visiting Card Found"
                        : ""}
                    </div>
                    {showDeletionError ? (
                      <FieldValidationError message="Unable to delete the visiting card!!"></FieldValidationError>
                    ) : null}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <span style={{ fontSize: "16px", fontWeight: 700 }}>
                    Back Side
                  </span>
                  <div className="upload">
                    <div className="upload-image" style={{ margin: "auto" }}>
                      {isUpdated2 ? (
                        <>
                          <div className="img-block">
                            {user?.slug === params.slug ? (
                              <>
                                <button
                                  onClick={() =>
                                    handleDeleteBackSideVisitingCard()
                                  }
                                  className="btn btn-danger"
                                  style={{ float: "right" }}
                                >
                                  <DeleteIcon />
                                </button>

                                <input
                                  className="img-block"
                                  type="file"
                                  disabled
                                  placeholder="Upload New Visiting Card"
                                  onChange={handleImageChangeBackside}
                                />
                                <img src={URL.createObjectURL(imgPreview2)} />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {dataIsUrl2 ? (
                        <>
                          <div className="img-block">
                            {user?.slug === params.slug && (
                              <>
                                <button
                                  onClick={() =>
                                    handleDeleteBackSideVisitingCard()
                                  }
                                  className="btn btn-danger"
                                  style={{ float: "right" }}
                                >
                                  <DeleteIcon />
                                </button>

                                <input
                                  className="img-block"
                                  disabled
                                  type="file"
                                  placeholder="Upload New Visiting Card"
                                  onChange={handleImageChangeBackside}
                                />
                              </>
                            )}
                            <img src={logoImage2} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {deleteVisitingCard2 && user?.slug === params.slug ? (
                        <>
                          <div>
                            <input
                              type="file"
                              className="img-block"
                              onChange={handleImageChangeBackside}
                            />
                            <label htmlFor="company_logo">
                              <UploadIcon />
                              <span>
                                Drag n Drop here Or{" "}
                                <span className="color-primary">Browse</span>
                              </span>
                            </label>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {user?.slug != params.slug &&
                      editVisitingCardData === null
                        ? "No Visiting Card Found"
                        : ""}
                    </div>
                    {showDeletionError2 ? (
                      <FieldValidationError message="Unable to delete the visiting card!!"></FieldValidationError>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="upload-button text-right">
                {user?.slug === params.slug && (
                  <button
                    disabled={disabledBtn}
                    className="button"
                    onClick={(e) => handleUploadVisitingCard(e)}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditVisitingCard;
