import { useContext, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../common/scss/pages/ads.scss'
import { ReactComponent as Close2Icon } from '../assets/images/close2-icon.svg'
import { ReactComponent as LockIcon } from '../assets/images/lock-icon.svg'
import profilePic from "../assets/images/profile.png";
function ProceedAds () {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
   return (
        <>
        <div className="modal fade proceed-modal" id="ProceedAdsModal" tabindex="-1" role="dialog" aria-labelledby="ProceedAdsModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className='modal-head'>
                <div className='profile'>
                  <div className='profile-pic'><img src={profilePic} /></div>
                  <div className='profile-content'><h6>Acme Crop</h6><p>order_Hf3JoNYdRcANSv</p><div className='price'>₹ 6,000</div></div>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><Close2Icon /></span>
                </button>
              </div>
              <div className="modal-body">
                <div className='row'>
                  <div class="col-sm-3">
                    <div className="form-field">
                      <label className="form-label">Country</label>
                      <select className="form-input"><option>+91</option></select>
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <div className="form-field">
                      <label className="form-label">Phone</label>
                      <input className="form-input" type='text' placeholder='Enter Phone No.' value='9999999999' />
                    </div>
                  </div>
                </div>
                <div className='row'>
                <div class="col-sm">
                    <div className="form-field">
                      <label className="form-label">Email</label>
                      <input className="form-input" type='email' placeholder='Enter Email' value='admin@gmail.com' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <p><LockIcon />This payment is secured by Razorpay</p>
                <button className='button button-primary'>Proceed</button>
              </div>
            </div>
          </div>
        </div>
        </>
  )
}

export default ProceedAds