import React from 'react';

const urlRegex = /(https?:\/\/[^\s]+)/g;

function DescriptionWithLinks({ description }) {
  const segments = description.split(urlRegex);

  return (
    <div>
      {segments.map((segment, index) => {
        if (urlRegex.test(segment)) {
          // Segment is a URL, render it as a link
          return (
            <a
              key={index}
              href={segment}
              target="_blank"
              rel="noopener noreferrer"
            >
              {segment}
            </a>
          );
        } else {
          // Segment is not a URL, render it as raw HTML
          return (
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: segment }}
            ></span>
          );
        }
      })}
    </div>
  );
}

export default DescriptionWithLinks;
