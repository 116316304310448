import React, { createContext, useCallback, useState } from "react";

const initialState = {
  buyer: false,
};

export const SwitchProfile = createContext(null);

export const SwitchProfileProvider = (props) => {
  const [accountFor, setAccountFor] = useState(initialState);
  const initialAccount = JSON.parse(localStorage.getItem("buyerAccount"));

  const setBuyer = () => {
    setAccountFor({ buyer: true });
    localStorage.setItem("buyer", true);
  };
  const setSeller = () => {
    setAccountFor({ buyer: false });
    localStorage.setItem("buyer", false);
  };

  const setBuyerAccount = () => {
    setAccountFor({ buyer: true });
    localStorage.setItem("buyerAccount", true);
    localStorage.setItem("buyer", true);
  };
  const setSellerAccount = () => {
    setAccountFor({ buyer: false });
    localStorage.setItem("buyerAccount", false);
    localStorage.setItem("buyer", false);
  };

  const updateAccount = useCallback(
    (isBuyer) => {
      setAccountFor({ buyer: isBuyer });
      localStorage.setItem("buyerAccount", isBuyer);
    },
    []
  );

  const toggleAccount = useCallback(() => {
    updateAccount(!accountFor.buyer);
  }, [accountFor.buyer, updateAccount]);

  return (
    <SwitchProfile.Provider
      value={{
        accountFor,
        setBuyer,
        setSeller,
        toggleAccount,
        setBuyerAccount,
        setSellerAccount,
      }}
    >
      {props.children}
    </SwitchProfile.Provider>
  );
};
