import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../libs/http-hydrate";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import Auth from "../libs/auth";
function ProductBankDetail() {
  const [ShortOrderDetails, setShortOrderDetails] = useState({});
  const [sellerPaymentDetails, setSellerPaymentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const user = Auth.getCurrentUser();

  let currentStep;

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", ShortOrderDetails.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  const location = useLocation();

  const navigate = useNavigate();

  const getProductCartSO = async () => {
    setLoading(true);
    if (location?.state?.id) {
      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          setSellerPaymentDetails(res?.data?.data?.seller_payment_details);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  currentStep = payment === "advance" ? "5" : "8";

  return (
    <>
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              currentStep={Number(currentStep)}
              isCompleted={currentStep}
              // isCompleted={
              //   ShortOrderDetails?.step?.current_step >= 4 &&
              //   ShortOrderDetails?.payment_type === "advance"
              //     ? ShortOrderDetails?.step?.current_step
              //     : currentStep
              // }
            />
          </div>
          <div className="order product-order bank-detail-page">
            <div className="page-title">
              <h6>Bank Details - Sellers</h6>
            </div>
            <div className="company-detail bank-detail">
              <div className="flex-box">
                <div className="flex-item">
                  <div className="row info-box">
                    <div className="col-sm">
                      <div className="info-item">
                        <label>MOBILE NUMBER</label>
                        <span>
                          {
                            ShortOrderDetails?.seller_payment_details
                              ?.mobile_number
                          }
                        </span>
                      </div>
                    </div>
                    <div className="col-sm info-box">
                      <div className="info-item">
                        <label>BANK IFSC CODE</label>
                        <span>
                          {
                            ShortOrderDetails?.seller_payment_details
                              ?.bank_account_ifsc_code
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row info-box">
                    <div className="col-sm">
                      <div className="info-item">
                        <label>EMAIL</label>
                        <span>
                          {ShortOrderDetails?.seller_payment_details?.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm info-box">
                      <div className="info-item">
                        <label>BANK BRANCH</label>
                        <span>
                          {
                            ShortOrderDetails?.seller_payment_details
                              ?.bank_branch
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row info-box">
                    <div className="col-sm">
                      <div className="info-item">
                        <label>BANK NAME</label>
                        <span>
                          {ShortOrderDetails?.seller_payment_details?.bank_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row info-box">
                    <div className="col-sm">
                      <div className="info-item">
                        <label>BANK ACCOUNT NO</label>
                        <span>
                          {
                            ShortOrderDetails?.seller_payment_details
                              ?.bank_account_number
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row info-box">
                    <div className="col-sm">
                      <div className="info-item">
                        <label>SELLER NAME</label>
                        <span>
                          {ShortOrderDetails?.seller_payment_details?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user?.is_buyer === 1 &&
              sellerPaymentDetails &&
              Object.keys(sellerPaymentDetails).length === 0 && (
                <div className="btn-wrap">
                  <span className="text-danger">
                    Please wait until seller add payment details
                  </span>
                </div>
              )}
            {!loading && (
              <div className="btn-wrap">
                <Link
                  to={`${
                    payment === "advance"
                      ? "/product-purchase-invoice"
                      : "/product-delivered"
                  }`}
                  state={{
                    id: ShortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  className="button button-blue"
                >
                  Previous
                </Link>
                {user?.is_seller === 1 &&
                  Object.keys(sellerPaymentDetails).length === 0 && (
                    // <div className="btn-wrap">
                    <Link
                      className="button button-primary"
                      to={"/settings"}
                      state={{
                        gst: "true",
                        PreFilled: "true",
                        redirectid: ShortOrderDetails?.id,
                      }}
                    >
                      Add Payment Details
                    </Link>
                    // </div>
                  )}
                {sellerPaymentDetails &&
                Object.keys(sellerPaymentDetails).length === 0 ? (
                  <button disabled className="button button-secondary">
                    Next
                  </button>
                ) : (
                  <Link
                    state={{
                      id: ShortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    to="/product-payment"
                    className="button button-primary"
                  >
                    Next
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductBankDetail;
