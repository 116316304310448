import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { SwitchProfile } from "../context/switchProfile.context";
import Auth from "../libs/auth";

const AcceptDeal = ({ productDetail, buyerRole }) => {
  const { setBuyerAccount, setSellerAccount } = useContext(SwitchProfile);
  const user = Auth.getCurrentUser();
  const [selectedOption, setSelectedOption] = useState("advance");
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [freightCharge, setFreightCharge] = useState(0);
  const CloseRef = useRef(null);
  const radioRef1 = useRef(null);
  const radioRef2 = useRef(null);
  const navigate = useNavigate();
  const [DataState, setDataState] = useState({
    gst: 0,
    freightCharge: 0,
    amount: 0,
    credit: 0,
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleQuantity = (e) => {
    if (e.target.value === 0) {
      setQuantity(productDetail?.order_qty);
    }
    setQuantity(e.target.value);
  };

  const sendInitialOffer = async (id) => {
    await get(`/short_order/send_offer/${id}`, getAuthConfig())
      .then((res) => {
        toast.success("Offer sent successfully");
        if (res.status === 200) {
          setTimeout(() => {
            navigate(`/transaction`);
          }, [2000]);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const addOrderToCart = async (button) => {
    const formData = new FormData();
    formData.append("product_id", productDetail?.id);
    formData.append("payment_type", selectedOption);
    formData.append(
      "quantity",
      quantity !== 0 ? quantity : productDetail.order_qty
    );
    formData.append("gst", productDetail?.gst_rate);
    formData.append(
      "freight_charge_percentage",
      productDetail?.freight_charges
    );
    formData.append(
      "amount",
      selectedOption === "advance"
        ? productDetail.amount
        : productDetail.credit_amount
    );
    formData.append("hsn_code", productDetail?.hsn_code);
    await postwithOu("/short_order/cart", getAuthConfig(), formData)
      .then((res) => {
        // localStorage.setItem("SOID", res?.data?.data?.id);
        if (button === "buttonView" && res.status === 200) {
          sendInitialOffer(res?.data?.data?.id);
          CloseRef.current.click();
          if (buyerRole === 0) {
            toast.success(`Your Account is switched to Buyer`);
            accountSwitch();
          }
        }
        // if(res)
        // setTimeout(() => {
        //   navigate(`/transaction`);
        // }, [2000]);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const accountSwitch = async () => {
    await postwithOu("/user/account_switch", getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          // toast.success(
          //   `Your Account is switched to ${
          //     res.data.data.is_buyer ? "buyer" : "seller"
          //   }`
          // );

          if (res?.data?.data?.is_seller === 1) {
            setSellerAccount(true);
            localStorage.setItem("accountFor", "seller");
          }
          if (res?.data?.data?.is_buyer === 1) {
            setBuyerAccount(true);
            localStorage.setItem("accountFor", "buyer");
          }
          setTimeout(() => {
            navigate(`/transaction`);
          }, [2000]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    totalFunction();
  }, [
    selectedOption,
    productDetail.amount,
    productDetail.credit_amount,
    productDetail.gst_rate,
    productDetail.freight_charges,
    productDetail.order_qty,
    quantity,
  ]);

  useEffect(() => {
    if (productDetail?.amount != null) {
      setDataState((p) => ({ ...p, amount: productDetail?.amount }));
    }

    if (productDetail?.credit_amount != null) {
      setDataState((p) => ({ ...p, credit: productDetail?.credit_amount }));
    }
  }, []);

  const totalFunction = () => {
    const totalAmount =
      Number(quantity !== 0 ? quantity : productDetail.order_qty) *
      Number(
        selectedOption === "advance"
          ? productDetail.amount
          : productDetail.credit_amount
      );
    let totalFreight;
    if (productDetail?.freight_charges !== 0) {
      totalFreight =
        (Number(totalAmount) *
          Number(
            productDetail?.freight_charges
              ? productDetail?.freight_charges
              : freightCharge
          )) /
        100;
    } else {
      totalFreight = 0;
    }
    const totalAfterFreight = Number(totalAmount) + Number(totalFreight);
    const totalGst =
      (Number(totalAfterFreight) * Number(productDetail.gst_rate)) / 100;
    const total = totalAfterFreight + totalGst;
    setTotalInvoice(total);
  };

  return (
    <>
      <ToastContainer />

      <div
        className="modal fade post-social-modal"
        id="accpetDeal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="accpetDeal"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Accept Deal
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="post-social-head row">
                <div className="select-wrap col-sm">
                  <div className="row">
                    <div className="form-field col-sm">
                      <label className="form-label">Payment Type</label>
                      <br />
                      <input
                        type="radio"
                        name="paymentType"
                        value="advance"
                        id="advanceteste"
                        checked={selectedOption === "advance"}
                        onChange={handleOptionChange}
                        ref={radioRef1}
                      />
                      <label
                        htmlFor="advanceteste"
                        style={{ fontWeight: "normal" }}
                      >
                        Advance
                      </label>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        name="paymentType"
                        value="credit"
                        id="creditteste"
                        checked={selectedOption === "credit"}
                        onChange={handleOptionChange}
                        ref={radioRef2}
                      />
                      <label
                        htmlFor="creditteste"
                        style={{ fontWeight: "normal" }}
                      >
                        Credit
                      </label>
                      
                      
                    </div>
                  </div>
                  <div className="row">
                  <div className="form-field col-sm">
                        <label className="form-label">Amount</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="amount"
                          id="amount"
                          disabled={true}
                          className="form-input"
                          placeholder="Amount"
                          value={
                            selectedOption === "advance"
                              ? productDetail?.amount
                              : productDetail?.credit_amount
                          }
                        />
                      </div>
                  <div className="form-field col-sm">
                        <label className="form-label">
                          Quantity{" "}
                          {productDetail?.unit?.name
                            ? "/ " + productDetail?.unit?.name
                            : ""}
                        </label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="quantity"
                          value={
                            quantity !== 0 ? quantity : productDetail?.order_qty
                          }
                          onChange={handleQuantity}
                          id="quantity"
                          className="form-input"
                          placeholder="Quantity"
                        />
                      </div>
                      <div className="form-field col-sm">
                        <label className="form-label">Freight Charge (%)</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="freight"
                          id="freight"
                          className="form-input"
                          placeholder="Freight"
                          value={productDetail?.freight_charges}
                          disabled
                        />
                      </div>
                      
                  </div>
                  <div className="row">
                  <div className="form-field col-sm">
                        <label className="form-label">GST (%)</label>
                        <input
                          type="number"                         onWheel={(e) => e.target.blur()}
                          name="gst"
                          id="gst"
                          value={productDetail?.gst_rate}
                          disabled
                          className="form-input"
                          placeholder="GST"
                        />
                      </div>
                      <div className="form-field col-sm">
                        <label className="form-label">HSN Code</label>
                        <input
                          type="text"
                          name="hsn"
                          id="hsn"
                          value={productDetail?.hsn_code}
                          disabled
                          className="form-input"
                          placeholder="HSN Code"
                        />
                      </div>
                      
                  </div>
                  <div className="row">
                  <div className="col-sm">
                        <b>Total Invoice Value</b> : {`${totalInvoice}`}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="button button-primary ml-0"
                onClick={() => addOrderToCart("buttonView")}
              >
                Accept Deal
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptDeal;
