import { useContext, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../common/scss/pages/ads.scss'
import { ReactComponent as Close2Icon } from '../assets/images/close2-icon.svg'
import { ReactComponent as ConnectIcon } from "../assets/images/connect-icon.svg";
import { ReactComponent as MeetingRoomIcon } from "../assets/images/meeting-room-icon.svg";
import { ReactComponent as PostShareIcon } from "../assets/images/post-share-icon.svg";
import featuredTagIcon from '../assets/images/featured-tag.svg'
import profilePic from "../assets/images/profile.png";
function PreviewAds () {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
   return (
        <>
        <div className="modal fade preview-modal" id="PreviewAdsModal" tabindex="-1" role="dialog" aria-labelledby="PreviewAdsModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><Close2Icon /></span>
              </button>
              <div className="modal-body">
                <div className='ads-selected'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='featured'>
                        <div className='user-profile'>
                        <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                        <div className='user-profile-content'>
                          <div className='user-profile-name'>
                            <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                            <p>Owner at mehta brothers</p>
                          </div>
                          <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                        </div>
                      </div>
                      <div className='featured-details'>
                        <ul>
                          <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                          <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                          <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                          <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                        </ul>
                        <div className='featured-details--highlights'>
                          <strong>Industry: Industrial Supplies</strong>
                        </div>
                        <div className='featured-details--highlights'>
                          <strong>Deals In</strong>
                          <p>Abrasives, Power Tools, Hand To-
                            ols, and Industrial Safty Tools</p>
                        </div>
                        <div className='featured-actions'>
                          <button className='button button-primary button-connect'><ConnectIcon />Follow</button>
                          <button className='button button-primary button-connect'><MeetingRoomIcon />Meeting Room</button>
                          <button className='button button-blue button-connect'><PostShareIcon /></button>
                        </div>
                      </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='selected-slot'>
                        <h6>Selected Week Slots</h6>
                        <Slider {...settings} className="slot-week">
                          <div>
                            <div className='slot-name'>June <span>(Current Month)</span></div>
                            <div className='selected-slot-week'>
                              <strong>You Choose June 3rd Week</strong>
                              <span>20th June to 26th June - Monday to Sunday</span>
                            </div>
                          </div>
                          <div>
                            <div className='slot-name'>July</div>
                            <div className='selected-slot-week'>
                              <strong>You Choose June 3rd Week</strong>
                              <span>20th June to 26th June - Monday to Sunday</span>
                            </div>
                          </div>
                        </Slider>
                        <p>If You Need To Change Week Slots <a href=''>Click Here</a></p>
                      </div>
                      <div className='action'>
                        <h6>Actions</h6>
                        <button className='button button-green'>Edit Compaign</button>
                        <button className='button button-blue'>Save Darft</button>
                        <button className='button button-primary'>Submit Ad</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
  )
}

export default PreviewAds