import { useContext, useState, useEffect } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as CheckMark } from "../assets/images/check-mark.svg";
import { ReactComponent as CheckMarkFill } from "../assets/images/checkFill.svg";
import ProfilePic from "../assets/images/profile.png";
import { getAuthConfig, postwithOu, get, post } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";

function PostHideFrom(props) {
  const [users, setUsers] = useState(
    {
      items:[]
    }
    );
  const [loading, setLoading] = useState(false);
  const [RUser, setRUser] = useState([]);
  const [Filter,setFilter] = useState({
    text:""
  })

  useEffect(() =>{
      if(Filter?.text === "" && props?.id != undefined){
        hideUserRequestList()
      }
      else{

      
        hideUserRequestList()
      }
  },[Filter?.text])

  useEffect(() =>{
    if(props?.id != undefined ){
      hideUserRequestList();

    }

  },[props?.id])
 
  const hideUserRequestList = async () => {
    setLoading(true);

    if(Filter?.text){
      try {
        let url ;
        if(props?.id )
        {
            url = `/connection/listing?type=for_product&search=${Filter?.text}&product_id=${props?.id}`;
        }
        else{
          url = `/connection/listing?type=for_product&search=${Filter?.text}`;
        }
        const res = await get(
          url,
          getAuthConfig()
        );
        setLoading(false);
        if (res.status === 200) {
          setUsers((p)=> ({...p , items : res.data.data.connections}));

        }
      } catch (error) {
        setLoading(false);
      }
    }
    else{

    try {

      let url ;
      if(props?.id != undefined)
      {
          url = `/connection/listing?type=for_product&product_id=${props?.id}`;
      }
      else{
        url = `/connection/listing?type=for_product`;
      }
      const res = await get(
        url,
        getAuthConfig()
      );
      setLoading(false);
      if (res.status === 200) {
        setUsers((p)=> ({...p , items : res.data.data.connections}));

      }
    } catch (error) {
      setLoading(false);
    }
  }
    setLoading(false);
  };

  const handleSubmit = async (id,index,val ) => {

    let     arr = users.items ;
    arr[index].is_blocked  = arr[index].is_blocked === 1 ? 0 : 1;
   setUsers((p)=> ({...p , items : arr}));
   


    const
    filtered = RUser.filter((obj) => {
     return obj === id;
   });
   if (filtered.length >= 1) {
     setRUser((current) => current.filter((fruit) => fruit !== id));
   } else{  

    
    setRUser((arr) => [...arr, id]);
    
   }

  

    // const
    //  filtered = RUser.filter((obj) => {
    //   return obj === id;
    // });
    // if (filtered.length >= 1) {
    //   setRUser((current) => current.filter((fruit) => fruit !== id));
    // } else{

    //     if(val !=  1)
    //     {
    //       // setRUser((arr) => [...arr, id]);

    //     }
    // }
  };

  UseEffectOnce(() => {
    // hideUserRequestList();
  }, []);

  

  





  return (
    <>
      <div
        className="modal fade price-hide-modal"
        id="PostHideModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="PostHideModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Post Hide From
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props?.postHideArray(users?.items);
                }}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="price-hide-form">
                <form>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Search through Profile ID or Name" 
                      value={Filter?.text}
                      onChange={(e) =>{
                        setFilter((p) =>({...p,text:e.target.value}))
                      }}
                    />
                    <button className="search-button" onClick={(e) =>{
                      e.preventDefault();
                    }}>
                      <SearchIcon />
                    </button>
                  </div>
                </form>
                <div className="user-list">
              
                  
                      { users?.items?.length > 0 && users?.items?.map((user,index) => {
                        return (
                          <div className="user-item" key={user?.id}>
                            <input
                              type="checkbox"
                              name="user_list"
                              checked={user?.is_blocked === 1 ? true : ""}
                              id={user.id}
                              onChange={() => {
                                handleSubmit(user.id , index , user?.is_blocked);
                              }}
                              // value={checked}
                            />
                            <label htmlFor={user.id}>
                              <div className="profile-image">
                                <img
                                  src={user.profile_image}
                                  alt="profileImg"
                                />
                              </div>
                              <div className="profile-content">
                                <h6>{user.name}</h6>
                                <p>(Profile ID: {user.id})</p>
                              </div>

                              {
                                user?.is_blocked === 1 ? 
                                <span  >
                                  <CheckMarkFill />
                                </span> : 
                              
                               <span >
                                <CheckMark  />
                              </span>
                              }
                               
                           
                            
                            </label>
                          </div>
                        );
                      })}
               
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button button-primary"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props?.postHideArray(users?.items);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostHideFrom;
