import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import { ReactComponent as BusinessIcon } from "../assets/images/business-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete-icon.svg";
import { post, get } from "../libs/http-hydrate";
import FieldValidationError from "../components/error-messages/field-validation-error";
import Auth from "../libs/auth";

function BusinessMapModal(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const user = Auth.getCurrentUser();
  const [initialProps, setInitialProps] = useState([{}]);
  const [availableBusinessMap, setAvailableBusinessMap] = useState([]);
  const [logo, setLogo] = useState("");
  const [isEmpty, setIsEmpty] = useState({});
  const CloseRef = useRef(null);
  const bottomRef = useRef(null);
  const [errors, setErrors] = useState({});

  const inputStyle = {
    border: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
  };

  useEffect(() => {
    setInitialProps(props.data);
    if (props.data?.length === 0) {
      setInitialProps([{ id: 1, description: "", year: 2000 }]);
    }
    get("/general/custom_media_list?type=business_map", {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((response) => {
        setAvailableBusinessMap(response.data.data);
      })
      .catch((err) => {});
  }, [props.data]);
  // Update the emptyValidation function to handle all fields
  const validateFields = (index) => {
    const newErrors = { ...errors };
    const item = initialProps[index];

    newErrors[index] = {
      year: !item.year,
      mapType: !item.id,
      description: !item.description,
    };

    setErrors(newErrors);
  };

  const handleSaveBusinessMap = () => {
    setIsFormSubmitted(true); // Set form as submitted

    if (hasErrors()) {
      // Prevent API call if there are errors
      return;
    }
    initialProps.forEach((businessMap) => {
      delete businessMap["id"];
      businessMap["id"] = businessMap["media_id"];
      delete businessMap["media_id"];
    });

    post(
      "/user/profile/business_map",
      { details: initialProps },
      { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {
        if (response.status === 200) {
          props?.getDetails();

          CloseRef.current.click();
        }
      })
      .catch((error) => {});
  };

  const makeYearJson = () => {
    let yearJson = [];

    for (let i = 1900; i < 2023; i++) {
      let yearObject = { year: i, id: i };
      yearJson.push(yearObject);
    }

    return yearJson;
  };

  const yearJson = makeYearJson();

  const handleFormChange = (index, event) => {
    let data = [...initialProps];
    data[index][event.target.name] = event.target.value;

    availableBusinessMap.forEach((element) => {
      if (data[index].id == element.id) {
        data[index].media = element;
        data[index].media_id = element.id;
      }
    });

    setIsEmpty(false);

    setInitialProps(data);
    validateFields(index);
  };

  const hasErrors = () => {
    return Object.values(errors).some(
      (error) => error.year || error.mapType || error.description
    );
  };

  const addFields = () => {
    let newfield = { id: 1, description: "", year: 2000 };
    setInitialProps([...initialProps, newfield]);
    setErrors({
      ...errors,
      [initialProps.length]: { year: true, mapType: true, description: true },
    });
  };

  const removeFields = (index) => {
    let data = [...initialProps];
    data.splice(index, 1);
    setInitialProps(data);
  };

  const emptyValidation = (index) => {
    const newIsEmpty = { ...isEmpty };
    newIsEmpty[index] = initialProps[index].description === "";
    setIsEmpty(newIsEmpty);
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [initialProps]);

  return (
    <>
      <div
        className="modal fade certifications-modal business-map-modal"
        id="BusinessMapModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="BusinessMapModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Business Map
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={CloseRef}
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="business-map">
                {initialProps?.length >= 1 ? (
                  <>
                    {initialProps?.map((input, index) => (
                      <div className="map-item" key={index}>
                        <div className="map-item-inner">
                          <div className="icon">
                            <img
                              style={{ width: "60px", height: "60px" }}
                              src={input?.media?.file}
                            />
                          </div>
                          <div className="content">
                            <div className="flex-box">
                              <div className="flex-item year">
                                <div className="form-field">
                                  <label className="form-label">
                                    Year <span className="mendatory">*</span>
                                  </label>
                                  <select
                                    name="year"
                                    value={input?.year}
                                    onChange={(event) =>
                                      handleFormChange(index, event)
                                    }
                                    className="form-input"
                                    style={{ ...inputStyle }}
                                  >
                                    <option disabled selected>
                                      Year
                                    </option>
                                    {yearJson.map((year) => (
                                      <option value={year.id}>
                                        {year.year}
                                      </option>
                                    ))}
                                  </select>
                                  {isFormSubmitted && errors[index]?.year && (
                                    <FieldValidationError message="Year is required" />
                                  )}
                                </div>
                              </div>
                              <div className="flex-item type">
                                <div className="form-field">
                                  <label className="form-label">
                                    Map Type{" "}
                                    <span className="mendatory">*</span>
                                  </label>
                                  <select
                                    name="id"
                                    onChange={(event) => {
                                      handleFormChange(index, event);
                                    }}
                                    className="form-input"
                                    style={{ ...inputStyle }}
                                  >
                                    <option
                                      selected
                                      value={input?.media_id}
                                      disabled
                                    >
                                      {input?.media?.name}
                                    </option>
                                    {availableBusinessMap.map((businessMap) => (
                                      <option value={businessMap.id}>
                                        {businessMap.name}
                                      </option>
                                    ))}
                                  </select>
                                  {isFormSubmitted &&
                                    errors[index]?.mapType && (
                                      <FieldValidationError message="Map Type is required" />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="form-field">
                              <label className="form-label">
                                Description <span className="mendatory">*</span>
                              </label>
                              <input
                                type="text"
                                name="description"
                                id="cname"
                                className="form-input"
                                value={input?.description}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                                onBlur={() => emptyValidation(index)}
                                placeholder="Enter Description"
                                style={{ ...inputStyle }}
                              />
                              {isFormSubmitted &&
                                errors[index]?.description && (
                                  <FieldValidationError message="Description is required" />
                                )}
                            </div>
                          </div>
                        </div>
                        {initialProps?.length === 1 ? (
                          <></>
                        ) : (
                          <>
                            <button
                              className="button-delete"
                              onClick={() => removeFields(index)}
                            >
                              <DeleteIcon />
                            </button>
                          </>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )}
                <div ref={bottomRef} />
              </div>
            </div>
            <div
              style={{
                borderTop: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
              }}
              className="modal-footer"
            >
              <button
                type="button"
                disabled={hasErrors()}
                className="button button-primary"
                onClick={() => handleSaveBusinessMap()}
              >
                Save
              </button>
              <button
                className="button button-primary button-success"
                onClick={() => addFields()}
              >
                Add More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessMapModal;
