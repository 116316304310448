import React, { useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FilterIcon } from "../../assets/images/filter-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow-down.svg";
import TicketsList from "./TicketsList";

const SupportList = ({
  data,
  handleClick,
  ticketIds,
  handleTabClick,
  tab,
  sort,
  handleSortby,
  search,
  handleSearch,
  handlePageChangeforchatList,
}) => {
  const isActive = data?.items?.tickets?.find(
    (ticket) => ticket?.id === ticketIds
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && data?.has_more) {
          handlePageChangeforchatList();
        }
      });
      if (node) observer.current.observe(node);
    },
    [data?.has_more]
  );

  return (
    <>
      <div className="card sidebar-nav support-sidebar">
        <div className="sidebar-title">
          <div className="ticket-head">
            <div className="ticket-head--top">
              <div className="tabs tabs--solid">
                <ul>
                  <li className={tab === "on_going" ? "active" : ""}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabClick("on_going");
                      }}
                    >
                      Ongoing
                    </a>
                  </li>
                  <li className={tab === "closed" ? "active" : ""}>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        handleTabClick("closed");
                      }}
                    >
                      Closed Ticket
                    </a>
                  </li>
                </ul>
              </div>
              <button
                className="filter-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (sort === "asc") {
                    handleSortby("desc");
                  } else {
                    handleSortby("asc");
                  }
                }}
              >
                {sort === "asc" ? (
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 0L20 5H17V17H15V5H12L16 0ZM11 15V17H0V15H11ZM11 8V10H0V8H11ZM9 1V3H0V1H9Z"
                      fill="black"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 0L20  0ZM11 15V17H0V15H11ZM11 8V10H0V8H11ZM9 1V3H0V1H9Z"
                      fill="black"
                    />

                    <path
                      d="M10 16L14 11H11V0H9V11H6L10 16Z"
                      fill="black"
                      transform="translate(5, 0)"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <a
            className="collapse-button mobile-view collapsed"
            data-toggle="collapse"
            href="#collapseSidebar"
            role="button"
            aria-expanded="false"
            aria-controls="collapseSearch"
          >
            <ArrowDownIcon />
          </a>
        </div>
        <div
          className="tickets-list collapse desktop-view"
          id="collapseSidebar"
        >
          <div className="search-box">
            <input
              type="text"
              className="form-input"
              placeholder="Search"
              onChange={(e) => {
                e.preventDefault();
                handleSearch(e.target.value);
              }}
            />
            <button className="search-button">
              <SearchIcon />
            </button>
          </div>
          <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
            {data?.items?.map((ticket) => (
              <div ref={lastBookElementRef}>
                <TicketsList
                  isActive={ticket?.id === ticketIds}
                  ticketIds={ticketIds}
                  key={ticket?.id}
                  ticket={ticket}
                  handleClick={handleClick}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportList;
