import { Modal } from "react-bootstrap";
import Auth from "../libs/auth";
import { ReactComponent as HistoryIcon } from "../assets/images/history-icon.svg";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as Close2Icon } from '../assets/images/close2-icon.svg'
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { useEffect } from "react";

function RecievedCard({
  showModal,
  handleCloseModal,
  selectedSocialPost,
  removeCarditem,
}) {
  const user = Auth.getCurrentUser();

  function momentT(ed) {
    if (Math.abs(moment(ed).diff()) < 5000) {
      return "just now";
    }
    return moment(ed).fromNow();
  }

  const [socialcardListing, setsocialcardListing] = useState({
    items: [],
    has_more: false,
  });

  const [socialPostCardPage, setsocialPostCardPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedSocialPost !== 0 && showModal === true) {
      socialPostCardListing();
    }
  }, [selectedSocialPost, socialPostCardPage, showModal]);

  async function socialPostCardListing() {
    try {
      if (loading) return;

      setLoading(true);

      const data = await get(
        `/social_post/${selectedSocialPost}/card?page=${socialPostCardPage}`,
        getAuthConfig()
      );

      if (data.status === 200) {
        if (socialPostCardPage > 1) {
          setsocialcardListing((prev) => ({
            items: [...prev.items, ...data?.data?.data?.items],
            has_more: data?.data?.data?.has_more,
          }));
        } else {
          setsocialcardListing((prev) => ({
            items: [...data?.data?.data?.items],
            has_more: data?.data?.data?.has_more,
          }));
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const loadMore = () => {
    if (socialcardListing.has_more) {
      setsocialPostCardPage((prevPage) => prevPage + 1);
    }
  };

  async function deleteSocialCard(card_id) {
    try {
      const formdata = new FormData();
      formdata.append("card_id", card_id);
      const data = await post(
        `/social_post/${selectedSocialPost}/card/delete`,
        formdata,
        getAuthConfig()
      );
      if (data.status === 200) {
        setsocialcardListing((prev) => ({
          ...prev,
          items: prev.items.filter((item) => item.id !== card_id), // Remove the deleted card from the list
        }));
        removeCarditem();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }

  return (
    <>
      <Modal size="lg" className="card-modal" show={showModal} onHide={handleCloseModal}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
          Received card
          
          </Modal.Title>
          <button type="button" className="close" onClick={handleCloseModal}>
            <span aria-hidden="true" ><Close2Icon /></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div class="theme theme--default">
            {socialcardListing?.items &&
              socialcardListing?.items?.map((item, index) => {
                return (
                  <>
                    <div className="posts-activity">
                      <div className="posts-comments">
                        <div className="comment-item">
                          <div className="user-profile">
                            <Link
                              to={
                                user?.slug === item?.user?.slug
                                  ? `/edit-profile-seller/${user.slug}`
                                  : `/edit-profile-seller/${item?.user?.slug}`
                              }
                            >
                              <div className="user-profile-image">
                                <img
                                  alt=""
                                  src={item?.user?.profile_image}
                                  className="profile-pic"
                                />
                              </div>
                            </Link>
                            <div className="comment-content">
                              <div className="comment-meta">
                                <div className="user-profile-content">
                                  <Link
                                    to={
                                      user?.slug === item?.user?.slug
                                        ? `/edit-profile-seller/${user.slug}`
                                        : `/edit-profile-seller/${item?.user?.slug}`
                                    }
                                  >
                                    <div className="user-profile-name">
                                      <h6>
                                        {item?.user?.name}
                                        <span>
                                          (Profile ID: {item?.user?.id})
                                        </span>
                                        <span>
                                          (
                                          {item?.user?.is_buyer === 0
                                            ? "Seller"
                                            : "Buyer"}
                                          )
                                        </span>
                                      </h6>
                                      <p>
                                        {item?.user?.i_am} at{" "}
                                        {item?.user?.company_details?.name}
                                      </p>
                                    </div>
                                  </Link>
                                </div>

                                <div className="posts-action">
                                  <div className="posts-time">
                                    <p>
                                      <HistoryIcon />
                                      {momentT(item?.created_at)}
                                    </p>
                                  </div>
                                  {user?.id === item?.user?.id ? (
                                    <div className="more-btn">
                                      <div className="nav-item dropdown account-dropdown">
                                        <Link
                                          to={""}
                                          className="nav-link dropdown-toggle"
                                          href="#"
                                          id="navbarDropdown"
                                          role="button"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <MoreIcon />
                                        </Link>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="navbarDropdown"
                                        >
                                          <Link
                                            to={"/"}
                                            className="dropdown-item"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              deleteSocialCard(item?.id);
                                            }}
                                          >
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="comment-text">
                                <img
                                  src={item?.card}
                                  height={100}
                                  width={100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

            {socialcardListing?.has_more && (
              <>
                <div className="text-center m-5">
                  <span onClick={loadMore} disabled={loading}>
                    {loading ? "Loading..." : "Load More"}
                  </span>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default RecievedCard;
