import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/request.scss";
import profilePic from "../assets/images/profile.png";
import PostImage from "../assets/images/post-image.jpg";
import PostImage2 from "../assets/images/social1.jpg";
import PostImage3 from "../assets/images/social2.jpg";
import PostImage4 from "../assets/images/social3.jpg";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import { ReactComponent as CaretDownIcon } from "../assets/images/caret-down.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import { ReactComponent as HistoryIcon } from "../assets/images/history-icon.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon.svg";
import { ReactComponent as LikeIcon } from "../assets/images/like-icon.svg";
import { ReactComponent as LikeIconFilled } from "../assets/images/like-icon-filled.svg";
import { ReactComponent as CommentIcon } from "../assets/images/comment-icon.svg";
import { ReactComponent as CommentIconFilled } from "../assets/images/comment-icon-filled.svg";
import { ReactComponent as SendQuoteIcon } from "../assets/images/send-quote-icon.svg";
import { ReactComponent as MeetingRoomIcon } from "../assets/images/meeting-room-icon.svg";
import { ReactComponent as MeetingRoomIconFilled } from "../assets/images/meeting-room-icon-filled.svg";
import { ReactComponent as PostShareIcon } from "../assets/images/post-share-icon.svg";
import { ReactComponent as PostShareIconFilled } from "../assets/images/post-share-icon-filled.svg";
import { ReactComponent as AcceptDealIcon } from "../assets/images/accept-deal-icon.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/negotiation-icon.svg";
import { ReactComponent as SocialReactIcon } from "../assets/images/social-react.svg";
import { ReactComponent as CardIcon } from "../assets/images/card-icon.svg";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import Header from "../common/header";
import { ReactComponent as CopyIcon } from "../assets/images/copy-link-new.svg";
import { ReactComponent as CopyIconFilled } from "../assets/images/copy-link-new.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import moment from "moment";
import { getAuthConfig, postwithOu, get } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { ReactComponent as ReactLikeIcon } from "../assets/images/react-like.svg";
import { ReactComponent as CelebrateIcon } from "../assets/images/celebrate.svg";
import { ReactComponent as CareIcon } from "../assets/images/care.svg";
import { ReactComponent as LoveIcon } from "../assets/images/love.svg";
import { ReactComponent as InsightfulIcon } from "../assets/images/insightful.svg";
import { ReactComponent as CuriousIcon } from "../assets/images/curious.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SwitchProfile } from "../context/switchProfile.context";
import ReportModal from "./report-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import AcceptDeal from "./acceptDeal";
const PostCardSeller = ({ productdata, loading }) => {
  var settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2.25,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { accountFor } = useContext(SwitchProfile);
  const [Reply, setReply] = useState({
    isReply: false,
    parent_id: "",
    comment: "",
    ReplyData: [],
    CurrentReply: "",
  });
  const [ImageModal, setImageModal] = useState(false);
  const [ImageModalId, setImageModalId] = useState();
  const handleCloseModalImage = () => setImageModal(false);
  const user = Auth.getCurrentUser();
  const location = useLocation();
  const [ProductCommentTag, setProductCommentTag] = useState({
    has_more: false,
    items: [],
  });
  const [PostSellComTag, setPostSellComTag] = useState({
    has_more: false,
    items: [],
  });
  const [PostBuyComTag, setPostBuyComTag] = useState({
    has_more: false,
    items: [],
  });
  const [SocialComTag, setSocialComTag] = useState({
    has_more: false,
    items: [],
  });
  const descRef = useRef(null);
  const [FilterMarket, setFilterMarket] = useState({
    allMain: false,
    seller: false,
    buyer: false,
    allChild: false,
    buisness: false,
    social: false,
  });
  const [socialdata, setsocialdata] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [headerData, setHeaderData] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showModalCred, setShowModalCred] = useState(false);
  const handleCloseModalCred = () => setShowModalCred(false);
  const handleShowModalCred = () => setShowModalCred(true);
  const [CredPolicyData, setCredPolicyData] = useState({});

  const [showModalCard, setshowModalCard] = useState(false);
  const handleclosecard = () => setshowModalCard(false);
  const handleshowcard = () => setshowModalCard(true);

  const [shareLink, setShareLink] = useState(false);
  const [HomeDatas, setHomeDatas] = useState({});
  const [goButton, setGoButton] = useState(false);
  const [CommentV, setComment] = useState({
    comment: "",
    comment_Id: "",
    child_Comment: "",
    parent_id: "",
  });
  const [sent, setSent] = useState({
    items: [],
  });
  const [timeline, setTimeline] = useState({
    items: [],
    has_more: false,
  });
  const [roleData, setRoleData] = useState();
  const [Update, setSupdate] = useState(false);
  const buyerRole = (roleData) => {
    setRoleData(roleData);
  };
  const gstRef = useRef();

  const [available_usersPage, setavailable_usersPage] = useState(0);
  const [IndPage, setIndPage] = useState(0);

  const [suggested_usersPage, setsuggested_usersPage] = useState(0);
  const [TimeLine_usersPage, setTimeLine_usersPage] = useState(0);
  const [categoryPage, setcategoryPage] = useState(0);
  const [subCatPage, setsubCatPage] = useState(0);
  const [FiltAvailable, setFiltAvailable] = useState(0);
  const [LoadingT, setLoadingT] = useState(false);
  const [LoadingTAvailable, setLoadingTAvailable] = useState(false);
  const navigate = useNavigate();
  const MarketUpdates = useRef(null);

  const [stateList, setStateList] = useState({
    items: [],
    has_more: false,
  });
  const [cityList, setCityList] = useState([]);
  const [productArray, setProductArray] = useState({
    items: [],
    has_more: false,
  });
  const [productSubCategoryArray, setProductSubCategoryArray] = useState({
    items: [],
    has_more: false,
  });
  const [availableIndustry, setAvailableIndustry] = useState({
    items: [],
    has_more: false,
  });
  const [CurrentComment, setCurrentComment] = useState({
    post_id: "",
    post_type: "",
    commentArray: [],
    has_more: false,
    page: 0,
    parent_id: "",
    child_Comment_List: [],
    child_Comment_Has_more: false,
  });

  const [replyUser, setReplyUser] = useState("");

  const [FilterAvail, setFilterAvail] = useState({
    search: "",
    state_Id: "",
    city_Id: "",
    industry_Id: "",
    category_id: "",
    subcategory_id: "",
    Filter: "",
    count: "",
  });
  const settingsPost = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [cardSelectedUser, setcardselecteduser] = useState("");
  const [data, setData] = useState({
    dataa: "",
  });
  const [available, setAvailable] = useState({
    items: [],
    has_more: false,
  });
  const [socialCard, setSocialCard] = useState({
    items: [],
    has_more: false,
  });
  const stateRef = useRef(null);
  const IndustryRef = useRef(null);
  const categoryRef = useRef(null);
  const selectInputRef = useRef(null);
  const selectCat = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (location.state === "scrollToDiv") {
      // fetchMoreData();
      MarketUpdates.current.scrollIntoView({ behavior: "smooth" });
      location.state = undefined;
    } else {
    }
  });
  UseEffectOnce(() => {
    HomeData();
  }, []);
  useEffect(() => {
    if (Update === true) {
      HomeData();
    }
    setSupdate(false);
  }, [Update]);

  useEffect(() => {
    if (CurrentComment?.page === 0) {
    } else {
      CommentListing(CurrentComment?.post_id, CurrentComment?.post_type);
    }
  }, [CurrentComment?.page]);
  useEffect(() => {
    setTimeline((p) => ({ ...p, items: [productdata] }));
  }, [productdata]);
  async function HomeData() {
    try {
      const data = await get("/home", getAuthConfig());
      if (data.status === 200) {
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }
  function UpdateTrue() {
    setSupdate(true);
    descRef?.current?.blur();
  }

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item.user.id);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  async function sentConnectionRequest(User_id) {
    const formdata = new FormData();
    formdata.append("user_id", User_id);
    const data = await postwithOu("/connection/send", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          setSent((p) => ({
            ...p,
            items: sent.items.concat(User_id),
          }));
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          swal("Warning", "Request Already sent to this user ", "warning");
        } else {
          toast.error(err?.response?.data?.message);
        }
      });
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && timeline?.has_more) {
          setTimeLine_usersPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [timeline?.has_more]
  );

  async function reaction(Post_Id, type, comment_Id, unlike, index, reaction) {
    const formdata = new FormData();

    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }

    if (comment_Id) {
      formdata.append("comment_id", comment_Id);
    }
    formdata.append("type", type);
    if (unlike) {
      formdata.append("reaction", "");
    } else {
      formdata.append("reaction", reaction);
    }

    await postwithOu("/general/reaction", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          // timeline?.items[index]?.interactions?.reaction = "like";

          let arr = [];
          arr = timeline?.items;

          if (arr[index].interactions.reaction != null) {
            if (unlike) {
              arr[index].interactions.reaction = null;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions - 1;
            } else {
              arr[index].interactions.reaction = reaction;
              // arr[index].interactions.reactions =
              //   arr[index].interactions.reactions + 1;
            }
          } else {
            if (unlike) {
              arr[index].interactions.reaction = null;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions - 1;
            } else {
              arr[index].interactions.reaction = reaction;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions + 1;
            }
          }

          setTimeline((p) => ({ ...p, items: arr }));
          // fetchMoreData();
          // func(index)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function reactionChild(Post_Id, type, comment_Id, unlike, ind, child) {
    const formdata = new FormData();

    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }

    if (comment_Id) {
      formdata.append("comment_id", comment_Id);
    }
    formdata.append("type", "comment");
    if (unlike) {
      formdata.append("reaction", "");
    } else {
      formdata.append("reaction", "like");
    }

    const data = await postwithOu(
      "/general/reaction",
      getAuthConfig(),
      formdata
    )
      .then((datta) => {
        if (datta.status === 200) {
          // fetchMoreData();

          if (child) {
            let arr = [];
            arr = CurrentComment?.child_Comment_List;
            if (unlike) {
              arr[ind].interactions.is_like = 0;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions - 1;
            } else {
              arr[ind].interactions.is_like = 1;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions + 1;
            }
            setCurrentComment((p) => ({ ...p, child_Comment_List: arr }));
            // ChildCommentListing(CurrentComment?.parent_id,Post_Id,type)
          } else {
            let arr = [];
            arr = CurrentComment?.commentArray;
            if (unlike) {
              arr[ind].interactions.is_like = 0;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions - 1;
            } else {
              arr[ind].interactions.is_like = 1;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions + 1;
            }
            setCurrentComment((p) => ({ ...p, commentArray: arr }));
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function Comment(Post_Id, type, parent_id, index) {
    const formdata = new FormData();
    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }
    if (parent_id) {
      formdata.append("parent_id", parent_id);
      formdata.append("comment", CommentV?.child_Comment);
    } else {
      formdata.append("comment", CommentV?.comment);
    }

    formdata.append("type", type);
    // formdata.append("comment", CommentV?.comment);

    await postwithOu("/general/comment", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          // fetchMoreData();
          CommentListing(Post_Id, type);
          setComment((p) => ({ ...p, comment: "" }));
          let arr = [];
          arr = timeline?.items;

          if (arr[index].interactions.comments != null) {
            arr[index].interactions.comments =
              arr[index].interactions.comments + 1;
          }
          if (parent_id) {
            ChildCommentListing(CurrentComment?.parent_id, Post_Id, type);
            setComment((p) => ({ ...p, child_Comment: "" }));
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function CommentListing(id, type) {
    // alert(CurrentComment?.post_id)
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?page=${
        CurrentComment?.page + 1
      }&social_post_id=${id}&type=${type}`;
    } else {
      url = `/general/comment/listing?page=${
        CurrentComment?.page + 1
      }&product_id=${id}&type=product`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        if (CurrentComment?.page === 0) {
          setCurrentComment((p) => ({
            ...p,
            commentArray: response.data.data.items,
          }));
        } else {
          setCurrentComment((p) => ({
            ...p,
            commentArray: CurrentComment.commentArray.concat(
              response.data.data.items
            ),
          }));
        }

        setCurrentComment((p) => ({
          ...p,
          has_more: response.data.data.has_more,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function ChildCommentListing(parent_id, product_id, type) {
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?social_post_id=${product_id}&parent_id=${parent_id}&type=${type}`;
    } else {
      url = `/general/comment/listing?product_id=${product_id}&parent_id=${parent_id}&type=product`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        setCurrentComment((p) => ({
          ...p,
          child_Comment_List: response.data.data.items,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function DeleteComment(Comment_Id, product_Id, type, is_child, index) {
    try {
      let url;
      url = `/general/comment/delete/${Comment_Id}}`;
      await get(url, getAuthConfig()).then((response) => {
        if (is_child) {
          ChildCommentListing(CurrentComment?.parent_id, product_Id, type);
        } else {
          CommentListing(product_Id, type);
          let arr = [];
          arr = timeline?.items;
          arr[index].interactions.comments =
            arr[index].interactions.comments - 1;
          setTimeline((p) => ({ ...p, items: arr }));
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }

  const handleDeletePost = async (id, index) => {
    await get(`/product/delete/${id}`, getAuthConfig())
      .then((res) => {
        let arr = [];
        arr = timeline?.items;
        arr.splice(index, 1);
        setTimeline((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeleteSocial = async (id, index) => {
    await get(`/social_post/destroy/${id}`, getAuthConfig())
      .then((res) => {
        let arr = [];
        arr = timeline?.items;
        arr.splice(index, 1);
        setTimeline((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const CommentTag = async (type) => {
    let url;

    if (type === "product") {
      url = `/general/content?search&type=product_comment`;
    } else if (type === "post_to_sell") {
      url = `/general/content?search&type=post_sell_comment`;
    } else if (type === "post_to_buy") {
      url = `/general/content?search&type=post_buy_comment`;
    } else if (type === "social_post") {
      url = `/general/content?search&type=social_post_comment`;
    }
    await get(url, getAuthConfig())
      .then((res) => {
        if (type === "product") {
          setProductCommentTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "post_to_sell") {
          setPostSellComTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "post_to_buy") {
          setPostBuyComTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "social_post") {
          setSocialComTag((p) => ({ ...p, items: res.data.data }));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  function momentT(ed) {
    if (Math.abs(moment(ed).diff()) < 5000) {
      return "just now";
    }
    return moment(ed).fromNow();
  }

  async function ReplyToMyPost(ProductId, productType, parent_id) {
    const formdata = new FormData();
    if (productType === "social_post") {
      formdata.append("social_post_id", ProductId);
    } else if (productType === "product") {
      formdata.append("product_id", ProductId);
    }
    formdata.append("parent_id", parent_id);
    formdata.append("type", productType);
    formdata.append("comment", Reply?.comment);

    await postwithOu("/general/comment", getAuthConfig(), formdata)
      .then((datta) => {
        ReplyListing(productType, ProductId, parent_id);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function ReplyListing(type, product_id, parent_id) {
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?social_post_id=${product_id}&type=${type}&parent_id=${parent_id}`;
    } else {
      url = `/general/comment/listing?product_id=${product_id}&type=product&parent_id=${parent_id}`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        setReply((p) => ({ ...p, ReplyData: response?.data?.data?.items }));
        setReplyUser(response?.data?.data?.items.map((item) => item.user));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  const headerDataCall = (data) => {
    setHeaderData(data);
  };

  async function getSocialCard() {
    try {
      const data = await get("/social_post/cards?page=1", getAuthConfig());

      if (data.status === 200) {
        setSocialCard((p) => ({
          ...p,
          items: data?.data?.data?.list,
        }));
        setSocialCard((p) => ({
          ...p,
          has_more: data?.data?.data?.has_more,
        }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  UseEffectOnce(() => {
    getSocialCard();
  }, []);
  return (
    <>
      <div className="card post-buyer">
        {loading
          ? "Please wait while loading"
          : timeline?.items &&
            timeline?.items.map((item, index) => {
              return (
                <>
                  <div
                    className="card post-seller"
                    ref={lastBookElementRef}
                    key={item}
                  >
                    <div className="card-body">
                      <div className="user-profile">
                        <Link to={`/edit-profile-seller/${item?.user?.slug}`}>
                          <div className="user-profile-image">
                            <img
                              alt="profile"
                              src={item?.user?.profile_image}
                              className="profile-pic"
                            />
                          </div>
                        </Link>
                        <div className="user-profile-content">
                          <Link to={`/edit-profile-seller/${item?.user?.slug}`}>
                            <div className="user-profile-name">
                              <h6>
                                {item?.user?.name}
                                <span>(Profile ID: {item?.user?.id})</span>
                              </h6>
                              <p>
                                {item?.user?.company_details?.designation !=
                                null
                                  ? "Partner" +
                                    item?.user?.company_details?.designation +
                                    "at"
                                  : " "}
                                {item?.user?.company_details?.name}
                              </p>
                              <span className="post-tag">
                                {item?.user_type === "seller"
                                  ? "Seller"
                                  : "Buyer"}
                              </span>

                              {
                                item?.user?.id === user?.id ||
                                item?.user?.has_connection === 1 ? (
                                  ""
                                ) : sent?.items.filter(
                                    (word) => word === item?.user?.id
                                  ).length > 0 ? (
                                  <button
                                    className="btn btn-success button-connect"
                                    style={{
                                      maxWidth: "fit-content",
                                    }}
                                  >
                                    Request Sent
                                  </button>
                                ) : (
                                  <button
                                    className="button button-primary button-connect"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        user?.verified_at === null &&
                                        user?.is_seller === 1 &&
                                        user?.is_seller === 1
                                      ) {
                                        // swal("error","Please verify your profile","error");
                                        // sentConnectionRequest(item?.id);
                                        handleShowModal();
                                      } else {
                                        sentConnectionRequest(item?.user?.id);
                                      }
                                    }}
                                  >
                                    + Connect
                                  </button>
                                )

                                // <button
                                //   className="button button-primary button-connect"
                                //   onClick={(e) => {
                                //     e.preventDefault();
                                //     if (
                                //       user?.verified_at === null &&
                                //       user?.is_seller === 1 &&
                                //       user?.is_seller === 1
                                //     ) {
                                //       handleShowModal();
                                //     } else {
                                //       sentConnectionRequest(
                                //         item?.user?.id
                                //       );
                                //     }
                                //   }}
                                // >
                                //   + Connect
                                // </button>
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="posts-action">
                          <div className="posts-time">
                            <p>
                              <HistoryIcon />
                              {""}
                              {momentT(item?.created_at)}
                            </p>
                            <p>
                              <EditIcon />
                              {""}
                              {momentT(item?.updated_at)}
                            </p>
                          </div>
                          {user?.id === item?.user?.id ? (
                            <div className="more-btn">
                              <div className="nav-item dropdown account-dropdown">
                                <Link
                                  to={""}
                                  className="nav-link dropdown-toggle"
                                  href="#"
                                  id="navbarDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <MoreIcon />
                                </Link>
                                <div className="dropdown-menu">
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      if (item?.type === "post_to_sell") {
                                        navigate("/edit-post", {
                                          state: item,
                                        });
                                      } else {
                                        navigate("/edit-product", {
                                          state: item,
                                        });
                                      }
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      // e.preventDefault();

                                      swal({
                                        title: "Are you sure?",
                                        text: "Are you sure to delete this product!",
                                        icon: "warning",
                                        dangerMode: true,
                                        buttons: ["cancel", "ok"],
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          handleDeletePost(item?.id, index);
                                        } else {
                                        }
                                      });
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="more-btn">
                              <div className="nav-item dropdown account-dropdown">
                                <Link
                                  to={""}
                                  className="nav-link dropdown-toggle"
                                  href="#"
                                  id="navbarDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <MoreIcon />
                                </Link>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="navbarDropdown"
                                >
                                  <Link
                                    className="dropdown-item"
                                    to={"/"}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      sessionStorage.setItem(
                                        "reportid",
                                        item?.id
                                      );
                                      sessionStorage.setItem("post", "Post");
                                      sessionStorage.setItem(
                                        "type",
                                        item?.type
                                      );

                                      // ReportComment(item?.id,com?.id,item?.type )
                                    }}
                                    data-toggle="modal"
                                    data-target="#ReportModal"
                                  >
                                    Report
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="posts-summery">
                        {item?.post_template?.content}
                      </p>
                      <div className="posts-product-details flex-box">
                        <div className="flex-item posts-image-wrap">
                          {item.media.length > 1 ? (
                            <div className="posts-image">
                              <Slider {...settingsPost} arrows={true}>
                                {item.media.map((imagess) => (
                                  <img
                                    src={imagess.file}
                                    alt="productImage"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setImageModalId(index);
                                      setImageModal(true);
                                    }}
                                  />
                                ))}
                              </Slider>
                            </div>
                          ) : (
                            <div className="posts-image">
                              {/* <a href=""> */}
                              <img
                                src={item?.media[0]?.file}
                                alt="productImage"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setImageModalId(index);
                                  setImageModal(true);
                                }}
                              />
                              {/* </a> */}
                            </div>
                          )}
                        </div>
                        <div className="flex-item posts-info-wrap">
                          <div className="tags">
                            <div className="tags--title">Product Details</div>
                            <span className="tag">{item?.name}</span>
                            {/* <span className="tag">{item?.name}</span> */}
                            <span className="tag">
                              Size: {item?.size ? item?.size : "N/A"}
                            </span>

                            <span className="tag">
                              Colour:{" "}
                              {item?.color?.name ? item?.color?.name : "N/A"}
                            </span>
                            <span className="tag">
                              Material:{" "}
                              {item?.material_type
                                ? item?.material_type
                                : "N/A"}
                            </span>
                            <span className="tag">
                              Brand:{" "}
                              {item?.brand?.name ? item?.brand?.name : "N/A"}
                            </span>
                            <span className="tag">
                              Item Code:{" "}
                              {item?.product_code ? item?.product_code : "N/A"}
                            </span>
                            <span className="tag">
                              Shape: {item?.shape ? item?.shape : "N/A"}
                            </span>
                            <span className="tag">
                              Storage:{" "}
                              {item?.storage_capacity
                                ? item?.storage_capacity
                                : "N/A"}
                            </span>
                          </div>
                          <div className="tags">
                            <div className="tags--title">Pricing and Tax</div>
                            <span className="tag">
                              Advance Payment Price:{" "}
                              {item?.amount ? item?.amount : "N/A"}
                            </span>
                            <span className="tag">
                              Credit Payment Price:{" "}
                              {item?.credit_amount
                                ? item?.credit_amount
                                : "N/A"}
                            </span>
                            {item?.credit_policy && (
                              <span
                                className="attachment"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCredPolicyData(item?.credit_policy);
                                  handleShowModalCred();
                                }}
                              >
                                <AttachmentIcon />
                              </span>
                            )}

                            <span className="tag">
                              HSN Code: {item?.hsn_code}
                            </span>
                            <span className="tag">GST: {item?.gst_rate}%</span>
                          </div>
                          <div className="tags">
                            <div className="tags--title">Other Details</div>

                            {item?.order_qty && (
                              <span className="tag">
                                MOQ: {item?.order_qty}{" "}
                                {item?.unit?.id != 0 ? (
                                  <>
                                    {/* <span className="tag"> */}
                                    {item?.unit?.name}
                                    {/* </span> */}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                            {item?.quality_standard && (
                              <span className="tag">
                                Quality Standard: {item?.quality_standard}
                              </span>
                            )}
                            <span className="tag">
                              Delivery Within State:{" "}
                              {item?.delivery_details?.delivery_within_state}
                            </span>
                            <span className="tag">
                              Delivery Outside State:{" "}
                              {item?.delivery_details?.delivery_outside_state}
                            </span>
                            <span className="tag">
                              <strong>Other Description:</strong>
                              {
                                item?.description != null &&
                                  item?.description != undefined && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                      }}
                                    ></p>
                                  )
                                // <DescriptionWithLinks description={item?.description} />
                              }
                            </span>
                          </div>
                          {item?.catalogue && (
                            <ul>
                              <li>
                                <a
                                  href={item?.catalogue}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <AttachmentIcon />
                                  Product Catalogue
                                </a>
                              </li>
                            </ul>
                          )}
                          {item.freight_charges && (
                            <ul>
                              <li>Freight Charges {item?.freight_charges}%</li>
                            </ul>
                          )}
                        </div>
                        <div className="posts-data">
                          <div className="posts-connections">
                            {item?.interactions?.reactions > 0 ||
                            item?.interactions?.reaction > 0 ? (
                              <span>
                                {item?.interactions?.reaction === "like" ? (
                                  <LikeIconFilled />
                                ) : (
                                  <>
                                    {item.interactions.reaction ===
                                    "celebrate" ? (
                                      <CelebrateIcon />
                                    ) : item?.interactions?.reaction ===
                                      "love" ? (
                                      <LoveIcon />
                                    ) : item?.interactions?.reaction ===
                                      "insightful" ? (
                                      <InsightfulIcon />
                                    ) : item?.interactions?.reaction ===
                                      "support" ? (
                                      <CareIcon />
                                    ) : item?.interactions?.reaction ===
                                      "curious" ? (
                                      <CuriousIcon />
                                    ) : (
                                      <LikeIconFilled />
                                    )}
                                  </>
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                            <span>
                              {item?.interactions?.reaction === null &&
                                item.interactions.reactions === 1 &&
                                `${item.interactions.reactions}`}
                              {item?.interactions?.reaction !== null &&
                                item.interactions.reactions === 1 &&
                                `${item.interactions.reactions}`}
                              {item?.interactions?.reaction === null &&
                                item.interactions.reactions > 1 &&
                                `${item.interactions.reactions} Likes`}
                              {item?.interactions?.reaction !== null &&
                                item.interactions.reactions === 2 &&
                                `You and ${
                                  item.interactions.reactions - 1
                                } other`}
                              {item?.interactions?.reaction !== null &&
                                item.interactions.reactions > 2 &&
                                `You and ${
                                  item.interactions.reactions - 1
                                } others`}
                            </span>
                          </div>
                          <div className="comments-deals">
                            <span>
                              {Number(item?.interactions?.comments) > 0
                                ? `${item.interactions.comments} Comments`
                                : ""}
                            </span>

                            {/* <span>7 Cards Received</span> */}
                          </div>
                        </div>
                      </div>

                      <div className="posts-activity">
                        <div className="posts-activity-actions">
                          <span className="posts-activity-actions--button reaction">
                            <span
                              className={
                                item?.interactions?.reaction === "like" ||
                                item?.interactions?.reaction === "support" ||
                                item?.interactions?.reaction === "love" ||
                                item?.interactions?.reaction === "celebrate" ||
                                item?.interactions?.reaction === "insightful" ||
                                item?.interactions?.reaction === "curious"
                                  ? "icon-reacted"
                                  : ""
                              }
                              onClick={(e) => {
                                if (
                                  user?.verified_at === null &&
                                  user?.is_seller === 1 &&
                                  user?.is_seller === 1
                                ) {
                                  handleShowModal();
                                } else {
                                  if (
                                    item?.interactions?.reaction === "like" ||
                                    item?.interactions?.reaction ===
                                      "celebrate" ||
                                    item?.interactions?.reaction === "love" ||
                                    item?.interactions?.reaction ===
                                      "insightful" ||
                                    item?.interactions?.reaction ===
                                      "support" ||
                                    item?.interactions?.reaction === "curious"
                                  ) {
                                    reaction(
                                      item?.id,
                                      "product",
                                      "",
                                      "unlike",
                                      index
                                    );
                                  } else {
                                    reaction(
                                      item?.id,
                                      "product",
                                      "",
                                      "",
                                      index,
                                      "like"
                                    );
                                  }
                                }
                              }}
                            >
                              {item?.interactions?.reaction === "like" ? (
                                <LikeIconFilled />
                              ) : (
                                <>
                                  {item.interactions.reaction ===
                                  "celebrate" ? (
                                    <CelebrateIcon />
                                  ) : item?.interactions?.reaction ===
                                    "love" ? (
                                    <LoveIcon />
                                  ) : item?.interactions?.reaction ===
                                    "insightful" ? (
                                    <InsightfulIcon />
                                  ) : item?.interactions?.reaction ===
                                    "support" ? (
                                    <CareIcon />
                                  ) : item?.interactions?.reaction ===
                                    "curious" ? (
                                    <CuriousIcon />
                                  ) : (
                                    <LikeIconFilled />
                                  )}
                                </>
                              )}
                            </span>
                            <span className="posts-activity-actions--text">
                              {item?.interactions?.reaction === "like"
                                ? "Like"
                                : item?.interactions?.reaction === "celebrate"
                                ? "Celebrate"
                                : item?.interactions?.reaction === "support"
                                ? "Support"
                                : item?.interactions?.reaction === "insightful"
                                ? "Insightful"
                                : item?.interactions?.reaction === "love"
                                ? "Love"
                                : item?.interactions?.reaction === "curious"
                                ? "Curious"
                                : "Like"}
                            </span>
                            <div className="reaction-hover">
                              <span>
                                <ReactLikeIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1 &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction === "like"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "like"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                              <span>
                                <CelebrateIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1 &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction ===
                                        "celebrate"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "celebrate"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                              <span>
                                <CareIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1 &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction ===
                                        "support"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "support"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                              <span>
                                <LoveIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction === "love"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "love"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                              <span>
                                <InsightfulIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1 &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction ===
                                        "insightful"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "insightful"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                              <span>
                                <CuriousIcon
                                  onClick={(e) => {
                                    if (
                                      user?.verified_at === null &&
                                      user?.is_seller === 1 &&
                                      user?.is_seller === 1
                                    ) {
                                      handleShowModal();
                                    } else {
                                      if (
                                        item?.interactions?.reaction ===
                                        "curious"
                                      ) {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "unlike",
                                          index
                                        );
                                      } else {
                                        reaction(
                                          item?.id,
                                          "product",
                                          "",
                                          "",
                                          index,
                                          "curious"
                                        );
                                      }
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </span>
                          <span
                            className="posts-activity-actions--button comment"
                            onClick={(e) => {
                              if (
                                user?.verified_at === null &&
                                user?.is_seller === 1 &&
                                user?.is_seller === 1
                              ) {
                                handleShowModal();
                              } else {
                                // if (
                                //   e.target.style.fill === ""
                                // ) {
                                //   e.target.style.fill =
                                //     "#0a95ff";
                                //   reaction(item?.id, "product");
                                // } else {
                                //   e.target.style.fill = "";
                                // }
                                if (item?.type === "product") {
                                  if (ProductCommentTag?.items.length <= 0) {
                                    CommentTag(item?.type);
                                  }
                                }
                                if (item?.type === "post_to_sell") {
                                  if (PostSellComTag?.items.length <= 0) {
                                    CommentTag(item?.type);
                                  }
                                }
                                if (item?.interactions?.comments === 0) {
                                  // alert("test ")
                                  setCurrentComment((p) => ({
                                    ...p,
                                    post_id: item?.id,
                                  }));
                                  setCurrentComment((p) => ({
                                    ...p,
                                    post_type: item?.type,
                                  }));
                                  setCurrentComment((p) => ({
                                    ...p,
                                    commentArray: [],
                                  }));
                                } else {
                                  setCurrentComment((p) => ({
                                    ...p,
                                    post_id: item?.id,
                                  }));
                                  setCurrentComment((p) => ({
                                    ...p,
                                    post_type: item?.type,
                                  }));
                                  if (item?.id != CurrentComment?.post_id) {
                                    CommentListing(item?.id, item?.type);
                                  }
                                }
                              }
                            }}
                          >
                            <span>
                              <CommentIconFilled />
                            </span>
                            <span className="posts-activity-actions--text">
                              Comment
                            </span>
                          </span>
                          {item?.user?.id !== user?.id && (
                            <span className="posts-activity-actions--button send-quote">
                              <Link
                                to=""
                                onClick={() => setProductDetail(item)}
                                style={{ color: "inherit" }}
                                data-toggle="modal"
                                data-target="#accpetDeal"
                              >
                                <span className="icon">
                                  <AcceptDealIcon />
                                </span>
                                <span className="icon-filled">
                                  <AcceptDealIcon />
                                </span>
                                <span className="posts-activity-actions--text">
                                  Accept Deal
                                </span>
                              </Link>
                            </span>
                          )}
                          {item?.negotiation != null &&
                          item?.negotiation != 0 &&
                          item?.user?.id !== user?.id ? (
                            <span className="posts-activity-actions--button meeting-room">
                              <Link
                                to=""
                                onClick={() => setProductDetail(item)}
                                style={{ color: "inherit" }}
                                data-toggle="modal"
                                data-target="#NegotiationModal"
                                className=""
                              >
                                <span className="icon">
                                  <NegotiationIcon />
                                </span>
                                <span className="icon-filled">
                                  <NegotiationIcon />
                                </span>
                                <span className="posts-activity-actions--text">
                                  Negotiation
                                </span>
                              </Link>
                            </span>
                          ) : (
                            ""
                          )}
                          {item.user.id !== user.id && (
                            <span className="posts-activity-actions--button meeting-room">
                              <Link to={""} onClick={() => createThread(item)}>
                                <span className="icon">
                                  <MeetingRoomIcon />
                                </span>
                                <span className="icon-filled">
                                  <MeetingRoomIconFilled />
                                </span>
                                <span className="posts-activity-actions--text">
                                  Meeting Room
                                </span>
                              </Link>
                            </span>
                          )}
                          <CopyToClipboard
                            text={`https://busimeet.com/post-detail/${item?.id}/${item?.type}`}
                            onCopy={() => {
                              setShareLink(true);
                              toast.success("copied to clipboard!");
                            }}
                          >
                            <span className="posts-activity-actions--button share">
                              <span className="icon">
                                <CopyIcon />
                              </span>
                              <span className="icon-filled">
                                <CopyIconFilled />
                              </span>
                              <span className="posts-activity-actions--text">
                                Copy Link
                              </span>
                            </span>
                          </CopyToClipboard>
                        </div>

                        {CurrentComment?.post_id === item?.id ? (
                          <>
                            <div className="add-comments">
                              <form>
                                <div className="add-comments--form">
                                  <div className="profile-image">
                                    <Link
                                      to={`/edit-profile-seller/${user.slug}`}
                                    >
                                      <img
                                        src={user?.profile_image}
                                        className="profile-pic"
                                      />
                                    </Link>
                                  </div>
                                  <div className="comment-form">
                                    <div className="form-field">
                                      <input
                                        type="text"
                                        name="cname"
                                        id="cname"
                                        className="form-input"
                                        placeholder="Add a Comment"
                                        value={
                                          CommentV?.comment_Id === item?.id
                                            ? CommentV?.comment
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setComment((p) => ({
                                            ...p,
                                            comment: e.target.value,
                                          }));
                                          setComment((p) => ({
                                            ...p,
                                            comment_Id: item?.id,
                                          }));
                                        }}
                                      />
                                      <button
                                        className="button button-primary"
                                        onClick={(e) => {
                                          e.preventDefault();

                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            if (CommentV?.comment === "") {
                                            } else {
                                              Comment(
                                                item?.id,
                                                "product",
                                                null,
                                                index
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        Post
                                      </button>
                                    </div>
                                    <div className="tags">
                                      {item?.type === "product" &&
                                        ProductCommentTag?.items.map(
                                          (tag, tagI) => {
                                            return (
                                              <>
                                                <span
                                                  className="tag"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment: tag?.title,
                                                    }));
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment_Id: item?.id,
                                                    }));
                                                  }}
                                                >
                                                  {tag?.title}
                                                </span>
                                              </>
                                            );
                                          }
                                        )}
                                      {item?.type === "post_to_sell" &&
                                        PostSellComTag?.items.map(
                                          (tag, tagI) => {
                                            return (
                                              <>
                                                <span
                                                  className="tag"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment: tag?.title,
                                                    }));
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment_Id: item?.id,
                                                    }));
                                                  }}
                                                >
                                                  {tag?.title}
                                                </span>
                                              </>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            {CurrentComment?.commentArray.length > 0 &&
                              CurrentComment?.commentArray.map((com, ind) => {
                                return (
                                  <>
                                    <div className="posts-comments">
                                      <div className="comment-item">
                                        <div className="user-profile">
                                          <Link
                                            to={
                                              user?.slug === com?.user?.slug
                                                ? `/edit-profile-seller/${user.slug}`
                                                : `/edit-profile-seller/${com?.user?.slug}`
                                            }
                                          >
                                            <div className="user-profile-image">
                                              <img
                                                alt=""
                                                src={com?.user?.profile_image}
                                                className="profile-pic"
                                              />
                                            </div>
                                          </Link>
                                          <div className="comment-content">
                                            <div className="comment-meta">
                                              <div className="user-profile-content">
                                                <Link
                                                  to={
                                                    user?.slug ===
                                                    com?.user?.slug
                                                      ? `/edit-profile-seller/${user.slug}`
                                                      : `/edit-profile-seller/${com?.user?.slug}`
                                                  }
                                                >
                                                  <div className="user-profile-name">
                                                    <h6>
                                                      {com?.user?.name}
                                                      <span>
                                                        (Profile ID:{" "}
                                                        {com?.user?.id})
                                                      </span>
                                                      <span>
                                                        (
                                                        {com?.user?.is_buyer ===
                                                        0
                                                          ? "Seller"
                                                          : "Buyer"}
                                                        )
                                                      </span>
                                                    </h6>
                                                    <p>
                                                      {com?.user?.i_am} at{" "}
                                                      {
                                                        com?.user
                                                          ?.company_details
                                                          ?.name
                                                      }
                                                    </p>
                                                  </div>
                                                </Link>
                                              </div>

                                              <div className="posts-action">
                                                <div className="posts-time">
                                                  <p>
                                                    <HistoryIcon />
                                                    {momentT(com?.created_at)}
                                                  </p>
                                                </div>
                                                {user?.id === com?.user?.id ? (
                                                  <div className="more-btn">
                                                    <div className="nav-item dropdown account-dropdown">
                                                      <Link
                                                        to={""}
                                                        className="nav-link dropdown-toggle"
                                                        href="#"
                                                        id="navbarDropdown"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                      >
                                                        <MoreIcon />
                                                      </Link>
                                                      <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="navbarDropdown"
                                                      >
                                                        <Link
                                                          to={"/"}
                                                          className="dropdown-item"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            DeleteComment(
                                                              com?.id,
                                                              item?.id,
                                                              item?.type,
                                                              "",
                                                              index
                                                            );
                                                          }}
                                                        >
                                                          Delete
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="more-btn">
                                                    <div className="nav-item dropdown account-dropdown">
                                                      <Link
                                                        to={""}
                                                        className="nav-link dropdown-toggle"
                                                        href="#"
                                                        id="navbarDropdown"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                      >
                                                        <MoreIcon />
                                                      </Link>
                                                      <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="navbarDropdown"
                                                      >
                                                        <Link
                                                          className="dropdown-item"
                                                          to={"/"}
                                                          onClick={(e) => {
                                                            e.preventDefault();

                                                            sessionStorage.setItem(
                                                              "reportid",
                                                              item?.id
                                                            );
                                                            sessionStorage.setItem(
                                                              "comid",
                                                              com?.id
                                                            );
                                                            sessionStorage.setItem(
                                                              "type",
                                                              item?.type
                                                            );

                                                            // ReportComment(item?.id,com?.id,item?.type )
                                                          }}
                                                          data-toggle="modal"
                                                          data-target="#ReportModal"
                                                        >
                                                          Report
                                                        </Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div className="comment-text">
                                              <p>{com?.comment}</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="comment-actions">
                                          <span
                                            className="comment-actions--button reaction"
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  com?.interactions?.is_like > 0
                                                ) {
                                                  reactionChild(
                                                    item?.id,
                                                    "product",
                                                    com?.id,
                                                    "unlike",
                                                    ind
                                                  );
                                                } else {
                                                  reactionChild(
                                                    item?.id,
                                                    "product",
                                                    com?.id,
                                                    "",
                                                    ind
                                                  );
                                                }
                                              }
                                            }}
                                          >
                                            <span
                                              className={
                                                com?.interactions?.is_like > 0
                                                  ? "icon-filld"
                                                  : ""
                                              }
                                            >
                                              <LikeIconFilled />
                                            </span>
                                            <span className="comment-actions--text">
                                              Like
                                            </span>
                                          </span>
                                          <span className="pr-3">
                                            {com?.interactions?.reactions > 1
                                              ? com?.interactions?.reactions +
                                                " Likes"
                                              : com?.interactions?.reactions ===
                                                1
                                              ? com?.interactions?.reactions +
                                                " Like"
                                              : ""}
                                          </span>
                                          <span
                                            className="comment-actions--button comment"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                com?.id !=
                                                CurrentComment?.parent_id
                                              ) {
                                                setCurrentComment((p) => ({
                                                  ...p,
                                                  child_Comment_List: [],
                                                }));
                                              }

                                              setCurrentComment((p) => ({
                                                ...p,
                                                parent_id: com?.id,
                                              }));
                                              setComment((p) => ({
                                                ...p,
                                                parent_id: com?.id,
                                              }));
                                              if (
                                                com?.interactions
                                                  ?.child_comments > 0
                                              ) {
                                                if (
                                                  com?.id !=
                                                  CurrentComment?.parent_id
                                                ) {
                                                  ChildCommentListing(
                                                    com?.id,
                                                    item?.id,
                                                    item?.type
                                                  );
                                                }
                                              }
                                            }}
                                          >
                                            <span className="">
                                              <CommentIconFilled />
                                            </span>
                                            <span className="comment-actions--text">
                                              Reply
                                            </span>
                                          </span>
                                          <span className="pr-3">
                                            {com?.interactions?.child_comments >
                                            1
                                              ? com?.interactions
                                                  ?.child_comments + " Comments"
                                              : com?.interactions
                                                  ?.child_comments === 1
                                              ? com?.interactions
                                                  ?.child_comments + " Comment"
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    {com?.id === CurrentComment?.parent_id &&
                                      CurrentComment?.child_Comment_List
                                        .length > 0 &&
                                      CurrentComment?.child_Comment_List.map(
                                        (it, ig) => {
                                          return (
                                            <>
                                              <div className="posts-comments ml-5 ">
                                                <div className="comment-item">
                                                  <div className="user-profile">
                                                    <Link
                                                      to={
                                                        user?.slug ===
                                                        it?.user?.slug
                                                          ? `/edit-profile-seller/${user.slug}`
                                                          : `/edit-profile-seller/${it?.user?.slug}`
                                                      }
                                                    >
                                                      <div className="user-profile-image">
                                                        <img
                                                          alt=""
                                                          src={
                                                            it?.user
                                                              ?.profile_image
                                                          }
                                                          className="profile-pic"
                                                        />
                                                      </div>
                                                    </Link>
                                                    <div className="comment-content">
                                                      <div className="comment-meta">
                                                        <div className="user-profile-content">
                                                          <Link
                                                            to={
                                                              user?.slug ===
                                                              it?.user?.slug
                                                                ? `/edit-profile-seller/${user.slug}`
                                                                : `/edit-profile-seller/${it?.user?.slug}`
                                                            }
                                                          >
                                                            <div className="user-profile-name">
                                                              <h6>
                                                                {it?.user?.name}
                                                                <span>
                                                                  (Profile ID:{" "}
                                                                  {it?.user?.id}
                                                                  )
                                                                </span>

                                                                <span>
                                                                  (
                                                                  {it?.user
                                                                    ?.is_buyer ===
                                                                  0
                                                                    ? "Seller"
                                                                    : "Buyer"}
                                                                  )
                                                                </span>
                                                              </h6>
                                                              <p>
                                                                {it?.user?.i_am}{" "}
                                                                at{" "}
                                                                {
                                                                  it?.user
                                                                    ?.company_details
                                                                    ?.name
                                                                }
                                                              </p>
                                                            </div>
                                                          </Link>
                                                        </div>
                                                        <div className="posts-action">
                                                          <div className="posts-time">
                                                            <p>
                                                              <HistoryIcon />
                                                              {momentT(
                                                                it?.created_at
                                                              )}
                                                            </p>
                                                          </div>
                                                          {user?.id ===
                                                          it?.user?.id ? (
                                                            <div className="more-btn">
                                                              <div className="nav-item dropdown account-dropdown">
                                                                <Link
                                                                  to={""}
                                                                  className="nav-link dropdown-toggle"
                                                                  href="#"
                                                                  id="navbarDropdown"
                                                                  role="button"
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                >
                                                                  <MoreIcon />
                                                                </Link>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="navbarDropdown"
                                                                >
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={"/"}
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      DeleteComment(
                                                                        it?.id,
                                                                        item?.id,
                                                                        item?.type,
                                                                        true,
                                                                        index
                                                                      );
                                                                    }}
                                                                  >
                                                                    Delete
                                                                  </Link>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div className="more-btn">
                                                              <div className="nav-item dropdown account-dropdown">
                                                                <Link
                                                                  to={""}
                                                                  className="nav-link dropdown-toggle"
                                                                  href="#"
                                                                  id="navbarDropdown"
                                                                  role="button"
                                                                  data-toggle="dropdown"
                                                                  aria-haspopup="true"
                                                                  aria-expanded="false"
                                                                >
                                                                  <MoreIcon />
                                                                </Link>
                                                                <div
                                                                  className="dropdown-menu"
                                                                  aria-labelledby="navbarDropdown"
                                                                >
                                                                  <Link
                                                                    className="dropdown-item"
                                                                    to={"/"}
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();

                                                                      sessionStorage.setItem(
                                                                        "reportid",
                                                                        item?.id
                                                                      );
                                                                      sessionStorage.setItem(
                                                                        "comid",
                                                                        it?.id
                                                                      );
                                                                      sessionStorage.setItem(
                                                                        "type",
                                                                        item?.type
                                                                      );
                                                                      sessionStorage.setItem(
                                                                        "parent_id",
                                                                        CurrentComment?.parent_id
                                                                      );

                                                                      // ReportComment(item?.id,com?.id,item?.type )
                                                                    }}
                                                                    data-toggle="modal"
                                                                    data-target="#ReportModal"
                                                                  >
                                                                    Report
                                                                  </Link>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className="comment-text">
                                                        <p>{it?.comment}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="comment-actions">
                                                    <span
                                                      className="comment-actions--button reaction"
                                                      onClick={(e) => {
                                                        if (
                                                          user?.verified_at ===
                                                            null &&
                                                          user?.is_seller === 1
                                                        ) {
                                                          handleShowModal();
                                                        } else {
                                                          if (
                                                            it?.interactions
                                                              ?.is_like > 0
                                                          ) {
                                                            reactionChild(
                                                              item?.id,
                                                              "product",
                                                              it?.id,
                                                              "unlike",
                                                              ig,
                                                              "child"
                                                            );
                                                          } else {
                                                            reactionChild(
                                                              item?.id,
                                                              "product",
                                                              it?.id,
                                                              "",
                                                              ig,
                                                              "child"
                                                            );
                                                          }
                                                        }
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          it?.interactions
                                                            ?.is_like > 0
                                                            ? "icon-filld"
                                                            : ""
                                                        }
                                                      >
                                                        <LikeIconFilled />
                                                      </span>
                                                      <span className="comment-actions--text">
                                                        Like
                                                      </span>
                                                    </span>

                                                    <span className="pr-3">
                                                      {it?.interactions
                                                        ?.reactions > 0
                                                        ? it?.interactions
                                                            ?.reactions +
                                                          " Like"
                                                        : ""}
                                                    </span>

                                                    {user?.id ===
                                                    item?.user?.id ? (
                                                      <>
                                                        <span
                                                          className="comment-actions--button reaction"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            setReply((p) => ({
                                                              ...p,
                                                              CurrentReply:
                                                                it?.id,
                                                            }));
                                                            ReplyListing(
                                                              item?.type,
                                                              item?.id,
                                                              it?.id
                                                            );
                                                          }}
                                                        >
                                                          <span
                                                            className={
                                                              "icon-filld"
                                                            }
                                                          >
                                                            <CommentIconFilled />
                                                          </span>
                                                          <span className="comment-actions--text">
                                                            {user?.name !==
                                                            replyUser[0]?.name
                                                              ? "View Reply"
                                                              : "Reply"}
                                                          </span>
                                                        </span>
                                                      </>
                                                    ) : it?.interactions
                                                        ?.child_comments > 0 ? (
                                                      <span
                                                        className="comment-actions--button reaction"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          setReply((p) => ({
                                                            ...p,
                                                            CurrentReply:
                                                              it?.id,
                                                          }));
                                                          ReplyListing(
                                                            item?.type,
                                                            item?.id,
                                                            it?.id
                                                          );
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            "icon-filld"
                                                          }
                                                        >
                                                          <CommentIconFilled />
                                                        </span>
                                                        <span className="comment-actions--text">
                                                          {user?.name !==
                                                          replyUser[0]?.name
                                                            ? "View Reply"
                                                            : "Reply"}
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {it?.interactions
                                                      ?.child_comments > 0 ? (
                                                      <span>
                                                        {it?.interactions
                                                          ?.child_comments === 1
                                                          ? it?.interactions
                                                              ?.child_comments +
                                                            " Reply "
                                                          : it?.interactions
                                                              ?.child_comments +
                                                            " Replies"}
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}

                                                    {it?.id ===
                                                      Reply?.CurrentReply &&
                                                      Reply?.ReplyData.length >
                                                        0 &&
                                                      Reply?.ReplyData.map(
                                                        (reply, reIndex) => {
                                                          return (
                                                            <>
                                                              <div className="posts-comments ml-5 ">
                                                                <div className="comment-item">
                                                                  <div className="user-profile">
                                                                    <Link
                                                                      to={`/edit-profile-seller/${reply?.user.slug}`}
                                                                    >
                                                                      <div className="user-profile-image">
                                                                        <img
                                                                          alt=""
                                                                          src={
                                                                            reply
                                                                              ?.user
                                                                              ?.profile_image
                                                                          }
                                                                          className="profile-pic"
                                                                        />
                                                                      </div>
                                                                    </Link>
                                                                    <div className="comment-content">
                                                                      <div className="comment-meta">
                                                                        <div className="user-profile-content">
                                                                          <Link
                                                                            to={`/edit-profile-seller/${reply?.user?.slug}`}
                                                                          >
                                                                            <div className="user-profile-name">
                                                                              <h6>
                                                                                {
                                                                                  reply
                                                                                    ?.user
                                                                                    ?.name
                                                                                }
                                                                                <span>
                                                                                  (Profile
                                                                                  ID:{" "}
                                                                                  {
                                                                                    reply
                                                                                      ?.user
                                                                                      ?.id
                                                                                  }

                                                                                  )
                                                                                </span>

                                                                                <span>
                                                                                  (
                                                                                  {reply
                                                                                    ?.user
                                                                                    ?.is_buyer ===
                                                                                  0
                                                                                    ? "Seller"
                                                                                    : "Buyer"}

                                                                                  )
                                                                                </span>
                                                                              </h6>
                                                                            </div>
                                                                          </Link>
                                                                        </div>
                                                                      </div>
                                                                      <div className="comment-text">
                                                                        <p>
                                                                          {
                                                                            reply?.comment
                                                                          }
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}

                                                    {it?.id ===
                                                      Reply?.CurrentReply &&
                                                      item?.user?.id ===
                                                        user?.id && (
                                                        <>
                                                          <div className="add-comments">
                                                            <form>
                                                              <div className="add-comments--form">
                                                                <div className="profile-image">
                                                                  <Link
                                                                    to={`/edit-profile-seller/${user.slug}`}
                                                                  >
                                                                    <img
                                                                      src={
                                                                        user?.profile_image
                                                                      }
                                                                      className="profile-pic"
                                                                    />
                                                                  </Link>
                                                                </div>
                                                                <div className="comment-form">
                                                                  <div className="form-field">
                                                                    <input
                                                                      type="text"
                                                                      name="cname"
                                                                      id="cname"
                                                                      className="form-input"
                                                                      placeholder="Add a Reply"
                                                                      value={
                                                                        Reply?.comment
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setReply(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            comment:
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          })
                                                                        );
                                                                        setComment(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            parent_id:
                                                                              it?.id,
                                                                          })
                                                                        );
                                                                      }}
                                                                    />
                                                                    <button
                                                                      className="button button-primary"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();

                                                                        if (
                                                                          user?.verified_at ===
                                                                            null &&
                                                                          user?.is_seller ===
                                                                            1
                                                                        ) {
                                                                          handleShowModal();
                                                                        } else {
                                                                          if (
                                                                            Reply?.comment ===
                                                                            ""
                                                                          ) {
                                                                          } else {
                                                                            ReplyToMyPost(
                                                                              item?.id,
                                                                              "product",
                                                                              it?.id
                                                                            );
                                                                          }
                                                                        }
                                                                      }}
                                                                    >
                                                                      Post
                                                                    </button>
                                                                  </div>
                                                                  <div className="tags">
                                                                    {item?.type ===
                                                                      "product" &&
                                                                      ProductCommentTag?.items.map(
                                                                        (
                                                                          tag,
                                                                          tagI
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <span
                                                                                className="tag"
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();
                                                                                  setReply(
                                                                                    (
                                                                                      p
                                                                                    ) => ({
                                                                                      ...p,
                                                                                      comment:
                                                                                        tag?.title,
                                                                                    })
                                                                                  );
                                                                                  setReply(
                                                                                    (
                                                                                      p
                                                                                    ) => ({
                                                                                      ...p,
                                                                                      parent_id:
                                                                                        item?.id,
                                                                                    })
                                                                                  );
                                                                                }}
                                                                              >
                                                                                {
                                                                                  tag?.title
                                                                                }
                                                                              </span>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                    {item?.type ===
                                                                      "post_to_sell" &&
                                                                      PostSellComTag?.items.map(
                                                                        (
                                                                          tag,
                                                                          tagI
                                                                        ) => {
                                                                          return (
                                                                            <>
                                                                              <span
                                                                                className="tag"
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();
                                                                                  setReply(
                                                                                    (
                                                                                      p
                                                                                    ) => ({
                                                                                      ...p,
                                                                                      comment:
                                                                                        tag?.title,
                                                                                    })
                                                                                  );
                                                                                  setReply(
                                                                                    (
                                                                                      p
                                                                                    ) => ({
                                                                                      ...p,
                                                                                      parent_id:
                                                                                        item?.id,
                                                                                    })
                                                                                  );
                                                                                }}
                                                                              >
                                                                                {
                                                                                  tag?.title
                                                                                }
                                                                              </span>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </form>
                                                          </div>
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}

                                    {com?.id === CurrentComment?.parent_id ? (
                                      <>
                                        <div className="add-comments ml-5">
                                          <form>
                                            <div className="add-comments--form">
                                              <Link
                                                to={`/edit-profile-seller/${user.slug}`}
                                              >
                                                <div className="profile-image">
                                                  <img
                                                    alt=""
                                                    src={user?.profile_image}
                                                    className="profile-pic"
                                                  />
                                                </div>
                                              </Link>
                                              <div className="comment-form">
                                                <div className="form-field">
                                                  <input
                                                    type="text"
                                                    name="cname"
                                                    id="cname"
                                                    className="form-input"
                                                    placeholder="Add a Comment"
                                                    value={
                                                      CommentV?.parent_id ===
                                                      com?.id
                                                        ? CommentV?.child_Comment
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      setComment((p) => ({
                                                        ...p,
                                                        child_Comment:
                                                          e.target.value,
                                                      }));
                                                      setComment((p) => ({
                                                        ...p,
                                                        comment_Id: item?.id,
                                                      }));
                                                    }}
                                                  />
                                                  <button
                                                    className="button button-primary"
                                                    onClick={(e) => {
                                                      e.preventDefault();

                                                      if (
                                                        user?.verified_at ===
                                                          null &&
                                                        user?.is_seller === 1
                                                      ) {
                                                        handleShowModal();
                                                      } else {
                                                        if (
                                                          CommentV?.child_Comment ===
                                                          ""
                                                        ) {
                                                        } else {
                                                          Comment(
                                                            item?.id,
                                                            "product",
                                                            com?.id
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    Post
                                                  </button>
                                                </div>
                                                <div className="tags">
                                                  {item?.type === "product" &&
                                                    ProductCommentTag?.items.map(
                                                      (tag, tagI) => {
                                                        return (
                                                          <>
                                                            <span
                                                              className="tag"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    child_Comment:
                                                                      tag?.title,
                                                                  })
                                                                );
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment_Id:
                                                                      item?.id,
                                                                  })
                                                                );
                                                              }}
                                                            >
                                                              {tag?.title}
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  {item?.type ===
                                                    "post_to_sell" &&
                                                    PostSellComTag?.items.map(
                                                      (tag, tagI) => {
                                                        return (
                                                          <>
                                                            <span
                                                              className="tag"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    child_Comment:
                                                                      tag?.title,
                                                                  })
                                                                );
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment_Id:
                                                                      item?.id,
                                                                  })
                                                                );
                                                              }}
                                                            >
                                                              {tag?.title}
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            {CurrentComment?.has_more === true ? (
                              <div className="posts-comments-footer">
                                <span
                                  href=""
                                  className="seemore-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentComment((p) => ({
                                      ...p,
                                      page: CurrentComment.page + 1,
                                    }));
                                  }}
                                >
                                  Load more comments <CaretDownIcon />
                                </span>
                              </div>
                            ) : (
                              " "
                            )}{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
      </div>
      <Modal show={showModalCred} onHide={handleCloseModalCred}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
            <h3>Credit Policy Data</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="policy-info">
            <form>
              <div className="info-box row justify-content-center">
                <div className="col-lg-1"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Nos of Advance Transactions for Credit</label>

                  <span>{CredPolicyData?.advance_transaction}</span>
                </div>
                <div className="col-lg-3"></div>
                <div className="info-item  col-lg-4 col-md-6 col-sm-12 col-12 ">
                  <label>Credit Period (Days)</label>

                  <span className="info-days">
                    {CredPolicyData?.credit_period}
                  </span>
                </div>

                <div className="col-lg-1"></div>

                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Interest Rate</label>

                  <span className="info-per">
                    {CredPolicyData?.delay_interest}
                  </span>
                </div>
                <div className="col-lg-3"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Credit Period Starts from</label>

                  <span className="info-item">
                    {CredPolicyData?.credit_period_start}
                  </span>
                </div>
                <div className="col-lg-1"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Interest Period</label>

                  <span>{CredPolicyData?.interest_period}</span>
                </div>
                <div className="col-lg-3"></div>

                <div className="info-item  col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Other Terms</label>

                  <span>{CredPolicyData?.other_terms}</span>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalCred}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        show={ImageModal}
        onHide={handleCloseModalImage}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Modal.Body style={{ "text-align": "end" }}>
          <Button className="modal-close" onClick={handleCloseModalImage}>
            <CloseIcon />
          </Button>

          <div className="container">
            <Slider {...settingsPost} arrows={true}>
              {timeline?.items[ImageModalId]?.media.map((imagess) => (
                <img
                  src={imagess.file}
                  className=""
                  alt="productImage"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
              ))}
            </Slider>
          </div>
        </Modal.Body>
      </Modal>
      <AcceptDeal productDetail={productDetail} buyerRole={roleData} />

      <ReportModal />
    </>
  );
};

export default PostCardSeller;
