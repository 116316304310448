const orderStatus = {
    TEMPORARY: 'temporary',
    DRAFT: 'draft',
    OFFER_SENT: 'offer_sent',
    OFFER_ACCEPTED: 'offer_accepted',
    OFFER_REJECTED: 'offer_rejected',
    PURCHASE_ORDER: 'purchase_order',
    INVOICE: 'invoice',
    BANK_DETAILS: 'bank_details',
    PAYMENT: 'payment',
    E_WAY_BILL: 'e_way_bill',
    MATERIAL_DISPATCH: 'material_dispatch',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed'
  };

export default orderStatus
