import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/short-order.scss";
import Header from "../common/header";
import ProductImage from "../assets/images/product-image1.png";
import CompanyLogo from "../assets/images/company-logo.png";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as PendingIcon } from "../assets/images/pending.svg";
import { ReactComponent as LinkIcon } from "../assets/images/link-icon.svg";
import { ReactComponent as DownloadIcon } from "../assets/images/short-download.svg";
import ShortOngoingIcon from "../assets/images/short-ongoing-icon.svg";
import ShortCompletedIcon from "../assets/images/short-comleted.svg";
import { Link, useNavigate } from "react-router-dom";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

function ShortOrderSeller() {
  const [orderListing, setOrderListing] = useState({
    has_more: false,
    items: [],
  });
  const [sorting, setSorting] = useState("latest");
  const [filtering, setFiltering] = useState("all");
  const [typeFilter, setTypeFilter] = useState("");
  const [searching, setSearching] = useState("");
  const [productListing, setProductListing] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = (action) => {
    setFiltering(action);
  };

  const handleClickFilter = (action) => {
    setTypeFilter(action);
  };

  const shortOrderListing = async () => {
    setLoading(true);
    if (searching === "") {
      await get(
        `/short_order/listing?page=1&type=${filtering}&order_by=${sorting}&search=&from=${typeFilter}`,
        getAuthConfig()
      )
        .then((res) => {
          setLoading(false);
          setOrderListing((p) => ({ ...p, items: res?.data?.data?.data }));
          setProductListing(
            res?.data?.data?.data?.map((item) => item.products[0])
          );
          if (res?.data?.data?.has_more === true) {
            setOrderListing((p) => ({ ...p, has_more: true }));
          } else {
            setOrderListing((p) => ({ ...p, has_more: false }));
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setTimeout(async () => {
        if (searching.length > 2) {
          await get(
            `/short_order/listing?page=1&type=${filtering}&order_by=${sorting}&search=${searching}&from=${typeFilter}`,
            getAuthConfig()
          )
            .then((res) => {
              setLoading(false);
              setOrderListing((p) => ({ ...p, items: res?.data?.data?.data }));
              setProductListing(
                res?.data?.data?.data?.map((item) => item.products[0])
              );
              if (res?.data?.data?.has_more === true) {
                setOrderListing((p) => ({ ...p, has_more: true }));
              } else {
                setOrderListing((p) => ({ ...p, has_more: false }));
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      }, [1500]);
    }
  };

  const searchShortOrder = (e) => {
    setSearching(e.target.value);
  };

  useEffect(() => {
    shortOrderListing();
  }, [sorting, filtering, searching, typeFilter]);

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="short-order">
            <div className="short-order-head">
              <div className="page-title">
                <h6>Transaction</h6>
              </div>
              <div className="order-filter">
                <div className="tabs tabs--solid">
                  <ul>
                    <li
                      className={`${typeFilter === "" ? "active" : ""}`}
                      onClick={() => handleClickFilter("")}
                    >
                      <Link to={""}>All</Link>
                    </li>
                    <li
                      className={`${typeFilter === "post" ? "active" : ""}`}
                      onClick={() => handleClickFilter("post")}
                    >
                      <Link to={""}>From Post</Link>
                    </li>
                    <li
                      className={`${
                        typeFilter === "portfolio" ? "active" : ""
                      }`}
                      onClick={() => handleClickFilter("portfolio")}
                    >
                      <Link to={""}>From Portfolio</Link>
                    </li>
                  </ul>
                </div>
                <div className="search-box">
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Search"
                    value={searching}
                    onChange={searchShortOrder}
                    onBlur={(e) => {
                      e.preventDefault();
                      clearInterval();
                    }}
                  />
                  <button className="search-button">
                    <SearchIcon />
                  </button>
                </div>
              </div>
              <div className="short">
                <select
                  className="form-input"
                  value={sorting}
                  onChange={(e) => setSorting(e.target.value)}
                >
                  <option value="">Sort By</option>
                  <option value="latest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>
            </div>
            <div className="tabs tabs--transparent">
              <ul>
                <li
                  className={`${filtering === "all" ? "active" : ""}`}
                  onClick={() => handleClick("all")}
                >
                  <Link to={""}>All</Link>
                </li>
                <li
                  className={`${filtering === "on_going" ? "active" : ""}`}
                  onClick={() => handleClick("on_going")}
                >
                  <Link to={""}>Ongoing</Link>
                </li>
                <li
                  className={`${filtering === "completed" ? "active" : ""}`}
                  onClick={() => handleClick("completed")}
                >
                  <Link to={""}>Completed</Link>
                </li>
                <li
                  className={`${filtering === "in_complete" ? "active" : ""}`}
                  onClick={() => handleClick("in_complete")}
                >
                  <Link to={""}>Incomplete</Link>
                </li>
              </ul>
            </div>
            {!orderListing?.items?.length && !loading ? (
              <>No Data Found</>
            ) : (
              <>
                {loading ? (
                  <>
                    <Skeleton
                      height={200}
                      count={3}
                      style={{ marginTop: "20px", borderRadius: "12px" }}
                    />
                  </>
                ) : (
                  <>
                    {orderListing?.items?.map((item) => (
                      <div className="orders">
                        <div className="order-item">
                          <div className="image-block">
                            <img
                              src={item?.products[0]?.thumb_image?.file}
                              alt=""
                            />
                            -
                          </div>
                          <div className="content-block">
                            <div className="left">
                              <h6>
                                {item?.products[0]?.name}{" "}
                                {item?.products?.length > 1
                                  ? ` & view ${
                                      item?.products?.length - 1
                                    } more `
                                  : ""}
                              </h6>
                              <div className="company-name">
                                <img
                                  src={item?.to_user?.company?.logo}
                                  alt="companyLogo"
                                />
                                {item?.to_user?.company?.name}
                              </div>
                              <ul>
                                <li>
                                  <label>Description</label>:
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: productListing[0]?.description,
                                    }}
                                  ></span>
                                </li>
                                <li>
                                  <label>Payment Terms</label>:
                                  <span>{item?.payment_type}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="right">
                              {item.status === "completed" && (
                                <div className="time">
                                  <span>Completed date: </span>13th Jan 2022
                                </div>
                              )}
                              <div className="status">
                                {item.status === "draft" ? (
                                  <PendingIcon />
                                ) : item.status === "on_going" ? (
                                  <img
                                    src={ShortOngoingIcon}
                                    alt="OnGoing SO"
                                  />
                                ) : item.status === "completed" ? (
                                  <img
                                    src={ShortCompletedIcon}
                                    alt="completedIcon"
                                  />
                                ) : (
                                  ""
                                )}
                                <span className="status-label">
                                  {item.status === "draft"
                                    ? "Pending Request"
                                    : item.status === "on_going"
                                    ? "Ongoing SO"
                                    : item.status === "completed"
                                    ? "Complete"
                                    : ""}
                                </span>
                              </div>
                              <a className="view-btn">
                                <LinkIcon />
                                View Post
                              </a>
                              {item.status === "completed" && (
                                <button className="button button-primary">
                                  <DownloadIcon />
                                  Download Invoice
                                </button>
                              )}
                              {item.status === "on_going" && (
                                <div className="step-label">
                                  <span className="counter">{item?.step}</span>
                                  Step - {item?.step}
                                  (Purchase Order)
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="order-item-footer">
                            <ul>
                              <li>
                                Start Date:{" "}
                                {moment(item?.created_at)?.format(
                                  "Do MMMM YYYY"
                                )}
                              </li>
                              <li>QTY: {item?.total_qty}</li>
                              <li>My price: ₹{item?.initial?.grand_total} </li>
                              <li>
                                Price Received Seller: ₹
                                {item?.initial?.grand_total}
                              </li>
                              {(item.status === "offer_rejected" ||
                                item.status === "final_offer_received") && (
                                <li>{`Revised Offer From Me: ${
                                  item?.final_offer?.grand_total === null
                                    ? "N/A"
                                    : `₹${item?.final_offer?.grand_total}`
                                }`}</li>
                              )}
                              {(item.status === "offer_rejected" ||
                                item.status === "final_offer_received") && (
                                <li>Final Offer From Seller: N/ A</li>
                              )}
                            </ul>
                          </div>
                        </div>
                        {/* <div className="order-item">
                          <div className="image-block">
                            <img src={ProductImage} />
                          </div>
                          <div className="content-block">
                            <div className="left">
                              <h6>Black+Decker 650W Rebating Planer, KW712</h6>
                              <div className="company-name">
                                <img src={CompanyLogo} />
                                ABC Group
                              </div>
                              <ul>
                                <li>
                                  <label>Order ID</label>:
                                  <span>#34323423423523</span>
                                </li>
                              </ul>
                            </div>
                            <div className="right">
                              <div className="status">
                                <img src={ShortOngoingIcon} alt="OnGoing SO" />
                                <span className="status-label">Ongoing So</span>
                              </div>
                              <a className="view-btn">
                                <LinkIcon />
                                View Post
                              </a>
                              <div className="step-label">
                                <span className="counter">3</span>Step - 3
                                (Purchase Order)
                              </div>
                            </div>
                          </div>
                          <div className="order-item-footer">
                            <ul>
                              <li>SO release date: 10th Jan 2022</li>
                              <li>SO ID: #12323434</li>
                              <li>PO ID: 12234433</li>
                              <li>Invoice Number: NA </li>
                              <li>final Amout : ₹70032</li>
                            </ul>
                          </div>
                        </div>
                        <div className="order-item">
                          <div className="image-block">
                            <img src={ProductImage} />
                          </div>
                          <div className="content-block">
                            <div className="left">
                              <h6>Black+Decker 650W Rebating Planer, KW712</h6>
                              <div className="company-name">
                                <img src={CompanyLogo} />
                                ABC Group
                              </div>
                              <ul>
                                <li>
                                  <label>Order ID</label>:
                                  <span>#34323423423523</span>
                                </li>
                              </ul>
                            </div>
                            <div className="right">
                              <div className="time">
                                <span>Completed date: </span>13th Jan 2022
                              </div>
                              <div className="status">
                                <img src={ShortCompletedIcon} />
                                <span className="status-label">Complete</span>
                              </div>
                              <a className="view-btn">
                                <LinkIcon />
                                View Post
                              </a>
                              <button className="button button-primary">
                                <DownloadIcon />
                                Download Invoice
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShortOrderSeller;
