import React from "react";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import Header from "../common/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { useState } from "react";
import RatingCard from "../components/ShortOrder/Step10/RatingCard";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";

const ProductRating = () => {
  let currentStep = 10;

  const [shortOrderDetails, setShortOrderDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSO, setLoadingSO] = useState(false);

  const payment = localStorage.getItem("paymentType");

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", shortOrderDetails?.payment_type);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoadingSO(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoadingSO(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          // setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoadingSO(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  UseEffectOnce(() => {
    getProductCartSO();
  }, []);

  const [buyerRatings, setBuyerRatings] = useState({
    price: 0,
    quality: 0,
    service: 0,
    support: 0,
  });

  const [sellerRatings, setSellerRatings] = useState({
    respond: 0,
    payment: 0,
    valuable: 0,
    support: 0,
  });

  const [userData, setUserData] = useState();

  const parentToChild = (slug) => {
    setUserData(slug);
  };

  const handleBuyerRatingChange = (key, rating) => {
    setBuyerRatings({ ...buyerRatings, [key]: rating });
  };

  const handleSellerRatingChange = (key, rating) => {
    setSellerRatings({ ...sellerRatings, [key]: rating });
  };

  const submitRating = async () => {
    const formData = new FormData();
    formData.append("order_id", shortOrderDetails?.id);
    if (userData?.data?.is_seller === 0) {
      formData.append("price", buyerRatings?.price);
      formData.append("quality", buyerRatings?.quality);
      formData.append("service", buyerRatings?.service);
      formData.append("support", buyerRatings?.support);
    } else {
      formData.append("respond", sellerRatings?.respond);
      formData.append("payment", sellerRatings?.payment);
      formData.append("valuable", sellerRatings?.valuable);
      formData.append("support", sellerRatings?.support);
    }
    setLoading(true);
    try {
      await post("/short_order/update/review", formData, getAuthConfig());
      toast.success("Review added successfully");
      setTimeout(() => {
        navigate("/", {
          state: { id: shortOrderDetails?.id },
        });
      }, [1000]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Header home userType={parentToChild} disableSwitch />
      <ToastContainer />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              isCompleted={shortOrderDetails?.step?.current_step}
              currentStep={currentStep}
            />
          </div>
          <div className="order product-order purchase-order-page">
            <div className="page-title">
              <h6>Rating</h6>
            </div>
            <div className="company-detail purchase-order">
              <RatingCard
                rating={shortOrderDetails?.reviews}
                userData={userData?.data}
                sellerRatings={sellerRatings}
                buyerRatings={buyerRatings}
                handleBuyerRatingChange={handleBuyerRatingChange}
                handleSellerRatingChange={handleSellerRatingChange}
              />
            </div>
            {!loadingSO && (
              <div className="btn-wrap">
                <Link
                  to={`${
                    payment === "advance"
                      ? "/product-delivered"
                      : "/product-payment"
                  }`}
                  state={{
                    id: shortOrderDetails?.id,
                    route: location?.state?.route,
                  }}
                  className="button button-blue"
                >
                  Previous
                </Link>
                <button
                  disabled={loading ? true : false}
                  onClick={submitRating}
                  className="button button-primary"
                >
                  Submit & Go To Home
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRating;
