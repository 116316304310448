import React, { useContext, useEffect, useState, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../common/scss/pages/ads.scss";
import Header from "../common/header";
import Package from "../assets/images/package.png";
import featuredTagIcon from "../assets/images/featured-tag.svg";
import profilePic from "../assets/images/profile.png";
import PreviewAds from "./preview-ads";
import ProceedAds from "./proceed-ads";
import { Link, useNavigate } from "react-router-dom";
import { get, post } from "../libs/http-hydrate";
import ReactSelect, { components } from "react-select";

function Ads() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  const minDate = new Date();
  const maxDate = new Date(currentYear, currentMonth + 2, 0); // End of current month + 2 (2 months in advance)

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);

    // Calculate the end date by adding 7 days to the start date
    const calculatedEndDate = new Date(start);
    calculatedEndDate.setDate(start.getDate() + 6);

    setEndDate(calculatedEndDate);
  };
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const [IndPage, setIndPage] = useState(0);
  const [availableIndustry, setAvailableIndustry] = useState({
    items: [],
    has_more: false,
  });
  const [stateList, setStateList] = useState({
    items: [],
    has_more: false,
  });
  const [cityList, setCityList] = useState({
    items: [],
    has_more: false,
  });
  const [FilterAvail, setFilterAvail] = useState({
    search: "",
    state_Id: "",
    city_Id: "",
    industry_Id: "",
    category_id: "",
    subcategory_id: "",
    Filter: "",
    count: "",
  });
  const [productArray, setProductArray] = useState({
    items: [],
    has_more: false,
  });
  const [productSubCategoryArray, setProductSubCategoryArray] = useState({
    items: [],
    has_more: false,
  });
  const [categoryPage, setcategoryPage] = useState(0);
  const [subCatPage, setsubCatPage] = useState(0);
  useEffect(() => {
    if (IndPage === 0) {
    } else {
      Industry();
    }
  }, [IndPage]);
  useEffect(() => {
    if (subCatPage === 0) {
    } else {
      // searcSubCat(ProductData?.category_id);
    }
  }, [subCatPage]);
  useEffect(() => {
    if (categoryPage === 0) {
    } else {
      intialCat();
    }
  }, [categoryPage]);
  function Industry() {
    post(
      "/general/category_listing",
      { type: "industry", page: IndPage + 1 }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    ).then((response) => {
      if (IndPage === 0) {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
      } else {
        setAvailableIndustry((p) => ({
          ...p,
          items: availableIndustry.items.concat(response?.data?.data),
        }));
      }
      setAvailableIndustry((p) => ({
        ...p,
        has_more: response?.data?.has_more,
      }));
      // setAvailableIndustry(response.data.data);
    });
  }
  function intialCat() {
    post("/general/category_listing", {
      type: "product",
      page: categoryPage + 1,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: productArray.items.concat(response?.data?.data),
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((e) => {});
  }

  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      per_page: 50,
      page: subCatPage + 0,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        setProductSubCategoryArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((e) => {
        // alert("this is the error", e);
      });
  }
  function searcIndus(newValue) {
    post(
      "/general/category_listing",
      { type: "industry", page: 1, search: newValue }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setAvailableIndustry((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((e) => {});
  }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  function IntialState() {
    get("/general/region_listing?region_id=1")
      .then((response) => {
        const sortedData = response?.data?.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setStateList((p) => ({
          ...p,
          items: sortedData,
        }));

        setStateList((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.message);
      });
  }

  function City() {
    get(`/general/region_listing?region_id=${FilterAvail?.state_Id}`)
      .then((response) => {
        const sortedData = response.data.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setCityList(sortedData);
      })
      .catch((error) => {
        // toast.error(error?.response?.data?.message);
      });
  }
  // Function to format a date as "Month Day"
  function formatDate(date) {
    const options = { month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  // Function to format a range of dates as "startDay to endDay - Days of the week"
  function formatWeekDays(startDate, endDate) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const startDay = daysOfWeek[startDate.getDay()];
    const endDay = daysOfWeek[endDate.getDay()];
    return `${startDay} to ${endDay}  ${daysOfWeek
      .slice(startDate.getDay(), endDate.getDay() + 1)
      .join(", ")}`;
  }

  // Function to format the week text based on the selected date
  // Function to format the week text based on the selected date
  function formatWeekText(startDate) {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const selectedMonth = monthNames[startDate.getMonth()];
    const weekNumber = Math.ceil(startDate.getDate() / 7);
    let suffix = "th";

    if (weekNumber === 1 || weekNumber === 21 || weekNumber === 31) {
      suffix = "st";
    } else if (weekNumber === 2 || weekNumber === 22) {
      suffix = "nd";
    } else if (weekNumber === 3 || weekNumber === 23) {
      suffix = "rd";
    }

    return `${selectedMonth} ${weekNumber}${suffix} Week`;
  }

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid ads-page">
          <div className="page-title">
            <h6>Create Campaign </h6>
            <button className="button button-blue">Save Draft</button>
          </div>
          <div className="card select-package">
            <div className="card-head">
              <h6>Select Ad Package</h6>
            </div>
            <div className="card-body">
              <Slider {...settings} className="packages">
                <div className="package">
                  <input type="checkbox" id="package-1" />
                  <label htmlFor="package-1">
                    <div className="package-inner">
                      <div className="package-head">
                        <img src={Package} />
                        <strong>400 INR</strong>
                      </div>
                      <div className="package-content">
                        <ul>
                          <li>1 State</li>
                          <li>1 City</li>
                          <li>1 Product Category</li>
                        </ul>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="package">
                  <input type="checkbox" id="package-2" />
                  <label htmlFor="package-2">
                    <div className="package-inner">
                      <div className="package-head">
                        <img src={Package} />
                        <strong>700 INR</strong>
                      </div>
                      <div className="package-content">
                        <ul>
                          <li>1 State</li>
                          <li>1 City</li>
                          <li>1 Product Category</li>
                        </ul>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="package">
                  <input type="checkbox" id="package-3" />
                  <label htmlFor="package-3">
                    <div className="package-inner">
                      <div className="package-head">
                        <img src={Package} />
                        <strong>1000 INR</strong>
                      </div>
                      <div className="package-content">
                        <ul>
                          <li>1 State</li>
                          <li>1 City</li>
                          <li>1 Product Category</li>
                        </ul>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="package">
                  <input type="checkbox" id="package-4" />
                  <label htmlFor="package-4">
                    <div className="package-inner">
                      <div className="package-head">
                        <img src={Package} />
                        <strong>1100 INR</strong>
                      </div>
                      <div className="package-content">
                        <ul>
                          <li>1 State</li>
                          <li>1 City</li>
                          <li>1 Product Category</li>
                        </ul>
                      </div>
                    </div>
                  </label>
                </div>
              </Slider>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="card">
                <div className="card-header">
                  <h6>Enter Details</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">Select Industry</label>
                        <ReactSelect
                          options={
                            availableIndustry?.items &&
                            availableIndustry?.items.map(function (
                              productArray
                            ) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            Industry();
                          }}
                          onKeyDown={(e) => {
                            searcIndus(e.target.value);
                          }}
                          onMenuScrollToBottom={(e) => {
                            if (availableIndustry?.has_more === true) {
                              let p = IndPage + 1;
                              setIndPage(p);
                            }
                          }}
                        />
                        {/* <select className="form-input"><option>Select Industry</option></select> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">
                          Select State where you want to show your Ad
                        </label>
                        <ReactSelect
                          placeholder="Select State"
                          options={
                            stateList?.items &&
                            stateList?.items.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              state_Id: e[0]?.value,
                            }));
                          }}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            IntialState();
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">
                          Select City where you want to show your Profile Ad
                        </label>
                        <ReactSelect
                          placeholder="Select City"
                          options={
                            cityList?.items &&
                            cityList?.items.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          // onChange={(selected) => handleIndustryChange(selected)}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            City();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">
                          Targeted-Select Product Category{" "}
                        </label>

                        <ReactSelect
                          placeholder="Select Product Category"
                          options={
                            productArray?.items &&
                            productArray?.items.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              category_id: e[0]?.value,
                            }));
                          }}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            intialCat();
                          }}
                        />
                        {/* <select className="form-input"><option>Select Product Category</option></select> */}
                      </div>
                    </div>
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">
                          Select Product Sub-Category (Optional)
                        </label>

                        <ReactSelect
                          placeholder="Select Sub Product Category"
                          options={
                            productSubCategoryArray?.items &&
                            productSubCategoryArray?.items.map(function (
                              productArray
                            ) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              subcategory_id: e[0]?.value,
                            }));
                          }}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            searcSubCat(FilterAvail?.category_id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm">
                      <div className="form-field">
                        <label className="form-label">
                          Select which of your products categories you want to
                          show in Ad (Optional - Max 5 Add)
                        </label>
                        <ReactSelect
                          placeholder="Select Product Category"
                          options={
                            productArray?.items &&
                            productArray?.items.map(function (productArray) {
                              return {
                                value: productArray.id,
                                label: productArray.name,
                              };
                            })
                          }
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          onChange={(e) => {
                            setFilterAvail((p) => ({
                              ...p,
                              category_id: e[0]?.value,
                            }));
                          }}
                          allowSelectAll={true}
                          // value={fields.industry.value}
                          onFocus={(e) => {
                            e.preventDefault();
                            intialCat();
                          }}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="card select-sloat">
                <div className="card-header">
                  <h6>Select Available Week Slots</h6>
                  <p>
                    One available week slot selection from calendar, Max 2
                    months of advance slot can be booked
                  </p>
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <div className="card-footer">
                  <button className="button button-secondary">Cancel</button>
                  <button className="button button-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-wrap">
            <button
              className="button button-green"
              data-toggle="modal"
              data-target="#PreviewAdsModal"
            >
              Preview Your Ad
            </button>
            <button className="button button-primary">Submit Ad</button>
          </div>
          <div className="card ads-selected">
            <div className="row">
              <div className="col-sm-6">
                <div className="featured">
                  <div className="user-profile">
                    <div className="user-profile-image">
                      <img src={profilePic} className="profile-pic" />
                    </div>
                    <div className="user-profile-content">
                      <div className="user-profile-name">
                        <h6>
                          Makita Panchal<span>(Profile ID: 302101)</span>
                        </h6>
                        <p>Owner at mehta brothers</p>
                      </div>
                      <div className="featured-tag">
                        <img src={featuredTagIcon} />
                        Featured Profile
                      </div>
                    </div>
                  </div>
                  <div className="featured-details">
                    <ul>
                      <li>
                        <span className="featured-details--label">
                          Location
                        </span>
                        :
                        <span className="featured-details--text">
                          Ahmedabad
                        </span>
                      </li>
                      <li>
                        <span className="featured-details--label">GST</span>:
                        <span className="featured-details--text">
                          NHD9F987G0834
                        </span>
                      </li>
                      <li>
                        <span className="featured-details--label">
                          Deals Closed
                        </span>
                        :<span className="featured-details--text">32</span>
                      </li>
                      <li>
                        <span className="featured-details--label">
                          Followers
                        </span>
                        :<span className="featured-details--text">234</span>
                      </li>
                    </ul>
                    <div className="featured-details--highlights">
                      <strong>Industry: Industrial Supplies</strong>
                    </div>
                    <div className="featured-details--highlights">
                      <strong>Deals In</strong>
                      <p>
                        Abrasives, Power Tools, Hand To- ols, and Industrial
                        Safty Tools
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="selected-slot">
                  <h6>Selected Week Slots</h6>
                  {startDate && endDate ? (
                    <div className="selected-slot-week">
                      {/* <strong>You Choose June 3rd Week</strong>
                    <span>20th June to 26th June - Monday to Sunday</span> */}
                      <strong>You Choose {formatWeekText(startDate)}</strong>

                      <span>
                        {formatDate(startDate)} to {formatDate(endDate)} -{" "}
                        {formatWeekDays(startDate, endDate)}
                      </span>
                    </div>
                  ) : (
                    <p>Please select a week slot.</p>
                  )}
                  <p>
                    If You Need To Change Week Slots <a href="">Click Here</a>
                  </p>
                </div>
                <div className="action">
                  <h6>Actions</h6>
                  <button className="button button-blue">Save Darft</button>
                  <button className="button button-primary">Submit Ad</button>
                </div>
              </div>
            </div>
          </div>
          <PreviewAds />
          <ProceedAds />
        </div>
      </div>
    </>
  );
}

export default Ads;
