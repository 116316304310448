import React, { useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { ReactComponent as SendIcon } from "../assets/images/short-send.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import swal from "sweetalert";
import Skeleton from "react-loading-skeleton";
import Auth from "../libs/auth";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";
import { Link, useNavigate } from "react-router-dom";

function AcceptOffer() {
  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getNegotiationDetailSO = async () => {
    setLoading(true);
    await get(`/negotiation/details/5`, getAuthConfig())
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
        }
      });
  };

  const user = Auth.getCurrentuser();

  const handleAcceptOrReject = async (action, id) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("negotiation_id", id);
    await post("/negotiation/action", formData, getAuthConfig());
  };

  UseEffectOnce(() => {
    getNegotiationDetailSO();
  });

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order">
            <div className="page-title">
              <h6>Buyer Accept Offer / Buyer View</h6>
            </div>
            <div className="order-table">
              {!products?.length && !loading ? (
                <>No Data Found</>
              ) : (
                <>
                  {loading ? (
                    <>
                      <Skeleton height={270} />
                    </>
                  ) : (
                    <table>
                      <thead>
                        <tr>
                          <th>Sr NO</th>

                          <th>Product Category, Product Sub Category</th>
                          <th>Product Differentiation</th>
                          <th>Required Qty</th>
                          <th>GST (%)</th>
                          <th>Seller Offer Price per Qty</th>
                          <th className="height-light">
                            Required Price by Buyer
                          </th>
                          <th className="height-light">Final Offer Price</th>
                          <th>Total Gross Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products?.map((item, index) => (
                          <>
                            <tr key={item?.id}>
                              <td>{index + 1}</td>

                              <td>
                                {`${
                                  item?.product?.category?.parent?.name !==
                                    undefined ||
                                  (item?.product?.category?.parent?.name !==
                                    null &&
                                    item?.product?.category?.parent?.name + ",")
                                } ${item?.product?.category?.name}`}
                              </td>
                              <td>
                                {item?.product?.brand?.name &&
                                  `Brand: ${item?.product?.brand?.name},`}
                                {item?.product?.size &&
                                  `Size: ${item?.product?.size},`}
                                {item?.product?.color?.name &&
                                  `Color: ${item?.product?.color?.name}`}
                              </td>
                              <td>{item?.quantity}</td>
                              <td>{item?.gst?.percentage}</td>
                              <td>₹{item?.initial?.amount}</td>
                              <td className="height-light">₹34067</td>
                              <td className="height-light">₹34067</td>
                              <td>₹324234</td>
                            </tr>
                          </>
                        ))}
                        <tr>
                          <td colSpan={"6"}></td>
                          <td className="height-light" colSpan={"2"}>
                            Total
                          </td>
                          <td className="height-light">₹1234234</td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td className="height-light" colSpan={"2"}>
                            2% Freight
                          </td>
                          <td className="height-light">₹22322</td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td className="height-light" colSpan={"2"}>
                            Total after freight
                          </td>
                          <td className="height-light">₹234322</td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td className="height-light" colSpan={"2"}>
                            GST
                          </td>
                          <td className="height-light">
                            ₹{shortOrderDetails?.gst}
                          </td>
                        </tr>
                        <tr className="footer">
                          <td className="height-light">Profile ID</td>
                          <td className="height-light">23443</td>
                          <td colSpan={"2"} className="height-light">
                            Payment Terms
                          </td>
                          <td colSpan={"2"} className="height-light">
                            Advance
                          </td>
                          <td className="height-light" colSpan={"2"}>
                            Total Invoice Value
                          </td>
                          <td className="height-light">₹234324</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              )}
              <div className="btn-wrap">
                <button
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to release short order?",
                      icon: "warning",
                      success: true,
                      buttons: ["Cancel", "Ok"],
                    }).then((willDelete) => {
                      if (willDelete) {
                        setLoading(true);

                        handleAcceptOrReject("accept", shortOrderDetails?.id);
                      } else {
                      }
                    })
                  }
                  className="button button-green button-green--bordered btn-release"
                >
                  <SendIcon />
                  Release Short Order
                </button>
                <button
                  onClick={() =>
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to reject this offer  ?",
                      icon: "warning",
                      dangerMode: true,
                      buttons: ["Cancel", "Ok"],
                    }).then((willDelete) => {
                      setLoading(true);
                      if (willDelete) {
                        handleAcceptOrReject("reject", shortOrderDetails?.id);
                        setLoading(false);
                      } else {
                      }
                    })
                  }
                  className="button button-red button-red--bordered btn-reject"
                >
                  <RejectIcon />
                  Reject
                </button>

                <button
                  className="button button-primary button-primary--bordered"
                  onClick={() => {
                    if (user?.id === shortOrderDetails?.to_user_id) {
                      createThread(shortOrderDetails?.user_id);
                    } else {
                      createThread(shortOrderDetails?.to_user_id);
                    }
                  }}
                >
                  <OrderMeetingIcon />
                  Meeting Room
                </button>
              </div>
              {shortOrderDetails?.status === "rejected" &&
              shortOrderDetails.can_edit === 0 ? (
                <div
                  className="note"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "red",
                  }}
                >
                  <p>Sorry! This Offer is already Rejected</p>
                </div>
              ) : (
                <div
                  className="note"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "red",
                  }}
                >
                  <p>{`Waiting for ${
                    user.is_seller === 1 ? "buyer" : "seller"
                  }'s confirmation`}</p>

                  <Link
                    className="button button-green button-green--bordered btn-release"
                    to={`/transaction`}
                  >
                    Go Back to Negotiation List
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptOffer;
