import { useContext, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
function ViewBrand(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  const brandData = props.data;
  //brands data
  return (
    <>
      <div
        className="modal fade certifications-modal"
        id="ViewBrand"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ViewBrandLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">
                View Brand
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="view-certificate">
                <div
                  className="img-block"
                  style={{ width: "50%", height: "30%", margin: "auto" }}
                >
                  <img
                    style={{
                      border: darkMode
                        ? "1px solid #383a3d"
                        : "1px solid #BDBDBD",
                    }}
                    src={brandData.icon}
                    alt=""
                  />
                </div>
                <div className="content-block">
                  <h6>{brandData.name}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewBrand;
