import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import Auth from "../libs/auth";
import { post } from "../libs/http-hydrate";
function NetworkModal(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const user = Auth.getCurrentUser();
  const initialNetworkData = props.data;

  const [customers, setCustomers] = useState();
  const [retailers, setRetailers] = useState();
  const [dealers, setDealers] = useState();

  useEffect(() => {
    setCustomers(initialNetworkData.customers);
    setRetailers(initialNetworkData.retailers);
    setDealers(initialNetworkData.dealers);
  }, [initialNetworkData]);

  const handleNetworkData = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("customers", customers);
    formData.append("retailers", retailers);
    formData.append("dealers", dealers);

    post("/user/network_details", formData, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((reponse) => {
        if (reponse.status === 200) {
          window.location.reload(false);
        }
      })
      .catch((err) => {
        alert("Something went wrong.......");
      });
  };

  const inputStyle = {
    border: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
  };

  return (
    <>
      <div
        className="modal fade certifications-modal"
        id="NetworkModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="NetworkModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                Update Network Details{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div
              className="modal-body row"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div className="form-field col-md-3">
                <label className="form-label">
                  Customers <span className="mendatory"></span>
                </label>
                <input
                  type="number"
                  name="cname"
                  id="cname"
                  className="form-input"
                  value={customers}
                  placeholder="How many customers you have?"
                  onChange={(e) => setCustomers(e.target.value)}
                  style={{ ...inputStyle }}
                />
              </div>
              <div className="form-field col-md-3">
                <label className="form-label">
                  Retailers <span className="mendatory"></span>
                </label>
                <input
                  type="number"
                  name="cname"
                  id="cname"
                  className="form-input"
                  value={retailers}
                  placeholder="How many retailers you have?"
                  onChange={(e) => setRetailers(e.target.value)}
                  style={{ ...inputStyle }}
                />
              </div>
              <div className="form-field col-md-3">
                <label className="form-label">
                  Dealers <span className="mendatory"></span>
                </label>
                <input
                  type="number"
                  name="cname"
                  id="cname"
                  className="form-input"
                  value={dealers}
                  placeholder="How many dealers you have?"
                  onChange={(e) => setDealers(e.target.value)}
                  style={{ ...inputStyle }}
                />
              </div>
            </div>
            <div
              style={{
                borderTop: darkMode ? "1px solid #383a3d" : "1px solid #BDBDBD",
              }}
              className="modal-footer"
            >
              <button
                type="button"
                data-dismiss="modal"
                className="button button-primary"
                onClick={(e) => handleNetworkData(e)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NetworkModal;
