import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Auth from "../libs/auth";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import Skeleton from "react-loading-skeleton";
import BuyerTerms from "./BuyerTerms";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import { SwitchProfile } from "../context/switchProfile.context";

function ProductPurchaseOrder() {
  const location = useLocation();
  const user = Auth.getCurrentUser();
  const [ShortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [GstAvg, setGstAvg] = useState(0);
  const [isPOChecked, setisPOChecked] = useState(false);
  const [isPOType, setIsPOType] = useState("");
  const [poid, setPOID] = useState("");
  const [error, setError] = useState(false);
  const [buttonName, setButtonName] = useState("Submit");

  let currentStep = 3;

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const userData = localStorage.getItem("buyerAccount");

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoading(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          setProducts(res?.data?.data?.items);
          let gstSumm = 0;

          res?.data?.data?.items.map((item, index) => {
            gstSumm = gstSumm + Number(item?.gst?.percentage);
          });
          setGstAvg(
            parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
          );
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  async function POChecker() {
    await get(
      `/short_order/po_id_checker?order_id=${location?.state?.id}&po_id=${poid}`,
      getAuthConfig()
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setButtonName("Submitted");
        }
        setisPOChecked(true);
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          toast.error(err?.response?.data?.message);
        }
      });
  }

  async function UPDATEData() {
    getProductCartSO();
  }

  useEffect(() => {
    if (poid.length > 0 && isPOType === "manual") {
      setPOID("");
    } else if (isPOType === "auto") {
      setButtonName("Submit");
    }
  }, [isPOType]);

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={ShortOrderDetails?.step?.current_step}
              orderId={ShortOrderDetails?.id}
            />
          </div>
          <div className="order product-order purchase-order-page">
            <div className="page-title">
              <h6>Purchase Order</h6>
              {Number(ShortOrderDetails?.step?.current_step) >= 3 && (
                <a
                  href={ShortOrderDetails?.links?.purchase_order}
                  className="button button-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Purchase Order
                </a>
              )}
              {userData === "true" &&
                ShortOrderDetails?.step?.current_step < 3 && (
                  <>
                    {isPOChecked === true ? (
                      <button
                        className="button button-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isPOChecked != true) {
                            setError(true);
                          }
                        }}
                        data-toggle="modal"
                        data-target="#buyerTerms"
                      >
                        Generate Purchase Order <br />{" "}
                        {userData === "true"
                          ? "(" +
                            ShortOrderDetails?.to_user?.company_details?.name +
                            ")"
                          : "(" +
                            ShortOrderDetails?.user?.company_details?.name +
                            ")"}
                      </button>
                    ) : (
                      <button
                        className="button button-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isPOChecked != true) {
                            setError(true);
                          }
                        }}
                      >
                        Generate Purchase Order <br />{" "}
                        {userData === "true"
                          ? "(" +
                            ShortOrderDetails?.to_user?.company_details?.name +
                            ")"
                          : "(" +
                            ShortOrderDetails?.user?.company_details?.name +
                            ")"}
                      </button>
                    )}
                  </>
                )}
            </div>
            {Number(ShortOrderDetails?.step?.current_step) < 3 && (
              <div className={userData !== "true" ? "d-none" : ""}>
                <div
                  style={{ paddingBottom: "8px" }}
                  className="purchase-order-head"
                  disabled={
                    user?.id === ShortOrderDetails?.to_user?.id ? true : false
                  }
                >
                  <span className="po-id">Auto Generate PO ID: </span>

                  {Number(ShortOrderDetails?.step?.current_step) !== 3 && (
                    <>
                      <div className="form-field">
                        <input
                          type="radio"
                          name="po_ref"
                          id="auto"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setPOID(ShortOrderDetails?.po_id);
                              setError(false);
                              setisPOChecked(true);
                              setIsPOType("auto");
                            }
                          }}
                        />
                        <label htmlFor="auto">Manual Generate</label>
                      </div>
                      <div className="form-field">
                        <input
                          type="radio"
                          name="po_ref"
                          id="manual"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setIsPOType("manual");
                              setisPOChecked(false);
                            }
                          }}
                        />
                        <label htmlFor="manual">
                          <input
                            type="text"
                            className="form-input"
                            placeholder="Enter Manually Order Reference"
                            onChange={(e) => {
                              e.preventDefault();
                              setError(false);
                              setisPOChecked(false);
                              setPOID(e.target.value);
                            }}
                            disabled={isPOType === "manual" ? false : true}
                          />

                          <button
                            className={`button button-primary`}
                            disabled={
                              isPOType === "auto" ||
                              poid === "" ||
                              buttonName === "Submitted"
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                poid != ShortOrderDetails?.po_id &&
                                poid !== ""
                              ) {
                                POChecker();
                              } else {
                                setError(true);
                              }
                            }}
                          >
                            {buttonName}
                          </button>
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {error === true && (
              <span className="text-danger" style={{ marginLeft: "100px" }}>
                <b>Please Enter POID or Select Auto generate</b>
              </span>
            )}
            <div className="order-table" style={{ paddingTop: "15px" }}>
              <span
                style={{ fontSize: "16px", marginBottom: "5px" }}
                className="order-id"
              >
                <strong>Order ID #{ShortOrderDetails?.id}</strong>
              </span>
              {!loading && products?.length ? (
                <table>
                  <thead style={{ fontSize: "14px" }}>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Category, Product Sub Category</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      <th
                        colSpan={`${
                          ShortOrderDetails?.status === "temporary" ||
                          ShortOrderDetails?.status === "draft"
                            ? 2
                            : 3
                        }`}
                      >
                        Total Gross Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr style={{ fontSize: "14px" }} key={item.id}>
                            <td>{index + 1}</td>
                            <td>{`${item?.product?.category?.name}`}</td>
                            <td>
                              {item?.product?.brand?.name
                                ? `Brand: ${item?.product?.brand?.name},`
                                : "Brand: N/A,"}
                              &nbsp;
                              {item?.product?.size
                                ? `Size: ${item?.product?.size},`
                                : "Size: N/A,"}
                              &nbsp;
                              {item?.product?.color?.name
                                ? `Color: ${item?.product?.color?.name}`
                                : "Color: N/A"}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.gst?.percentage}</td>
                            <td>₹{item?.amount}</td>
                            <td
                              colSpan={`${
                                ShortOrderDetails?.status === "temporary" ||
                                ShortOrderDetails?.status === "draft"
                                  ? 2
                                  : 3
                              }`}
                            >
                              ₹{item?.sub_total}
                            </td>
                            {(ShortOrderDetails?.status === "temporary" ||
                              ShortOrderDetails?.status === "draft") && (
                              <td>
                                <DeleteIcon style={{ cursor: "pointer" }} />
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total</td>
                      <td className="height-light">
                        ₹{ShortOrderDetails?.sub_total}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">
                        {ShortOrderDetails?.freight_charge?.percentage}% Freight
                      </td>
                      <td className="height-light">
                        ₹{ShortOrderDetails?.freight_charge?.charge}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total after freight</td>
                      <td className="height-light">
                        ₹
                        {parseFloat(
                          Number(ShortOrderDetails?.freight_charge?.charge) +
                            Number(ShortOrderDetails?.sub_total)
                        )?.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">GST</td>
                      <td className="height-light">
                        ₹{ShortOrderDetails?.gst}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }} className="footer">
                      <td className="height-light">Profile ID</td>
                      <td className="height-light">
                        {ShortOrderDetails?.user?.id}
                      </td>
                      <td colSpan={"2"} className="height-light">
                        Payment Terms
                      </td>
                      <td
                        style={{ textTransform: "capitalize" }}
                        colSpan={"2"}
                        className="height-light"
                      >
                        {ShortOrderDetails?.payment_type}
                      </td>
                      <td className="height-light">Total Invoice Value</td>
                      <td className="height-light">
                        ₹{ShortOrderDetails?.grand_total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}
            </div>
            {!loading && (
              <>
                {userData !== "true" &&
                  ShortOrderDetails?.step?.current_step < 3 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "25px",
                      }}
                    >
                      <p style={{ color: "red" }}>
                        Please wait until buyer generate purchase order
                      </p>
                    </div>
                  )}
                <div className="btn-wrap">
                  <Link
                    to={"/product-company-detail"}
                    state={{
                      id: ShortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    className="button button-blue"
                  >
                    Previous
                  </Link>

                  {Number(ShortOrderDetails?.step?.current_step) >= 3 ? (
                    <Link
                      to={"/product-purchase-invoice"}
                      className="button button-primary"
                      state={{
                        id: ShortOrderDetails?.id,
                        route: location?.state?.route,
                      }}
                    >
                      Next
                    </Link>
                  ) : (
                    <button className="button button-primary" disabled={true}>
                      Next
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <BuyerTerms
        POID={poid}
        OID={ShortOrderDetails?.id}
        UPDATEData={UPDATEData}
      />
    </>
  );
}

export default ProductPurchaseOrder;
