import React, { useEffect, useState } from "react";
import "../common/scss/pages/settings.scss";
import "../common/scss/pages/support.scss";
import Header from "../common/header";

import SupportList from "../components/Support/SupportList";
import SupportChatBox from "../components/Support/SupportChatBox";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SupportOngoing = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(null);
  const [message, setMessage] = useState("");
  const [chatDataHistory, setChatDataHistory] = useState({
    items: [],
    has_more: false,
  });
  const [ticketsList, setTicketsList] = useState({
    items: [],
    has_more: false,
  });
  const [chatPage, setChatPage] = useState(1);
  const [ticketsListPage, setTicketsPage] = useState(1);
  const [ticketIds, setTicketIds] = useState("");
  const [selectedTab, setSelectedTab] = useState("on_going");
  const [sortBy, setSortBy] = useState("desc");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (location?.state?.id) {
      setTicketIds(location?.state?.id);
    }
  }, []);
  useEffect(() => {
    if (ticketIds != undefined || ticketIds != null || ticketIds != "") {
      chatHistory();
    }
  }, [ticketIds, chatPage]);
  useEffect(() => {
    getSupportTickets();
  }, [selectedTab, sortBy, search, ticketsListPage]);

  const chatHistory = async () => {
    if (ticketIds) {
      setLoadingChat(true);
      try {
        if (message) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        const response = await get(
          `/support-ticket/${ticketIds}/chat-history?page=${chatPage}&is_read_only`,
          getAuthConfig()
        );
        setLoading(false);

        if (chatPage > 1) {
          setChatDataHistory((p) => ({
            ...p,
            items: chatDataHistory.items.concat(response?.data?.data?.chats),
          }));
        } else {
          setChatDataHistory((p) => ({
            ...p,
            items: response?.data?.data?.chats,
          }));
        }
        setChatDataHistory((p) => ({
          ...p,
          has_more: response?.data?.data?.has_more,
        }));
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      } finally {
        setLoadingChat(false);
      }
    }
  };

  const getSupportTickets = async () => {
    try {
      const response = await get(
        `/support-ticket?page=${ticketsListPage}&type=${selectedTab}&sort=${sortBy}&search=${search}`,
        getAuthConfig()
      );

      if (ticketsListPage > 1) {
        setTicketsList((p) => ({
          ...p,
          items: ticketsList.items.concat(response?.data?.data?.tickets),
        }));
      } else {
        setTicketsList((p) => ({
          ...p,
          items: response?.data?.data?.tickets,
        }));
      }
      setTicketsList((p) => ({
        ...p,
        has_more: response?.data?.data?.has_more,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleClick = (id) => {
    setTicketIds(id);
    setChatPage(1);
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setTicketsPage(1); // Reset the ticketsListPage when selectedTab changes
  };
  const handleSortby = (sort) => {
    setSortBy(sort);
    setTicketsPage(1); // Reset the ticketsListPage when selectedTab changes
  };
  const handleSearch = (search) => {
    setSearch(search);
  };
  const handlePageChangeforchatList = () => {
    setTicketsPage((prevPageNumber) => prevPageNumber + 1);
  };

  const handlePageChangeforchatListchat = () => {
    setChatPage((prevPageNumber) => prevPageNumber + 1);
  };

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="layout-grid-box-column2 ">
            <div className="layout-grid layout-grid--left">
              <SupportList
                tab={selectedTab}
                handleTabClick={handleTabClick}
                sort={sortBy}
                handleSortby={handleSortby}
                search={search}
                handleSearch={handleSearch}
                data={ticketsList}
                handleClick={handleClick}
                ticketIds={ticketIds ? ticketIds : location?.state?.id}
                handlePageChangeforchatList={handlePageChangeforchatList}
              />
            </div>
            <div className="layout-grid layout-grid--main">
              <div className="card">
                <SupportChatBox
                  loadingChat={loadingChat}
                  tab={selectedTab}
                  message={message}
                  setMessage={setMessage}
                  chatHistory={ticketIds && chatHistory}
                  data={chatDataHistory}
                  loading={loading}
                  handlePageChangeforchatListchat={
                    handlePageChangeforchatListchat
                  }
                  chatPage={chatPage}
                  ticketIds={ticketIds ? ticketIds : location?.state?.id}
                  supportDetails={location?.state?.supportDetails}
                  attachment={location?.state?.attachment}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportOngoing;
