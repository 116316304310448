import React, { useState, useEffect } from "react";
import "./clock.css";
import Skeleton from "react-loading-skeleton";

const DigitalClock = ({ loading, dueTime }) => {
  const referenceDate = new Date(dueTime);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = referenceDate - currentTime;
    const isNegative = difference < 0;

  

    let days = Math.floor(Math.abs(difference) / (1000 * 60 * 60 * 24));
    const hours = Math.floor((Math.abs(difference) / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((Math.abs(difference) / (1000 * 60)) % 60);
    const seconds = Math.floor((Math.abs(difference) / 1000) % 60);
  
    if (isNegative) {
      days = -days; // Set days as negative to represent the countdown
    }
  

    return { days, hours, minutes, seconds, isNegative };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTimeRemaining(remainingTime);

    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (value) => {
    return value.toString().padStart(2, "0");
  };

  return (
    <div className="container text-center mt-5">
      {loading ? (
        <Skeleton height={200} />
      ) : (
        <div className="digital-clock">
          <h2>
            {timeRemaining.isNegative ? "- " : ""}
            {`${formatTime(timeRemaining.days)}:${formatTime(
              timeRemaining.hours
            )}:${formatTime(timeRemaining.minutes)}:${formatTime(
              timeRemaining.seconds
            )}`}
          </h2>
          <h5>Days Hours Minutes Seconds</h5>
        </div>
      )}
    </div>
  );
};

export default DigitalClock;
