import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/settings.scss";
import "../common/scss/pages/support.scss";
import Header from "../common/header";
import ProductImage from "../assets/images/product-image1.png";
import CompanyLogo from "../assets/images/company-logo.png";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SupportForm from "../components/Support/SupportForm";
import { getAuthConfig, post } from "../libs/http-hydrate";
import { ToastContainer, toast } from "react-toastify";
import SupportLeftSidebar from "../components/Support/SupportLeftSidebar";
function SupportOrder() {
  const [supportDetails, setSupportDetails] = useState({
    subject: "",
    title: "",
    description: "",
  });
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSupportDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAttachment = (e) => {
    setAttachment(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("short_order_id", location?.state?.id);
    formData.append("subject", supportDetails.subject);
    formData.append("title", supportDetails.title);
    formData.append("description", supportDetails.description);
    if (attachment !== null) {
      formData.append("attachments[0]", attachment);
    }

    setLoading(true);
    try {
      const response = await post(
        "/support-ticket/create",
        formData,
        getAuthConfig()
      );

      if (response) {
        toast.success("Your issue is raised successfully");
        setLoading(false);
        setSupportDetails({
          subject: "",
          title: "",
          description: "",
        });
        navigate(`/support-chat`, { state: { id: response?.data?.data?.id } });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <ToastContainer />

      <Header home />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="page-title">
            <h6>BusiMeet Help Center | 24x7 Customer Care Support</h6>
          </div>
          <div className="layout-grid-box-column2 ">
            <SupportLeftSidebar />
            <div className="layout-grid layout-grid--main">
              <SupportForm
                type="order"
                loading={loading}
                handleAttachment={handleAttachment}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                supportDetails={supportDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportOrder;
