import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/arrow-down.svg";

const SupportLeftSidebar = () => {
  return (
    <>
      <div className="layout-grid layout-grid--left">
        <div className="card sidebar-nav support-sidebar">
          <div className="sidebar-title">
            <h6>TYPE OF ISSUE</h6>
            <a
              className="collapse-button mobile-view"
              data-toggle="collapse"
              href="#collapseSidebar"
              role="button"
              aria-expanded="false"
              aria-controls="collapseSearch"
            >
              <ArrowDownIcon />
            </a>
          </div>
          <ul className="collapse desktop-view" id="collapseSidebar">
            <li className="sidebar-nav--item">
              <Link to={"/support"}>Help with your issues</Link>
            </li>
            <li className="sidebar-nav--item active">Help with your order</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SupportLeftSidebar;
