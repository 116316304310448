import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { cloneDeep } from "lodash";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import Auth from "../libs/auth";
function ProductNegotiationViewBuyer() {
  const location = useLocation();
  const SOID =
    location?.state?.shortOrder === true
      ? location?.state?.id
      : location?.state?.id;

  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState();
  const [value, setValue] = useState([]);
  const [GstAvg, setGstAvg] = useState(0);
  const navigate = useNavigate();
  const [TotalData, setTotalData] = useState({
    total: 0,
    freightCharge: 0,
    afterFreight: 0,
    gst: 0,
    totolInvoice: 0,
  });

  useEffect(() => {
    TotalDataFunc();
  }, [products]);

  useEffect(() => {
    TotalDataFunc();
  }, [value]);

  useEffect(() => {
    FreightCharfe();
  }, [TotalData]);

  useEffect(() => {
    afterFreight();
  }, [TotalData]);

  useEffect(() => {
    GstWithAvg();
  }, [TotalData]);

  useEffect(() => {
    FinalInvoic();
  }, [TotalData]);

  function TotalDataFunc() {
    let ttal = 0;

    products?.map((item, index) => {
      if (shortOrderDetails?.final_offer?.sub_total != null) {
        if (item?.final_offer?.amount === null) {
          ttal =
            ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
        } else if (item?.final_offer?.amount <= 0) {
          // toast.error("Not Allowded Numbers");
          ttal =
            ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
        } else {
          // alert(value[index])
          ttal =
            ttal + Number(item?.quantity) * Number(item?.final_offer?.amount);
        }
      } else {
        if (item?.negotiation?.amount === null) {
          ttal = ttal + Number(item?.quantity) * Number(item?.product?.amount);
        } else if (item?.negotiation?.amount <= 0) {
          // toast.error("Not Allowded Numbers");
          ttal = ttal + Number(item?.quantity) * Number(item?.product?.amount);
        } else {
          // alert(value[index])
          ttal =
            ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
        }
      }
    });
    setTotalData((p) => ({ ...p, total: ttal }));
  }

  function FreightCharfe() {
    if (TotalData?.total > 0) {
      let fData =
        (Number(shortOrderDetails?.freight_charge_percentage) *
          Number(TotalData?.total)) /
        100;
      setTotalData((p) => ({ ...p, freightCharge: fData }));
    }
  }

  const user = Auth?.getCurrentUser();

  function afterFreight() {
    let Aftr = TotalData?.total + TotalData?.freightCharge;
    setTotalData((p) => ({ ...p, afterFreight: Aftr }));
  }
  function GstWithAvg() {
    if (
      shortOrderDetails?.final_offer?.gst != null &&
      shortOrderDetails?.status === "final_offer"
    ) {
      setTotalData((p) => ({ ...p, gst: shortOrderDetails?.final_offer?.gst }));
    } else if (
      (shortOrderDetails?.negotiation?.gst != null &&
        shortOrderDetails?.status === "revise_offer") ||
      shortOrderDetails?.status === "initial_offer"
    ) {
      setTotalData((p) => ({ ...p, gst: shortOrderDetails?.negotiation?.gst }));
    } else {
      setTotalData((p) => ({ ...p, gst: shortOrderDetails?.initial?.gst }));
    }
  }
  function FinalInvoic() {
    let Final = Number(TotalData?.afterFreight) + Number(TotalData?.gst);

    setTotalData((p) => ({ ...p, totolInvoice: Final }));
  }

  const sendNegotiation = async (action) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one fields  ");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price ");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      formData.append("order_id", shortOrderDetails?.id);
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        // check if price is greater than 0
        const price = value[i];
        if (price > 0) {
          // append order_item_id and price to formData
          formData.append(`negotiation[${i}][order_item_id]`, orderItemId);
          formData.append(`negotiation[${i}][price]`, price);
        }
      }
      formData.append("type", action);
      await post("/short_order/move_to_negotiation", formData, getAuthConfig())
        .then((res) => {
          toast.success("Offer is sent successfully");
          setTimeout((e) => {
            navigate("/negotiation-buyer");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  const getProductCartSO = async () => {
    setLoading(true);
    let url;
    if (location?.state?.shortOrder === true) {
      url = `/short_order/details/${SOID}`;
    } else {
      url = `/negotiation/details/${SOID}`;
    }

    await get(url, getAuthConfig())
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
        let gstSumm = 0;

        res?.data?.data?.items.map((item, index) => {
          gstSumm = gstSumm + Number(item?.gst?.percentage);
        });
        setGstAvg(
          parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
        );
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
          navigate("/negotiation-buyer");
        }
      });
  };

  const handleBuyerNegotiationPrice = (e, index) => {
    const enteredPrice = e.target.value;
    setValue((prev) => {
      const newDivisions = cloneDeep(prev);
      newDivisions[index] = e.target.value;
      setIndex(index);

      // Total Gross Field Calculation
      const item = products[index];
      const qty = item?.quantity;
      const subTotalDifference = enteredPrice * qty;
      // const subTotalDifference = enteredPrice;

      // const td1 = e.target.parentNode.nextElementSibling;
      // td1.innerText = `₹${subTotalDifference}`;

      // Total Field Calculation
      let total = 0;
      let grandTotalDifference = 0;

      for (let i = 0; i < newDivisions.length; i++) {
        const grandTotal = shortOrderDetails?.sub_total;
        total += parseFloat(newDivisions[i] || 0);
        grandTotalDifference = grandTotal - total;
      }

      return newDivisions;
    });
  };

  UseEffectOnce(() => {
    getProductCartSO();
  });

  const handleAcceptOrReject = async (action, id, index) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("negotiation_id", id);
    await post("/negotiation/action", formData, getAuthConfig()).then((res) => {
      if (action === "reject") {
        toast.success("Offer Rejected Successfully ");
        setTimeout(() => {
          navigate("/negotiation-buyer");
        }, 2000);
      }
      if (action === "accept") {
        toast.success("Offer Accepted Successfully ");
        getReleaseorder(id);
      }
    });
  };

  const getReleaseorder = async (id) => {
    await get(`/negotiation/release-short-order/${id}`, getAuthConfig())
      .then((res) => {
        setTimeout(() => {
          navigate("/product-company-detail", {
            state: { id: res?.data?.data?.id },
          });
        }, 2000);
        // localStorage.setItem("SOID", res?.data?.data?.id);
      })
      .catch((err) => {});
  };

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {});
  };

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order product-order">
            <div className="page-title">
              <h6>
                {" "}
                Negotiation With{" "}
                {shortOrderDetails?.to_user?.company_details?.name || "-"}
              </h6>
            </div>
            <div className="order-table">
              <span
                style={{ fontSize: "16px", marginBottom: "5px" }}
                className="order-id"
              >
                <strong>Order ID #{shortOrderDetails?.id}</strong>
              </span>
              {!loading && products?.length ? (
                <table>
                  <thead style={{ fontSize: "14px" }}>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Category, Product Sub Category</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      <th className="height-light">Buyer Negotiation Price</th>
                      {shortOrderDetails?.final_offer?.sub_total != null ? (
                        <th>Final Offer Price Seller</th>
                      ) : (
                        ""
                      )}
                      <th>Total Gross Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr style={{ fontSize: "14px" }} key={item?.id}>
                            <td>{index + 1}</td>
                            <td>
                              {`${
                                item?.product?.category?.parent?.name !==
                                  undefined || null
                                  ? item?.product?.category?.parent?.name + ","
                                  : ""
                              } ${item?.product?.category?.name}`}
                            </td>
                            <td>
                              {item?.product?.brand?.name &&
                                `Brand: ${item?.product?.brand?.name},`}
                              {item?.product?.size &&
                                `Size: ${item?.product?.size},`}
                              {item?.product?.color?.name &&
                                `Color: ${item?.product?.color?.name}`}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.negotiation?.gst?.percentage}</td>
                            <td>₹{item?.initial?.amount}</td>
                            <td className="height-light">
                              <input
                                placeholder="Enter Value"
                                value={item?.negotiation?.amount}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) =>
                                  handleBuyerNegotiationPrice(e, index)
                                }
                                disabled
                              />
                            </td>

                            {item?.final_offer?.amount != null ? (
                              <td>{item?.final_offer?.amount}</td>
                            ) : (
                              ""
                            )}

                            <td>
                              ₹
                              {item?.final_offer?.amount != null
                                ? parseFloat(
                                    Number(item?.final_offer?.amount) *
                                      Number(item?.quantity)
                                  ).toFixed(2)
                                : parseFloat(
                                    Number(item?.negotiation?.amount) *
                                      Number(item?.quantity)
                                  ).toFixed(2)}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td
                        className="height-light"
                        colSpan={
                          shortOrderDetails?.final_offer?.grand_total ? 2 : 0
                        }
                      >
                        Total
                      </td>
                      <td className="height-light">
                        ₹{parseFloat(TotalData?.total).toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td
                        className="height-light percent"
                        colSpan={
                          shortOrderDetails?.final_offer?.grand_total ? 2 : 0
                        }
                      >
                        {shortOrderDetails?.freight_charge_percentage}% Freight
                      </td>
                      <td className="height-light charge">
                        ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td
                        className="height-light"
                        colSpan={
                          shortOrderDetails?.final_offer?.grand_total ? 2 : 0
                        }
                      >
                        Total after freight
                      </td>
                      <td className="height-light totalFreight">
                        ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td
                        className="height-light"
                        colSpan={
                          shortOrderDetails?.final_offer?.grand_total ? 2 : 0
                        }
                      >
                        GST{" "}
                      </td>
                      <td className="height-light gst">
                        ₹
                        {(shortOrderDetails.status === "final_offer" ||
                          (shortOrderDetails.status === "rejected" &&
                            shortOrderDetails?.final_offer?.gst !== null)) &&
                          (shortOrderDetails?.final_offer.gst
                            ? shortOrderDetails?.final_offer.gst
                            : TotalData?.gst)}
                        {(shortOrderDetails.status === "revise_offer" ||
                          shortOrderDetails.status === "initial_offer" ||
                          (shortOrderDetails.status === "rejected" &&
                            shortOrderDetails?.negotiation?.gst !== null &&
                            shortOrderDetails?.final_offer?.gst === null)) &&
                          (shortOrderDetails?.negotiation.gst
                            ? shortOrderDetails?.negotiation.gst
                            : TotalData?.gst)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }} className="footer">
                      <td className="height-light">Profile ID</td>
                      <td className="height-light">
                        {shortOrderDetails?.user?.id}
                      </td>
                      <td colSpan={"2"} className="height-light">
                        Payment Terms
                      </td>
                      <td colSpan={"2"} className="height-light">
                        {shortOrderDetails?.payment_type}
                      </td>
                      <td
                        className="height-light"
                        colSpan={
                          shortOrderDetails?.final_offer?.grand_total ? 2 : 0
                        }
                      >
                        Total Invoice Value
                      </td>
                      <td className="height-light invoice">
                        {(shortOrderDetails.status === "final_offer" ||
                          shortOrderDetails.status === "rejected") &&
                          (shortOrderDetails?.final_offer?.grand_total
                            ? shortOrderDetails?.final_offer?.grand_total
                            : parseFloat(TotalData?.totolInvoice).toFixed(2))}
                        {(shortOrderDetails.status === "revise_offer" ||
                          shortOrderDetails.status === "initial_offer") &&
                          (shortOrderDetails?.negotiation?.grand_total
                            ? shortOrderDetails?.negotiation?.grand_total
                            : parseFloat(TotalData?.totolInvoice).toFixed(2))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}

              {shortOrderDetails?.status === "final_offer" ||
              shortOrderDetails?.status === "accepted" ||
              shortOrderDetails.can_edit === 1 ? (
                <div className="btn-wrap">
                  <button
                    className="button button-green button-green--bordered btn-release"
                    onClick={(e) => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to accept this offer  ?",
                        icon: "warning",
                        dangerMode: true,
                        buttons: ["Cancel", "Ok"],
                      }).then((willDelete) => {
                        setLoading(true);
                        if (willDelete) {
                          handleAcceptOrReject(
                            "accept",
                            shortOrderDetails?.id,
                            index
                          );
                        }
                        setLoading(false);
                      });
                    }}
                  >
                    <AcceptIcon />
                    Release Order
                  </button>
                  <button
                    className="button button-red button-red--bordered btn-reject"
                    onClick={(e) => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to reject this offer  ?",
                        icon: "warning",
                        dangerMode: true,
                        buttons: ["Cancel", "Ok"],
                      }).then((willDelete) => {
                        setLoading(true);
                        if (willDelete) {
                          handleAcceptOrReject(
                            "reject",
                            shortOrderDetails?.id,
                            index
                          );
                        }
                        setLoading(false);
                      });
                    }}
                  >
                    <RejectIcon />
                    Reject
                  </button>
                  <button
                    className="button button-primary button-primary--bordered"
                    onClick={() => {
                      if (user?.id === shortOrderDetails?.to_user_id) {
                        createThread(shortOrderDetails?.user_id);
                      } else {
                        createThread(shortOrderDetails?.to_user_id);
                      }
                    }}
                  >
                    <OrderMeetingIcon />
                    Meeting Room
                  </button>
                </div>
              ) : (
                ""
              )}
              {shortOrderDetails.can_edit === 0 &&
                shortOrderDetails.status !== "rejected" &&
                shortOrderDetails.status !== "completed" && (
                  <div
                    className="note"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      marginTop: "35px",
                      color: "red",
                      fontSize: "14px",
                    }}
                  >
                    <p>{`Waiting for ${
                      shortOrderDetails.user.is_buyer === 1 ? "seller" : "buyer"
                    }'s confirmation`}</p>
                    <Link
                      className="button button-green button-green--bordered btn-release"
                      to={`/negotiation-${
                        user.is_buyer === 1 ? "buyer" : "seller"
                      }`}
                    >
                      Go Back to Negotiation List
                    </Link>
                  </div>
                )}

              {shortOrderDetails?.status === "completed" && (
                <div
                  className="note"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "green",
                  }}
                >
                  <p>This transaction is completed</p>
                  <Link
                    className=" button button-green button-green--bordered btn-release"
                    to={"/transaction"}
                  >
                    Go Back to Transaction List
                  </Link>
                </div>
              )}
              {shortOrderDetails.can_edit === 0 &&
                shortOrderDetails.status === "rejected" && (
                  <div
                    className="note"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "35px",
                      color: "red",
                    }}
                  >
                    <p>Sorry! This Offer is already Rejected</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductNegotiationViewBuyer;
