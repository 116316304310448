const negotiationConstants = {
    DRAFT: "draft",
    INITIAL_OFFER: "initial_offer",
    REVISE_OFFER: "revise_offer",
    FINAL_OFFER: "final_offer",
    ACCEPTED: "accepted",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
    REJECTED: "rejected"
}
export default negotiationConstants;