import React from "react";

// import CircularProgress from "../components/CircularProgress";

const CompanyDataProfile = ({ companyData, isOwnProfile }) => {
  const networkData = [
    {
      label: "Customers",
      value: companyData?.total_network?.customers,
    },
    {
      label: "Retailers",
      value: companyData?.total_network?.retailers,
    },
    {
      label: "Dealers",
      value: companyData?.total_network?.dealers,
    },
  ];

  return (
    <>
      <div className="row">
        {networkData.map((data, index) => {
          if ((isOwnProfile || data.value > 0) && data.value !== undefined) {
            return (
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="col-sm-4"
                key={index}
              >
                <div className="chips w-100">
                  <div className="chips--value">{`${data.value}+`}</div>
                  <div className="chips--text">{data.label}</div>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default CompanyDataProfile;
