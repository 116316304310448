import React, { useContext, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../common/scss/pages/short-order.scss'
import '../common/scss/pages/ads.scss'
import Header from '../common/header'
import featuredTagIcon from '../assets/images/featured-tag.svg'
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import profilePic from "../assets/images/profile.png";
import { Link, useNavigate } from 'react-router-dom';
function AdsCampaignRunning () {
  return (
    <>
      <Header home />

      <div className='grey-bg'>
        <div className='container-fluid ads-page'>
          <div className='page-title'>
            <h6>Campaign Manager</h6>
            <div className='btn-wrap'>
              <button className='button button-primary'>Create Campaign Ad</button>
              <button className='button button-primary'>Create Sponsored Ad</button>
              <button className='button button-primary'>Buy Subscription</button>
            </div>
          </div>
          <div className="tabs tabs--solid">
                  <ul>
                    <li className="active">
                      <Link to={'/ads'}>Ads</Link>
                    </li>
                    <li>
                      <Link to={''}>Sponsored Ads</Link>
                    </li>
                    <li>
                      <Link to={'/ads-subscription'}>View Subscription</Link>
                    </li>
                  </ul>
                </div>
          <div className='card ads-selected ads-campaign ads-campaign--running'>
            <div className='ads-campaign-head'>
              <div className="tabs tabs--transparent">
                <ul>
                  <li className="active">
                    <Link to={'/ads-campaign-running'}>Running Campaign</Link>
                  </li>
                  <li>
                    <Link to={'/ads-campaign-expire'}>Expire Campaign</Link>
                  </li>
                  <li>
                    <Link to={'/ads-campaign-draft'}>Draft Campaign</Link>
                  </li>
                </ul>
              </div>
              <div className="short">
                  <select className="form-input"><option>Sort By</option><option>Oldest by Date</option><option>Newst By Date</option></select>
                </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
              <div className='selected-slot-week'>
                    <strong>You Choose June 3rd Week</strong>
                    <span>20th June to 26th June - Monday to Sunday</span>
                  </div>
                <div className='featured'>
                  <div className='user-profile'>
                  <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                  <div className='user-profile-content'>
                    <div className='user-profile-name'>
                      <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                      <p>Owner at mehta brothers</p>
                    </div>
                    <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                  </div>
                  <div className="more-btn">
                    <div className="nav-item dropdown account-dropdown">
                      <Link
                        to={""}
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <MoreIcon />
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                        >
                          Payment Details
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                        >
                          Support
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='featured-details'>
                  <ul>
                    <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                    <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                    <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                    <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                  </ul>
                  <div className='featured-details--highlights'>
                    <strong>Industry: Industrial Supplies</strong>
                  </div>
                  <div className='featured-details--highlights'>
                    <strong>Deals In</strong>
                    <p>Abrasives, Power Tools, Hand To-
                      ols, and Industrial Safty Tools</p>
                  </div>
                </div>
                  </div>
              </div>
              <div className='col-lg-4 col-md-6'>
              <div className='selected-slot-week'>
                    <strong>You Choose June 3rd Week</strong>
                    <span>20th June to 26th June - Monday to Sunday</span>
                  </div>
                <div className='featured'>
                  <div className='user-profile'>
                  <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                  <div className='user-profile-content'>
                    <div className='user-profile-name'>
                      <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                      <p>Owner at mehta brothers</p>
                    </div>
                    <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                  </div>
                  <div className="more-btn">
                    <div className="nav-item dropdown account-dropdown">
                      <Link
                        to={""}
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <MoreIcon />
                      </Link>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          className="dropdown-item"
                          href="#"
                        >
                          Payment Details
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                        >
                          Support
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='featured-details'>
                  <ul>
                    <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                    <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                    <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                    <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                  </ul>
                  <div className='featured-details--highlights'>
                    <strong>Industry: Industrial Supplies</strong>
                  </div>
                  <div className='featured-details--highlights'>
                    <strong>Deals In</strong>
                    <p>Abrasives, Power Tools, Hand To-
                      ols, and Industrial Safty Tools</p>
                  </div>
                </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default AdsCampaignRunning
