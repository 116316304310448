import { useContext, useEffect, useState } from "react";
import { ReactComponent as Close2Icon } from "../assets/images/close2-icon.svg";
import Auth from "../libs/auth";
import { post } from "../libs/http-hydrate";
import FieldValidationError from "../components/error-messages/field-validation-error";

function EditAboutModal(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }
  const user = Auth.getCurrentUser();
  const initialAboutData = props.data;

  const [aboutModalData, setAboutModalData] = useState();
  const [aboutText, setAboutText] = useState("");
  const [allValid, setAllValid] = useState();
  const [validateAbout, setValidateAbout] = useState();
  const [maxError, setMaxError] = useState(false);
  const [submitBtn, setSubmitBtn] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAboutModalData(initialAboutData);
    setAboutText(initialAboutData.about);
  }, [initialAboutData]);

  const handleAboutChange = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (aboutModalData.about != null) {
      formData.append("about", aboutText);
    }
    formData.append("name", aboutModalData.name);
    if (aboutModalData.company_details.details.designation !== null) {
      formData.append(
        "designation",
        aboutModalData.company_details.details.designation
      );
    }

    post("/user/profile/edit", formData, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((reponse) => {
        if (reponse.status === 200) {
          setLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {});
  };

  const handleAboutValidation = (value) => {
    if (value.length === 0) {
      setValidateAbout(true);
      setSubmitBtn(true);
      setAllValid(true);
    } else if (value.length > 255) {
      setMaxError(true);
      setSubmitBtn(true);
      setAllValid(true);
    } else {
      setValidateAbout(false);
      setMaxError(false);
      setSubmitBtn(false);
      setAllValid(false);
    }
  };

  const capitalizeFirstLowercaseRest = (str) => {
    let capitalizeStr =
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    setAboutText(capitalizeStr);
  };

  return (
    <>
      <div
        className="modal fade certifications-modal"
        id="AboutModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ContactModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              style={{
                borderBottom: darkMode
                  ? "1px solid #383a3d"
                  : "1px solid #BDBDBD",
              }}
              className="modal-header"
            >
              <h5 className="modal-title" id="exampleModalLabel">
                {" "}
                About
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <Close2Icon />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-field p-3 w-100">
                  <label className="form-label">
                    Edit Your About Section <span className="mendatory"></span>
                  </label>
                  <textarea
                    rows="5"
                    cols="50"
                    name="cname"
                    id="cname"
                    style={{
                      borderColor: "#c3c3c3",
                      padding: "10px",
                      width: "100%",
                      borderRadius: "5px",
                      border: darkMode
                        ? "1px solid #383a3d"
                        : "1px solid #BDBDBD",
                    }}
                    value={aboutText}
                    placeholder="Enter About"
                    onChange={(e) => {
                      setAboutText(e.target.value);
                      capitalizeFirstLowercaseRest(e.target.value);
                      handleAboutValidation(e.target.value);
                    }}
                  />
                  {validateAbout ? (
                    <FieldValidationError message="About should not be empty" />
                  ) : null}
                  {maxError ? (
                    <FieldValidationError message="Maximum 255 character allowed  " />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  borderTop: darkMode
                    ? "1px solid #383a3d"
                    : "1px solid #BDBDBD",
                }}
                className="modal-footer p-0 pt-3"
              >
                <button
                  type="button"
                  disabled={allValid || loading}
                  className="button button-primary"
                  style={{ borderRadius: "5px" }}
                  onClick={(e) => handleAboutChange(e)}
                >
                  {" "}
                  {loading ? "Uploading" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAboutModal;
