import React, { useState, useEffect } from "react";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/request.scss";
import TeslaIcon from "../assets/images/tesla-icon.jpg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as NoDataIcon } from "../assets/images/no-data.svg";
import Header from "../common/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";

function MyNetwork() {
  const [users, setUsers] = useState([]);
  const location = useLocation();
  const [connectionCount, setConnectionCount] = useState();
  const navigate = useNavigate();
  const currentUser = Auth.getCurrentUser();
  const [Filter, setFilter] = useState({
    search: "",
    filterValue: "",
  });
  UseEffectOnce(() => {
    fetchFriends();
  });

  const fetchFriends = async () => {
    if (location?.state != null && location?.state != undefined) {
      // alert(location.state);
    }
    await get("/connection/listing?type=friends", getAuthConfig())
      .then((res) => {
        setUsers(res.data.data.connections);
        setConnectionCount(res.data.data.total_connections);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (Filter?.filterValue === "") {
    } else {
      filterdata();
    }
  }, [Filter?.filterValue]);

  const RemoveConnection = async (id) => {
    const formdata = new FormData();
    formdata.append("user_id", id);
    await postwithOu("/connection/delete", getAuthConfig(), formdata)
      .then((datas) => {
        if (datas.status === 200) {
          // setUsers(datas.data.data);
          fetchFriends();
        }
      })
      .catch((e) => {
        if (e.response.data.status === 400) {
          toast.error(
            `Please complete your ongoing transactions to remove this user`
          );
        }
        if (e?.response?.data?.status === 401) {
          Auth.logout();
          navigate("/login");
        }
      });
  };

  const filterdata = async () => {
    let url;
    if (Filter.search != "") {
      url = `/connection/listing?type=friends&sort=${Filter?.filterValue}&search=${Filter?.search}`;
    } else {
      url = `/connection/listing?type=friends&sort=${Filter?.filterValue}`;
    }
    await get(url, getAuthConfig())
      .then((res) => {
        setUsers(res.data.data.connections);
      })
      .catch((err) => {});
  };

  const filterdataSearch = async (value) => {
    let url;
    if (Filter.filterValue != "") {
      url = `/connection/listing?type=friends&sort=${Filter?.filterValue}&search=${Filter?.search}`;
    } else {
      url = `/connection/listing?type=friends&search=${Filter?.search}`;
    }
    await get(url, getAuthConfig())
      .then((res) => {
        setUsers(res.data.data.connections);
        setConnectionCount(res.data.data.total_connections);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setTimeout(() => {
      filterdataSearch();
    }, 2000);
  }, [Filter?.search]);
  return (
    <>
      <ToastContainer />
      <Header home />
      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar />
            <div className="layout-grid">
              <div className="request-wrap">
                <h6>
                  {`Connections List ${connectionCount > 0 ? "-" : ""}`}
                  <span className="">
                    {connectionCount > 0 ? ` ${connectionCount}` : ''}
                  </span>
                </h6>
                <div className="request-wrap-head">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Search"
                      value={Filter?.search}
                      onChange={(e) => {
                        setFilter((p) => ({ ...p, search: e.target.value }));

                        // filterdataSearch(e.target.value);
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        clearInterval();
                      }}
                    />
                    <button
                      className="search-button"
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   if (Filter?.search != "") {
                      //     filterdataSearch();
                      //   }
                      // }}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                  <div className="short">
                    <select
                      className="form-input"
                      onChange={(e) => {
                        setFilter((p) => ({
                          ...p,
                          filterValue: e.target.value,
                        }));
                      }}
                    >
                      <option value={""}>Sort By</option>
                      <option value={"newest"}>Newest </option>
                      <option value={"oldest"}>Oldest</option>
                    </select>
                  </div>
                </div>
                <div className="request">
                  {users.map((user) => (
                    <div className="request-list user-profile" key={user.id}>
                      <Link to={`/edit-profile-seller/${user.slug}`}>
                        <div className="user-profile-image">
                          <img
                            src={user.profile_image}
                            className="profile-pic"
                            alt="profile-pic"
                          />
                        </div>
                      </Link>

                      <div className="user-profile-content">
                        <Link to={`/edit-profile-seller/${user.slug}`}>
                          <div className="user-profile-name">
                            <h6>
                              {user.name}
                              <span>(Profile ID: {user.id})</span>
                            </h6>
                            <p>
                              <img alt="" src={user?.company_details?.logo} />
                              <strong>
                                {user.i_am === "individual" ? "" : user.i_am}{" "}
                                {user.company_details.name
                                  ? `${user.i_am === "individual" ? "" : "-"} ${
                                      user.company_details.name
                                    }`
                                  : ""}
                              </strong>
                            </p>
                            <p>
                              Product Category:{" "}
                              <span>
                                {user.categories.length === 0 ? (
                                  <>N/A</>
                                ) : (
                                  <>
                                    {user.categories &&
                                      user.categories.map((category, index) => {
                                        return (
                                          <>
                                            {index === 0 ? " " : " , "}
                                            {category?.name}
                                          </>
                                        );
                                      })}
                                  </>
                                )}
                              </span>
                            </p>
                          </div>
                        </Link>
                        <button
                          onClick={() => {
                            swal({
                              title: "Are you sure?",
                              text: "Are you sure want to remove this user from the your business list ?",
                              icon: "warning",
                              dangerMode: true,
                              buttons: ["Cancel", "Ok"],
                            }).then((willDelete) => {
                              if (willDelete) {
                                RemoveConnection(user.id);
                              } else {
                              }
                            });
                          }}
                          className="button button-red button-remove"
                        >
                          Remove Connections
                        </button>
                      </div>
                    </div>
                  ))}

                  {users.length === 0 ? (
                    <div className="text-center w-100 no-record">
                      No Connection List Found
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <RightSideBar />
          </div>
        </div>
      </div>
    </>
  );
}

export default MyNetwork;
