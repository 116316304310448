import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { cloneDeep } from "lodash";
import { ReactComponent as SaveDraftIcon } from "../assets/images/save-draft.svg";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";

import Auth from "../libs/auth";
function PostNegotiationViewBuyer() {
  const location = useLocation();
  const SOID =
    location?.state?.shortOrder === true
      ? location?.state?.id
      : location?.state?.id;

  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState();
  const [value, setValue] = useState([]);
  const [GstAvg, setGstAvg] = useState(0);
  const navigate = useNavigate();
  const [TotalData, setTotalData] = useState({
    total: 0,
    freightCharge: 0,
    afterFreight: 0,
    gst: 0,
    totolInvoice: 0,
  });
  const [DisableAccept, setDisableAccept] = useState(false);

  useEffect(() => {
    TotalDataFunc();
  }, [products]);

  useEffect(() => {
    TotalDataFunc();
  }, [value]);

  useEffect(() => {
    FreightCharfe();
  }, [TotalData?.total]);
  useEffect(() => {
    afterFreight();
  }, [TotalData?.freightCharge]);

  useEffect(() => {
    GstWithAvg();
  }, [TotalData?.afterFreight]);

  useEffect(() => {
    FinalInvoic();
  }, [TotalData?.gst]);

  const user = Auth?.getCurrentUser();
  function TotalDataFunc() {
    let ttal = 0;

    products?.map((item, index) => {
      if (value[index] === undefined) {
        if (item?.final_offer?.amount != null) {
          ttal =
            ttal + Number(item?.quantity) * Number(item?.final_offer.amount);
        } else if (item?.negotiation?.amount != null) {
          ttal =
            ttal + Number(item?.quantity) * Number(item?.negotiation.amount);
        } else {
          ttal = ttal + Number(item?.quantity) * Number(item?.initial.amount);
        }
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        ttal = ttal + Number(item?.quantity) * Number(item?.initial.amount);
      } else {
        // alert(value[index])
        ttal = ttal + Number(item?.quantity) * Number(value[index]);
      }
    });
    if (value.length >= 1) {
      var res = value.filter((elements) => {
        return elements === "";
      });

      if (value.length === res.length) {
        setDisableAccept(false);
      } else {
        setDisableAccept(true);
      }
    }
    setTotalData((p) => ({ ...p, total: ttal }));
  }

  function FreightCharfe() {
    if (TotalData?.total > 0) {
      let fData =
        (Number(shortOrderDetails?.freight_charge_percentage) *
          Number(TotalData?.total)) /
        100;
      setTotalData((p) => ({ ...p, freightCharge: fData }));
    }
  }

  function afterFreight() {
    let Aftr = TotalData?.total + TotalData?.freightCharge;
    setTotalData((p) => ({ ...p, afterFreight: Aftr }));
  }
  function GstWithAvg() {
    let gst = 0;
    let freight = 0;

    products?.map((item, index) => {
      freight = 0;

      if (value[index] === undefined) {
        if (item?.final_offer?.amount != null) {
          freight =
            (Number(item?.quantity) *
              Number(item?.final_offer?.amount) *
              Number(shortOrderDetails?.freight_charge_percentage)) /
            100;

          gst =
            gst +
            ((Number(item?.quantity) * Number(item?.final_offer?.amount) +
              freight) *
              Number(item?.initial?.gst?.percentage)) /
              100;
        } else if (item?.negotiation?.amount != null) {
          freight =
            (Number(item?.quantity) *
              Number(item?.negotiation?.amount) *
              Number(shortOrderDetails?.freight_charge_percentage)) /
            100;

          gst =
            gst +
            ((Number(item?.quantity) * Number(item?.negotiation?.amount) +
              freight) *
              Number(item?.negotiation?.gst?.percentage)) /
              100;
        } else {
          freight =
            (Number(item?.quantity) *
              Number(item?.initial?.amount) *
              Number(shortOrderDetails?.freight_charge_percentage)) /
            100;

          gst =
            gst +
            ((Number(item?.quantity) * Number(item?.initial?.amount) +
              freight) *
              Number(item?.initial?.gst?.percentage)) /
              100;
        }
      } else if (value[index] <= 0) {
        // toast.error("Not Allowded Numbers");
        freight =
          (Number(item?.quantity) *
            Number(item?.initial.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.initial.amount) + freight) *
            Number(item?.initial.gst?.percentage)) /
            100;
      } else {
        // alert(value[index])
        freight =
          (Number(item?.quantity) *
            Number(value[index]) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(value[index]) + freight) *
            Number(item?.negotiation.gst?.percentage)) /
            100;
        // gst = gst + (Number(item?.quantity) * Number(value[index]));
      }
    });
    let gstW = (TotalData?.total * GstAvg) / 100;
    setTotalData((p) => ({ ...p, gst: gst }));
  }
  function FinalInvoic() {
    let Final = TotalData?.afterFreight + TotalData?.gst;

    setTotalData((p) => ({ ...p, totolInvoice: Final }));
  }

  const saveDraft = async (id) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one field");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price ");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        const price = value[i];
        if (price > 0) {
          formData.append(`negotiation[${i}][item_id]`, orderItemId);
          formData.append(`negotiation[${i}][amount]`, price);
        }
      }
      formData.append("negotiation_id", id);
      await post("/negotiation/save_draft", formData, getAuthConfig())
        .then((res) => {
          toast.success("Draft saved successfully");

          setTimeout(() => {
            navigate("/negotiation-seller");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  const sendNegotiation = async (action) => {
    let callApi = false;
    if (value?.length === 0) {
      toast.error("Please Enter Negotitation Price in atleast one fields  ");

      return false;
    } else if (value?.length >= 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] <= 0) {
          toast.error("Please Enter Valid Negotitation Price");

          return false;
        } else {
          callApi = true;
        }
      }
    }

    if (callApi === true) {
      const formData = new FormData();
      formData.append("negotiation_id", shortOrderDetails?.id);
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item?.id;
        // check if price is greater than 0
        const price = value[i];
        if (price > 0) {
          // append order_item_id and price to formData
          formData.append(`negotiation[${i}][item_id]`, item?.id);
          formData.append(`negotiation[${i}][amount]`, price);
          formData.append("type", "revise_offer");
        }
      }
      // formData.append("type", action);
      await post("/negotiation/send_offer", formData, getAuthConfig())
        .then((res) => {
          toast.success("Offer is sent successfully");
          setTimeout((e) => {
            navigate("/negotiation-buyer");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  const getProductCartSO = async () => {
    setLoading(true);
    let url;
    if (location?.state?.shortOrder === true) {
      url = `/short_order/details/${SOID}`;
    } else {
      url = `/negotiation/details/${SOID}`;
    }

    await get(url, getAuthConfig())
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
        let gstSumm = 0;

        res?.data?.data?.items.map((item, index) => {
          gstSumm = gstSumm + Number(item?.negotiation?.gst?.percentage);
        });
        setGstAvg(
          parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
        );
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
          navigate("/negotiation-buyer");
        }
      });
  };

  const handleBuyerNegotiationPrice = (e, index) => {
    const enteredPrice = e.target.value;
    setValue((prev) => {
      const newDivisions = cloneDeep(prev);
      newDivisions[index] = e.target.value;
      setIndex(index);

      // Total Gross Field Calculation
      const item = products[index];
      const qty = item?.quantity;
      const subTotalDifference = enteredPrice * qty;
      // const subTotalDifference = enteredPrice;

      // const td1 = e.target.parentNode.nextElementSibling;
      // td1.innerText = `₹${subTotalDifference}`;

      // Total Field Calculation
      let total = 0;
      let grandTotalDifference = 0;

      for (let i = 0; i < newDivisions.length; i++) {
        const grandTotal = shortOrderDetails?.negotiation.sub_total;
        total += parseFloat(newDivisions[i] || 0);
        grandTotalDifference = grandTotal - total;
      }

      return newDivisions;
    });
  };

  UseEffectOnce(() => {
    getProductCartSO();
  });

  const handleAcceptOrReject = async (action, id) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("negotiation_id", id);
    await post("/negotiation/action", formData, getAuthConfig())
      .then((res) => {
        if (action === "reject") {
          toast.success("Offer Rejected Successfully ");
          setTimeout(() => {
            navigate("/negotiation-buyer");
          }, 2000);
        }
        if (action === "accept") {
          toast.success("Offer Accepted Successfully ");
          getReleaseorder(id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  const getReleaseorder = async (id) => {
    await get(`/negotiation/release-short-order/${id}`, getAuthConfig())
      .then((res) => {
        // localStorage.setItem("SOID", res?.data?.data?.id);

        setTimeout(() => {
          navigate("/product-company-detail", {
            state: { id: res?.data?.data?.id },
          });
        }, 2000);
      })
      .catch((err) => {});
  };

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {});
  };
  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order product-order">
            <div className="page-title">
              <h6
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                Negotiation With{" "}
                {shortOrderDetails?.to_user?.company_details?.name || "-"}
              </h6>
            </div>
            <div className="order-table">
              <span
                style={{ fontSize: "16px", marginBottom: "5px" }}
                className="order-id"
              >
                <strong>Order ID #{shortOrderDetails?.id}</strong>
              </span>
              {!loading && products?.length ? (
                <table style={{ fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Category, Product Sub Category</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      {(shortOrderDetails.status === "revise_offer" ||
                        shortOrderDetails.status === "final_offer" ||
                        (shortOrderDetails.can_edit === 1 &&
                          shortOrderDetails.can_revise_offer === 1)) && (
                        <th className="height-light">
                          Required Price by Buyer
                        </th>
                      )}
                      {/* {shortOrderDetails?.can_revise_offer === 1 &&
                      shortOrderDetails.can_edit === 1 ? (
                        <th
                          className="height-light"
                          colSpan={
                            shortOrderDetails.can_revise_offer === 0 ? 2 : 0
                          }
                        >
                          Buyer Negotiation Price
                        </th>
                      ) : (
                        ""
                      )} */}
                      {shortOrderDetails?.final_offer?.sub_total != null ? (
                        <th colSpan={2}>Final Offer Price</th>
                      ) : (
                        ""
                      )}
                      <th colSpan={2}>Total Gross Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?.id}>
                            <td>{index + 1}</td>
                            <td>{`${item?.product?.category?.name}`}</td>
                            <td>
                              {item?.product?.brand?.name &&
                                `Brand: ${item?.product?.brand?.name},`}
                              {item?.product?.size &&
                                `Size: ${item?.product?.size},`}
                              {item?.product?.color?.name &&
                                `Color: ${item?.product?.color?.name}`}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.negotiation?.gst?.percentage}</td>
                            <td>₹{item?.initial?.amount}</td>
                            {(shortOrderDetails.status === "revise_offer" ||
                              shortOrderDetails.status === "final_offer") && (
                              <td className="height-light">
                                ₹{item.negotiation.amount}
                              </td>
                            )}
                            {item?.final_offer?.amount !== null && (
                              <td colSpan={2}>₹{item?.final_offer?.amount}</td>
                            )}

                            {shortOrderDetails?.can_revise_offer === 1 &&
                            shortOrderDetails.can_edit === 1 &&
                            shortOrderDetails.status !== "final_offer" ? (
                              <td
                                className="height-light"
                                colSpan={
                                  shortOrderDetails.can_revise_offer === 0
                                    ? 2
                                    : 0
                                }
                              >
                                <input
                                  placeholder="Enter Value"
                                  value={value[index]}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => {
                                    if (
                                      Number(e.target.value) >=
                                      Number(item?.initial?.amount)
                                    ) {
                                      swal(
                                        "warning",
                                        "Negotiation price must be less than seller offer price",
                                        "warning"
                                      );
                                    } else {
                                      handleBuyerNegotiationPrice(e, index);
                                    }
                                  }}
                                />
                              </td>
                            ) : (
                              ""
                            )}
                            <td
                              colSpan={
                                shortOrderDetails.status === "rejected" ||
                                shortOrderDetails.can_revise_offer === 0 ||
                                shortOrderDetails.items[0].final_offer
                                  .amount === null
                                  ? 2
                                  : 0
                              }
                            >
                              ₹
                              {value[index] != undefined && value[index] > 0
                                ? parseFloat(
                                    value[index] * item?.quantity
                                  ).toFixed(2)
                                : item?.final_offer?.sub_total != null
                                ? item?.final_offer?.sub_total
                                : item?.negotiation.sub_total != null
                                ? item?.negotiation.sub_total
                                : item?.initial.sub_total}
                            </td>
                          </tr>
                        </>
                      );
                    })}

                    {shortOrderDetails?.can_revise_offer === 1 ? (
                      <>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 3
                                : 0
                            }
                          >
                            Total
                          </td>
                          <td className="height-light">
                            ₹{parseFloat(TotalData?.total).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light percent"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 3
                                : 0
                            }
                          >
                            {shortOrderDetails?.freight_charge_percentage}%
                            Freight
                          </td>
                          <td className="height-light charge">
                            ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total !==
                              null
                                ? 3
                                : 0
                            }
                          >
                            Total after freight
                          </td>
                          <td className="height-light totalFreight">
                            ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 3
                                : 0
                            }
                          >
                            GST{" "}
                          </td>
                          <td className="height-light gst">
                            ₹{parseFloat(TotalData?.gst)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr className="footer">
                          <td className="height-light">Profile ID</td>
                          <td className="height-light">
                            {shortOrderDetails?.user?.id}
                          </td>
                          <td colSpan={"2"} className="height-light">
                            Payment Terms
                          </td>
                          <td colSpan={"2"} className="height-light">
                            {shortOrderDetails?.payment_type}
                          </td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 3
                                : 0
                            }
                          >
                            Total Invoice Value
                          </td>
                          <td
                            className="height-light invoice"
                            colSpan={
                              shortOrderDetails.can_revise_offer === 0 ? 3 : 0
                            }
                          >
                            ₹{parseFloat(TotalData?.totolInvoice).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="footer">
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 2
                                : 0
                            }
                          >
                            Total
                          </td>
                          <td className="height-light">
                            ₹{parseFloat(TotalData?.total).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light percent"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 2
                                : 0
                            }
                          >
                            {shortOrderDetails?.freight_charge_percentage}%
                            Freight
                          </td>
                          <td className="height-light charge">
                            ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 2
                                : 0
                            }
                          >
                            Total after freight
                          </td>
                          <td className="height-light totalFreight">
                            ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={"6"}></td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 2
                                : 0
                            }
                          >
                            GST{" "}
                          </td>
                          <td className="height-light gst">
                            ₹{parseFloat(TotalData?.gst)?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="height-light">Profile ID</td>
                          <td className="height-light">
                            {shortOrderDetails?.user?.id}
                          </td>
                          <td colSpan={"2"} className="height-light">
                            Payment Terms
                          </td>
                          <td colSpan={"2"} className="height-light">
                            {shortOrderDetails?.payment_type}
                          </td>
                          <td
                            className="height-light"
                            colSpan={
                              shortOrderDetails?.final_offer?.grand_total
                                ? 2
                                : 0
                            }
                          >
                            Total Invoice Value
                          </td>
                          <td
                            className="height-light invoice"
                            colSpan={
                              shortOrderDetails.can_revise_offer === 0 ? 3 : 0
                            }
                          >
                            ₹{parseFloat(TotalData?.totolInvoice).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}

              {shortOrderDetails?.can_edit === 1 ? (
                <div className="btn-wrap">
                  {shortOrderDetails.can_revise_offer === 1 &&
                    shortOrderDetails.status !== "final_offer" && (
                      <button
                        // to={"/product-company-detail"}
                        className="button button-primary button-primary--bordered"
                        onClick={() => {
                          sendNegotiation("initial_offer");
                        }}
                      >
                        <SendOfferIcon />
                        Send Revise Offer
                      </button>
                    )}
                  <button
                    // to={"/product-company-detail"}
                    className="button button-green button-green--bordered"
                    onClick={(e) => {
                      swal({
                        title: "Are you sure?",
                        text: "Are you sure you want to accept this offer  ?",
                        icon: "warning",
                        dangerMode: true,
                        buttons: ["Cancel", "Ok"],
                      }).then((willDelete) => {
                        setLoading(true);
                        if (willDelete) {
                          setLoading(false);
                          handleAcceptOrReject("accept", shortOrderDetails.id);
                        }
                        setLoading(false);
                      });
                    }}
                    disabled={
                      DisableAccept === true || shortOrderDetails.can_edit === 0
                        ? true
                        : false
                    }
                  >
                    <AcceptIcon />
                    {`${
                      shortOrderDetails.status !== "final_offer"
                        ? "Accept"
                        : "Release Short Order"
                    }`}
                  </button>
                  <button
                    // to={"/product-company-detail"}
                    className="button button-red button-red--bordered btn-reject"
                    onClick={() => {
                      handleAcceptOrReject("reject", shortOrderDetails.id);
                    }}
                  >
                    <RejectIcon />
                    Reject
                  </button>
                  {((shortOrderDetails.status === "initial_offer" &&
                    shortOrderDetails.can_revise_offer === 1) ||
                    shortOrderDetails.status === "draft") && (
                    <button
                      className="button button-blue button-blue--bordered"
                      onClick={() => {
                        saveDraft(shortOrderDetails.id);
                      }}
                      disabled={shortOrderDetails.status === "draft"}
                    >
                      <SaveDraftIcon />
                      Save Draft
                    </button>
                  )}

                  <button
                    className="button button-primary button-primary--bordered"
                    onClick={() => {
                      if (user?.id === shortOrderDetails?.to_user_id) {
                        createThread(shortOrderDetails?.user_id);
                      } else {
                        createThread(shortOrderDetails?.to_user_id);
                      }
                    }}
                  >
                    <OrderMeetingIcon />
                    Meeting Room
                  </button>
                </div>
              ) : shortOrderDetails?.status === "rejected" ? (
                <div
                  className="note"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "red",
                  }}
                >
                  <p>Sorry! This Offer is already Rejected</p>
                </div>
              ) : shortOrderDetails?.status === "completed" ? (
                <div
                  className="note"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "green",
                  }}
                >
                  <p>This transaction is completed</p>
                  <Link
                    className=" button button-green button-green--bordered btn-release"
                    to={"/transaction"}
                  >
                    Go Back to Transaction List
                  </Link>
                </div>
              ) : (
                <div
                  className="note"
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    marginTop: "35px",
                    color: "red",
                    fontSize: "14px",
                  }}
                >
                  <p>{`Waiting for ${
                    user.is_seller === 1 ? "buyer" : "seller"
                  }'s confirmation`}</p>
                  <Link
                    className="button button-green button-green--bordered btn-release"
                    to={`/negotiation-${
                      user.is_buyer === 1 ? "buyer" : "seller"
                    }`}
                  >
                    Go Back to Negotiation List
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostNegotiationViewBuyer;
