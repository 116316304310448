import React, { useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { ReactComponent as SaveDraftIcon } from "../assets/images/save-draft.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { cloneDeep } from "lodash";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";
import Auth from "../libs/auth";

function Draft() {
  const user = Auth?.getCurrentUser();
  const location = useLocation();
  const SOID =
    location?.state?.shortOrder === true
      ? location?.state?.id
      : location?.state?.id;

  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState();
  const [value, setValue] = useState([]);
  const [GstAvg, setGstAvg] = useState(0);
  const navigate = useNavigate();
  const [TotalData, setTotalData] = useState({
    total: 0,
    freightCharge: 0,
    afterFreight: 0,
    gst: 0,
    totolInvoice: 0,
  });

  useEffect(() => {
    TotalDataFunc();
  }, [products]);

  useEffect(() => {
    TotalDataFunc();
  }, [value]);

  useEffect(() => {
    FreightCharfe();
  }, [TotalData?.total]);
  useEffect(() => {
    afterFreight();
  }, [TotalData?.freightCharge]);

  useEffect(() => {
    GstWithAvg();
  }, [TotalData?.afterFreight]);

  useEffect(() => {
    FinalInvoic();
  }, [TotalData?.gst]);

  function TotalDataFunc() {
    let ttal = 0;

    products?.map((item, index) => {
      if (Number(item?.negotiation?.amount) === null) {
        ttal = ttal + Number(item?.quantity) * Number(item?.intial?.amount);
      } else if (Number(item?.negotiation?.amount) <= 0) {
        // toast.error("Not Allowded Numbers");
        ttal = ttal + Number(item?.quantity) * Number(item?.initial?.amount);
      } else {
        // alert(value[index])
        ttal =
          ttal + Number(item?.quantity) * Number(item?.negotiation?.amount);
      }
    });
    setTotalData((p) => ({ ...p, total: ttal }));
  }

  function FreightCharfe() {
    if (TotalData?.total > 0) {
      let fData =
        (Number(shortOrderDetails?.freight_charge_percentage) *
          Number(TotalData?.total)) /
        100;
      setTotalData((p) => ({ ...p, freightCharge: fData }));
    }
  }

  function afterFreight() {
    let Aftr = TotalData?.total + TotalData?.freightCharge;
    setTotalData((p) => ({ ...p, afterFreight: Aftr }));
  }
  function GstWithAvg() {
    let gst = 0;
    let freight = 0;
    products?.map((item, index) => {
      freight = 0;
      if (item?.negotiation?.amount === undefined) {
        freight =
          (Number(item?.quantity) *
            Number(item?.initial?.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.initial?.amount) + freight) *
            Number(item?.initial?.gst?.percentage)) /
            100;
      } else if (item?.negotiation?.amount <= 0) {
        freight =
          (Number(item?.quantity) *
            Number(item?.negotiation?.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.negotiation?.amount) +
            freight) *
            Number(item?.initial?.gst?.percentage)) /
            100;
      } else {
        freight =
          (Number(item?.quantity) *
            Number(item?.negotiation?.amount) *
            Number(shortOrderDetails?.freight_charge_percentage)) /
          100;

        gst =
          gst +
          ((Number(item?.quantity) * Number(item?.negotiation?.amount) +
            Number(freight)) *
            Number(item?.initial?.gst?.percentage)) /
            100;

        // gst = gst + (Number(item?.quantity) * Number(value[index]));
      }
    });
    let gstW = (TotalData?.total * GstAvg) / 100;
    setTotalData((p) => ({ ...p, gst: gst }));
  }
  function FinalInvoic() {
    let Final = TotalData?.afterFreight + TotalData?.gst;

    setTotalData((p) => ({ ...p, totolInvoice: Final }));
  }

  const sendNegotiation = async (action) => {
    let callApi = false;
    products?.map((item, index) => {
      if (Number(item?.negotiation?.amount) === null) {
      } else {
        callApi = true;
      }
    });

    if (callApi === true) {
      const formData = new FormData();
      formData.append("negotiation_id", shortOrderDetails?.id);
      for (let i = 0; i < products.length; i++) {
        const item = products[i];
        const orderItemId = item.id;

        // check if price is greater than 0
        const price = Number(item?.negotiation?.amount);
        if (price > 0) {
          // append order_item_id and price to formData
          formData.append(`negotiation[${i}][item_id]`, orderItemId);
          formData.append(`negotiation[${i}][amount]`, price);
        }
      }
      formData.append("type", action);
      await post("/negotiation/save_draft", formData, getAuthConfig())
        .then((res) => {
          if (action === "intial") {
            SendIntialOffre();
          } else {
            toast.success("Offer is sent successfully");

            setTimeout((e) => {
              navigate("/negotiation-buyer");
            }, 2000);
          }
        })
        .catch((err) => {});
    } else if (callApi === false) {
      toast.error("Apply Negotiation Value to atleast one Field");
    }
  };

  async function SendIntialOffre() {
    let url;
    url = `/negotiation/send_initial_offer/${location?.state?.id}`;

    await get(url, getAuthConfig())
      .then((res) => {
        toast.success("Intial Offer is sent successfully");
        setTimeout((e) => {
          navigate("/negotiation-buyer");
        }, 2000);
      })

      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          navigate("/negotiation-buyer");
        }
      });
  }

  const getProductCartSO = async () => {
    setLoading(true);
    let url;
    if (location?.state?.shortOrder === true) {
      url = `/short_order/details/${SOID}`;
    } else {
      url = `/negotiation/details/${SOID}`;
    }

    await get(url, getAuthConfig())
      .then((res) => {
        setLoading(false);
        setShortOrderDetails(res?.data?.data);
        setProducts(res?.data?.data?.items);
        let gstSumm = 0;
        let arr = [];
        res?.data?.data?.items.map((item, index) => {
          if (item?.negotiation?.amount != undefined) {
            arr[index] = item?.negotiation?.amount;
          } else {
            arr[index] = 0;
          }

          gstSumm = gstSumm + Number(item?.gst?.percentage);
        });

        setValue((result) => [...result, arr]);
        setGstAvg(
          parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
        );
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          localStorage.removeItem("SOID");
          navigate("/negotiation-buyer");
        }
      });
  };

  const handleBuyerNegotiationPrice = (e, index) => {
    let ar = {
      amount: e.target.value,
    };
    // 👇️ passing function to setData method
    setProducts((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === index) {
          return { ...obj, negotiation: ar };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });

      return newState;
    });
  };

  UseEffectOnce(() => {
    getProductCartSO();
  });

  const deleteProductCartSO = async (id) => {
    setLoading(true);
    await get(`/short_order/delete_item/${id}`, getAuthConfig())
      .then(() => {
        setLoading(false);
        getProductCartSO();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("/chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {});
  };

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div className="grey-bg">
        <div className="container-fluid order-page">
          <div className="order product-order">
            <div className="page-title">
              <h6>Buyer Send Negotiation Price / Buyer View</h6>
            </div>
            <div className="order-table">
              <span className="order-id">
                <strong>Order ID #BUM234324</strong>
              </span>
              {!loading && products?.length ? (
                <table>
                  <thead>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Category, Product Sub Category</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      <th className="height-light">Buyer Negotiation Price</th>
                      <th>Total Gross Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?.id}>
                            <td>{index + 1}</td>
                            <td>{`${item?.product?.category?.name}`}</td>
                            <td>
                              {item?.product?.brand?.name &&
                                `Brand: ${item?.product?.brand?.name},`}
                              {item?.product?.size &&
                                `Size: ${item?.product?.size},`}
                              {item?.product?.color?.name &&
                                `Color: ${item?.product?.color?.name}`}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.initial?.gst?.percentage}</td>
                            <td>
                              ₹
                              {shortOrderDetails?.payment_type === "advance"
                                ? item?.product?.amount
                                : item?.product?.credit_amount}
                            </td>
                            <td className="height-light">
                              <input
                                placeholder="Enter Value"
                                value={item?.negotiation?.amount}
                                type="number"                         onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  // item.negotiation.amount = e.target.value;
                                  handleBuyerNegotiationPrice(e, item?.id);
                                }}
                              />
                            </td>
                            <td>
                              ₹
                              {item?.negotiation?.amount != undefined &&
                              item?.negotiation?.amount > 0
                                ? parseFloat(
                                    Number(item?.negotiation?.amount) *
                                      item?.quantity
                                  ).toFixed(2)
                                : item?.initial?.sub_total}
                            </td>
                            <td>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  swal({
                                    title: "Are you sure?",
                                    text: "Are you sure you want to remove this item from cart ?",
                                    icon: "warning",
                                    dangerMode: true,
                                    buttons: ["Cancel", "Ok"],
                                  }).then((willDelete) => {
                                    setLoading(true);
                                    if (willDelete) {
                                      deleteProductCartSO(item?.id);
                                      setLoading(false);
                                    } else {
                                    }
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total</td>
                      <td className="height-light">
                        ₹{parseFloat(TotalData?.total).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light percent">
                        {shortOrderDetails?.freight_charge_percentage}% Freight
                      </td>
                      <td className="height-light charge">
                        ₹{parseFloat(TotalData?.freightCharge).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total after freight</td>
                      <td className="height-light totalFreight">
                        ₹{parseFloat(TotalData?.afterFreight)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"6"}></td>
                      <td className="height-light">GST </td>
                      <td className="height-light gst">
                        ₹{parseFloat(TotalData?.gst)?.toFixed(2)}
                      </td>
                    </tr>
                    <tr className="footer">
                      <td className="height-light">Profile ID</td>
                      <td className="height-light">
                        {shortOrderDetails?.user?.id}
                      </td>
                      <td colSpan={"2"} className="height-light">
                        Payment Terms
                      </td>
                      <td colSpan={"2"} className="height-light">
                        {shortOrderDetails?.payment_type}
                      </td>
                      <td className="height-light">Total Invoice Value</td>
                      <td className="height-light invoice">
                        ₹{parseFloat(TotalData?.totolInvoice).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}

              <div className="btn-wrap">
                <button
                  // to={"/product-company-detail"}
                  className="button button-primary button-primary--bordered"
                  onClick={() => {
                    sendNegotiation("intial");

                    // SendIntialOffre();
                  }}
                >
                  <SendOfferIcon />
                  Send Offer
                </button>
                <button
                  className="button button-blue button-blue--bordered"
                  onClick={() => {
                    sendNegotiation("draft");
                  }}
                  disabled={
                    shortOrderDetails?.status === "draft" ? true : false
                  }
                >
                  <SaveDraftIcon />
                  Save Draft
                </button>
                <button
                  className="button button-primary button-primary--bordered"
                  onClick={() => {
                    if (user?.id === shortOrderDetails?.to_user_id) {
                      createThread(shortOrderDetails?.user_id);
                    } else {
                      createThread(shortOrderDetails?.to_user_id);
                    }
                  }}
                >
                  <OrderMeetingIcon />
                  Meeting Room
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Draft;
