import moment from "moment";
import React from "react";

const TicketsList = ({ ticket, handleClick, isActive }) => {
  return (
    <>
      <ul style={{ cursor: "pointer" }} onClick={() => handleClick(ticket?.id)}>
        {/* <li className="sidebar-nav--item active">
          <div className="ticket-item">
            <div className="ticket-name">
              {ticket?.title}<span>Ad ID: #{ticket?.id}</span>
            </div>
            <div className="ticket-time">10th jan 2021</div>
          </div>
        </li> */}
        <li className={`sidebar-nav--item ${isActive ? "active" : ""}`}>
          <div className="ticket-item">
            <div className="ticket-name">
              {ticket?.title}
              <span>Order ID: #{ticket?.id}</span>
            </div>
            <div className="ticket-time">
              {moment(ticket?.created_at).format("Do MMM YYYY")}
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default TicketsList;
