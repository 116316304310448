import React from "react";

import closeIcon from "../../assets/images/ closeIconFill.svg";

const CoverImagePopup = ({
  showCoverImgModal,
  closeCoverModal,
  coverImage,
}) => {
  return (
    <>
      <div
        className={`modal fade ${showCoverImgModal ? "show d-block" : ""}`}
        id="ChangeCover"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ChangeCoverLabel"
        aria-hidden="true"
        style={{
          backgroundColor: showCoverImgModal
            ? "rgba(0,0,0,0.5)"
            : "transparent",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ChangeCoverLabel">
                Cover Image
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeCoverModal}
                aria-label="Close"
              >
                <img aria-hidden="true" src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <img
                src={coverImage}
                alt="Modal Cover Img"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverImagePopup;
