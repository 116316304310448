const notificationConstants = {
  CONNECTION_REQUEST: "connection_request",
  REACTION: "reaction",
  SOCIAL_POST: "social_post",
  NEGOTIATION: "negotiation",
  TRANSACTION: "transaction",
  COMMENT: "comment",
  SOCIAL_POST_CARD: "social_post_card",
};

export default notificationConstants;
