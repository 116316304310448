import Auth from "../libs/auth";
import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import "./scss/common/header.scss";
import { ReactComponent as LandingHomeIcon } from "../assets/images/landing-nav-home.svg";
import { ReactComponent as AboutIcon } from "../assets/images/landing-nav-about.svg";
import { ReactComponent as ContactIcon } from "../assets/images/landing-nav-contact.svg";
import logoSmall from "../assets/images/logo-small.svg";
import { ReactComponent as HomeIcon } from "../assets/images/home.svg";
import { ReactComponent as MeetingRoomIcon } from "../assets/images/meeting-room-icon.svg";
import { ReactComponent as RequestIcon } from "../assets/images/connect-new.svg";
import { ReactComponent as FeedsIcon } from "../assets/images/feeds.svg";
import { ReactComponent as NotificationIcon } from "../assets/images/notification.svg";
import { ReactComponent as MeetingRoomIconFilled } from "../assets/images/meeting-room-icon-filled.svg";
import { ReactComponent as SettingIcon } from "../assets/images/settings-icon.svg";
import { ReactComponent as FaqIcon } from "../assets/images/FAQ-new.svg";
import { ReactComponent as HelpIcon } from "../assets/images/help-support-new.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/logout-icon.svg";
import { ReactComponent as LightModeIcon } from "../assets/images/light-mode.svg";
import { ReactComponent as DarkModeIcon } from "../assets/images/dark-mode.svg";
import { ReactComponent as ToggleBuyIcon } from "../assets/images/toggle-buy.svg";
import { ReactComponent as ToggleSellIcon } from "../assets/images/toggle-sell.svg";
import { ReactComponent as HeartFilledIcon } from "../assets/images/heart-filled.svg";
import { ReactComponent as MyTransactionIcon } from "../assets/images/my-transaction-icon.svg";
import { ReactComponent as FormIcon } from "../assets/images/form-icon.svg";
import { ReactComponent as MyNetworkIcon } from "../assets/images/my-network-icon.svg";
import { ReactComponent as PendingIcon } from "../assets/images/pending-icon.svg";
import { ReactComponent as ManageAccountIcon } from "../assets/images/manage-account-icon.svg";
import { ReactComponent as MyPostIcon } from "../assets/images/my-post-icon.svg";
import { ReactComponent as ProtfolioIcon } from "../assets/images/protfolio-icon.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/negotiation-icon.svg";
import { ThemeContext } from "../context/Theme.context";
import { Link, useLocation } from "react-router-dom";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import { useNavigate } from "react-router-dom";
import { SwitchProfile } from "../context/switchProfile.context";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import { ToastContainer, toast } from "react-toastify";
import SellerModal from "../pages/sellerModal";
function Header(props) {
  const {
    login,
    home,
    buyerRole,
    Buyer,
    parentToChild,
    UpdateTrue,
    headerDataCall,
    disableSwitch,
    userType,
  } = props;

  const navigate = useNavigate();
  const currentUser = Auth.getCurrentUser();
  const { theme, toggleTheme, setLightThemeLogout, setDarkThemeLogout } =
    useContext(ThemeContext);
  const [counts, setCounts] = useState({});
  const { setBuyerAccount, setSellerAccount, toggleAccount, accountFor } =
    useContext(SwitchProfile);
  const location = useLocation();
  const [basic_details, setBasicDetails] = useState({});
  const onLogout = (e) => {
    e.preventDefault();
    if (
      window.location.pathname === "/settings" ||
      window.location.pathname === "/edit-profile" ||
      window.location.pathname === "/support"
    ) {
      Auth.logout();
      navigate("/");
    } else {
      Auth.logout();
      navigate("/login");
    }
    if (localStorage.getItem("logoutThemeDark") === "true") {
      setDarkThemeLogout();
    } else if (localStorage.getItem("logoutThemeDark") === "false") {
      setLightThemeLogout();
    }
    if (localStorage.getItem("buyerAccount") === "true") {
      setBuyerAccount();
    } else if (localStorage.getItem("buyerAccount") === "false") {
      setSellerAccount();
    }
  };
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [unread_Count, setUnread_count] = useState(0);
  useEffect(() => {
    setValue(Buyer);
  }, [Buyer]);

  UseEffectOnce(() => {
    if (home) {
      fetchDataT();
    }
  });
  UseEffectOnce(() => {
    // getUnreadMessageCount();
    getUnreadCount();
  }, []);

  async function fetchData() {
    try {
      const data = await postwithOu("/user/account_switch", getAuthConfig());
      if (data.status === 200) {
        toggleAccount();
        fetchDataT();

        if (UpdateTrue != undefined) {
          UpdateTrue();
          // navigate("/home")
        }

        if (parentToChild != undefined) {
          parentToChild(data?.data?.data?.slug);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }

  async function getUnreadCount() {
    if (Auth.getCurrentUser()) {
      try {
        const data = await get("/counters", getAuthConfig());
        if (data.status === 200) {
          setCounts(data?.data?.data);
        }
      } catch (error) {
        console.log(error);
        // toast.error(error?.response?.data?.message);
      }
    }
  }

  async function fetchDataT() {
    setLoading(true);

    if (headerDataCall) {
      headerDataCall(true);
    }
    await get("/user/profile/basic_details", getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          setBasicDetails(res?.data);
          if (userType) {
            userType(res?.data);
          }
          buyerRole(res.data.data.is_buyer);
          if (res?.data?.data?.is_seller === 1) {
            setSellerAccount(true);
            localStorage.setItem("accountFor", "seller");
          }
          if (res?.data?.data?.is_buyer === 1) {
            setBuyerAccount(true);
            localStorage.setItem("accountFor", "buyer");
          }
          const userData = res?.data?.data;

          localStorage.setItem(
            "user",
            JSON.stringify({
              name: userData.name,
              token: userData.token,
              slug: userData.slug,
              id: userData.id,
              is_buyer: userData.is_buyer,
              is_seller: userData.is_seller,
              profile_image: userData.profile_image,
              verified_at: userData.verified_at,
              cover_image: userData?.cover_image,
              email: userData?.email,
              mobile_number: userData?.mobile_number,
            })
          );
        }
        setLoading(false);
        if (headerDataCall) {
          headerDataCall(false);
        }
      })
      .catch((e) => {
        setLoading(false);

        if (e?.response?.status === 401) {
          Auth.logout();
          navigate("/login");
        }
        if (headerDataCall) {
          // toast.error(e?.response?.data?.message);
          headerDataCall(false);
        }
      });
  }
  async function getUnreadMessageCount() {
    await get("/chat/get_unread_count", getAuthConfig())
      .then((res) => {
        if (res?.data?.data?.unread_count) {
          setUnread_count(res?.data?.data?.unread_count);
        }
      })
      .catch((e) => {});
  }

  return (
    <>
      <ToastContainer />
      <header className={home ? "header header-home" : "header"}>
        <div className="container-fluid">
          <div className="grid header-logo">
            <Link to={"/"}>
              {home ? (
                <img src={logoSmall} className="App-logoSmall" alt="logo" />
              ) : (
                <img src={logo} className="App-logo" alt="logo" />
              )}
            </Link>
          </div>
          {login && (
            <>
              <div className="grid header-nav">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse login-nav"
                    id="navbarNavAltMarkup"
                  >
                    <div className="navbar-nav">
                      <Link
                        className={`nav-item nav-link ${
                          location.pathname.includes("about-us") ||
                          location.pathname.includes("contact-us")
                            ? ""
                            : "active"
                        }`}
                        to={"/"}
                      >
                        <span className="nav-icon">
                          <LandingHomeIcon />
                        </span>
                        Home
                      </Link>
                      <Link
                        className={`nav-item nav-link ${
                          location.pathname.includes("about-us") ? "active" : ""
                        }`}
                        to={"/about-us"}
                      >
                        <span className="nav-icon">
                          <AboutIcon />
                        </span>
                        About
                      </Link>
                      <Link
                        className={`nav-item nav-link ${
                          location.pathname.includes("contact-us")
                            ? "active"
                            : ""
                        }`}
                        to={"/contact-us"}
                      >
                        <span className="nav-icon">
                          <ContactIcon />
                        </span>
                        Contact Us
                      </Link>
                    </div>
                    <div className="grid header-buttons mobile-view">
                      <Link className="button button-primary" to={"/signup"}>
                        Join Now
                      </Link>
                      {/*<a href='' className='button button-secondary'>Sign In</a>*/}
                    </div>
                  </div>
                </nav>
              </div>
              <div className="grid header-buttons desktop-view">
                <Link to={"/signup"} className="button button-primary">
                  Join Now
                </Link>
                {/* <a href='' className='button button-secondary'>Sign In</a> */}
              </div>{" "}
            </>
          )}
          {home && (
            <>
              <div className="grid header-nav">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          to={"/"}
                          className={`nav-link ${
                            location.pathname.includes("/received-request") ||
                            location.pathname.includes("/sent-request") ||
                            location.pathname.includes("/received-request") ||
                            location.pathname.includes("/connection-feeds") ||
                            location.pathname.includes("/notification") ||
                            location.pathname.includes("/chat-room")
                              ? ""
                              : "active"
                          }`}
                          href="#"
                        >
                          <span className="nav-icon">
                            <HomeIcon />
                          </span>
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname.includes("/received-request") ||
                            location.pathname.includes("/sent-request")
                              ? "active"
                              : ""
                          }`}
                          to={"/received-request"}
                        >
                          <span className="nav-icon">
                            <RequestIcon />
                          </span>
                          Requests
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname.includes("/connection-feeds")
                              ? "active"
                              : ""
                          }`}
                          to={"/connection-feeds"}
                        >
                          <span className="nav-icon">
                            <FeedsIcon />
                          </span>
                          Connection Feeds
                        </Link>
                      </li>
                      {/*<li className='nav-item'>
                        <a className='nav-link' href='#'><span className='nav-icon'><CampaignIcon /></span>Campaign Manager</a>
                      </li>*/}
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname.includes("/notification")
                              ? "active"
                              : ""
                          }`}
                          to={"/notification"}
                        >
                          <span className="nav-icon">
                            <p className="counter-pill">
                              {counts?.notification > 99
                                ? "99+"
                                : counts?.notification}
                            </p>
                            <NotificationIcon />
                          </span>
                          Notifications
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname.includes("/chat-room")
                              ? "active"
                              : ""
                          }`}
                          to={"/chat-room"}
                        >
                          <span className="nav-icon">
                            <p className="counter-pill">
                              {counts?.chat > 99 ? "99+" : counts?.chat}
                            </p>
                            <MeetingRoomIcon />
                          </span>
                          Meeting Room
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="grid header-right">
                <ul>
                  <li>
                    {loading ? (
                      <div className="switch-box">
                        <Skeleton
                          height={35}
                          style={{ borderRadius: "26px" }}
                        />
                      </div>
                    ) : (
                      <div className="switch-box">
                        {basic_details?.data?.is_buyer === 1 &&
                        basic_details?.data?.verified_at === null &&
                        basic_details?.data?.we_are === null ? (
                          <input
                            type="checkbox"
                            id="user_switch"
                            checked={!basic_details?.data?.is_buyer}
                            disabled={disableSwitch ? true : false}
                            data-toggle="modal"
                            data-target="#sellerModal"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   fetchData();
                            // }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            id="user_switch"
                            disabled={disableSwitch ? true : false}
                            checked={!basic_details?.data?.is_buyer}
                            onClick={(e) => {
                              e.preventDefault();
                              fetchData();
                            }}
                          />
                        )}

                        <label htmlFor="user_switch">
                          <span className={"switch-icon"}>Buyer</span>
                          <span className={"switch-icon"}>Seller</span>
                        </label>
                      </div>
                    )}
                  </li>
                  <li>
                    <div className="switch-box">
                      <input
                        type="checkbox"
                        id="mode_switch"
                        onClick={toggleTheme}
                        checked={!theme.dark}
                      />
                      <label htmlFor="mode_switch">
                        <span className="switch-icon">
                          <DarkModeIcon />
                        </span>
                        <span className="switch-icon">
                          <LightModeIcon />
                        </span>
                      </label>
                    </div>
                  </li>
                  <li className="nav-item dropdown account-dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={currentUser?.profile_image}
                        className="profile-pic"
                      />
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        className="profile-name dropdown-item"
                        to={`/edit-profile-seller/${currentUser?.slug}`}
                      >
                        <img
                          src={currentUser?.profile_image}
                          className="profile-pic"
                        />
                        <span>{currentUser?.name}</span>
                      </Link>
                      <p className="profile-bio dropdown-item mobile-view">
                        {basic_details?.data?.about}
                      </p>
                      <div className="profile-followers dropdown-item mobile-view">
                        <HeartFilledIcon /> 130 people followed you
                      </div>
                      <div className="mobile-view profile-nav">
                        <Link
                          to={"/transaction"}
                          className="dropdown-item"
                          href="#"
                        >
                          <span className="nav-icon">
                            <MyTransactionIcon />
                          </span>
                          My Transactions
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          to={"/settings"}
                          state={{ PreFilled: "true" }}
                          className="dropdown-item"
                          href="#"
                        >
                          <span className="nav-icon">
                            <FormIcon />
                          </span>
                          Pre Filled Forms
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          to={"/my-network"}
                          className="dropdown-item"
                          href="#"
                        >
                          <span className="nav-icon">
                            <MyNetworkIcon />
                          </span>
                          My Network
                        </Link>
                        <div className="dropdown-divider" />
                        <Link
                          to={"/received-request"}
                          className="dropdown-item"
                          href="#"
                        >
                          <span className="nav-icon">
                            <PendingIcon />
                          </span>
                          Pending Requests
                        </Link>
                        {accountFor?.buyer === false && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              to={"/product-portfolio-initial"}
                              className="dropdown-item"
                            >
                              <span className="nav-icon">
                                <ProtfolioIcon />
                              </span>
                              Product Portfolio
                            </Link>
                          </>
                        )}
                        <div className="dropdown-divider" />
                        <Link
                          to={`${
                            accountFor?.buyer === true
                              ? "/negotiation-buyer"
                              : "/negotiation-seller"
                          }`}
                          className="dropdown-item"
                          href="#"
                        >
                          <span className="nav-icon">
                            <NegotiationIcon />
                          </span>
                          Negotiation
                        </Link>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#">
                          <span className="nav-icon">
                            <ManageAccountIcon />
                          </span>
                          Manage Account
                        </a>
                        <div className="dropdown-divider" />
                        <Link to={"/my-post"} className="dropdown-item">
                          <span className="nav-icon">
                            <MyPostIcon />
                          </span>
                          My Post
                        </Link>
                        <div className="dropdown-divider" />
                      </div>
                      <Link className="dropdown-item" to="/settings">
                        <span className="nav-icon">
                          <SettingIcon />
                        </span>
                        Settings
                      </Link>
                      <div className="dropdown-divider" />
                      {/* <Link className="dropdown-item" to="/support">
                        <span className="nav-icon">
                          <HelpIcon />
                        </span>
                        Help & Support
                      </Link> */}
                      {/* <div className="dropdown-divider" />
                      <Link className="dropdown-item" to="/ads">
                        <span className="nav-icon">
                          <HelpIcon />
                        </span>
                        Campaign Manager
                      </Link> */}
                      <div className="dropdown-divider" />
                      <Link className="dropdown-item" to="/faq">
                        <span className="nav-icon">
                          <FaqIcon />
                        </span>
                        FAQs
                      </Link>
                      <div className="dropdown-divider" />
                      <Link
                        className="dropdown-item logout-link"
                        to="/"
                        onClick={onLogout}
                      >
                        <span className="nav-icon">
                          <LogoutIcon />
                        </span>
                        Logout
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </header>

      <SellerModal />
    </>
  );
}

export default Header;
