import React from "react";
import Skeleton from "react-loading-skeleton";

const NotificationListSkeleton = () => {
  return (
    <>
      <div className="notification-item" style={{borderBottom:"none"}}>
        <div className="icon light-orange">
          <Skeleton width={45} height={45} />
        </div>
        <div className="notification-title">
          <Skeleton width={500} />
        </div>
      </div>
      <div className="notification-item" style={{borderBottom:"none"}}>
        <div className="icon light-orange">
          <Skeleton width={45} height={45} />
        </div>
        <div className="notification-title">
          <Skeleton width={500} />
        </div>
      </div>
      <div className="notification-item" style={{borderBottom:"none"}}>
        <div className="icon light-orange">
          <Skeleton width={45} height={45} />
        </div>
        <div className="notification-title">
          <Skeleton width={500} />
        </div>
      </div>
      <div className="notification-item" style={{borderBottom:"none"}}>
        <div className="icon light-orange">
          <Skeleton width={45} height={45} />
        </div>
        <div className="notification-title">
          <Skeleton width={500} />
        </div>
      </div>
      <div className="notification-item">
        <div className="icon light-orange">
          <Skeleton width={45} height={45} />
        </div>
        <div className="notification-title">
          <Skeleton width={500} />
        </div>
      </div>
    </>
  );
};

export default NotificationListSkeleton;
