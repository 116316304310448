import React, { useContext, useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

import Header from "../common/header";
import LeftSideBar from "./LeftSideBar";
import { SwitchProfile } from "../context/switchProfile.context";
import ChatRoom from "./chatroom";
import RightSideBar from "./RightSideBar";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";

const MeetingRoomMain = () => {
  const [HomeDatas, setHomeDatas] = useState({});
  const [Update, setSupdate] = useState(false);

  const { accountFor } = useContext(SwitchProfile);

  async function HomeData() {
    try {
      const data = await get("/home", getAuthConfig());
      if (data.status === 200) {
        setHomeDatas(data.data.data);

        localStorage.setItem("PENDREQ", data?.data?.data?.pending_request);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }

  UseEffectOnce(() => {
    HomeData();
  }, []);

  useEffect(() => {
    if (Update === true) {
      HomeData();
    }
    setSupdate(false);
  }, [Update]);

  return (
    <>
      <ToastContainer />
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar childFunc={accountFor?.buyer} />
            <div className="layout-grid">
              <ChatRoom />
            </div>
            {HomeDatas?.suggested_users ? (
              <RightSideBar Suggested={HomeDatas?.suggested_users} />
            ) : (
              <Skeleton height={500} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingRoomMain;
