import React from "react";
import { ReactComponent as AttachmentIcon } from "../../assets/images/attachment.svg";
import { ToastContainer, toast } from "react-toastify";
import Spinners from "../../common/Spinners";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import { useState } from "react";
import { useEffect } from "react";
import SupportOrderProduct from "./SupportOrderProduct";
import { useNavigate } from "react-router-dom";

const SupportForm = ({
  supportDetails,
  handleSubmit,
  handleChange,
  handleAttachment,
  loading,
  type,
  attachment,
  order,
}) => {
  const [contentLoading, setContentLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [attachedFileNames, setAttachedFileNames] = useState([]);

  const navigate = useNavigate();

  const getSubject = async () => {
    setContentLoading(true);
    try {
      const response = await get(
        `/general/content?search&type=${
          type === "issue" ? "support" : "support_short_order"
        }`,
        getAuthConfig()
      );
      setContentLoading(false);
      setContent(response?.data?.data);
    } catch (error) {
      setContentLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleAttachments = (e) => {
    const newFileNames = Array.from(attachment).map((file) => file.name);
    setAttachedFileNames(newFileNames);
  };
  useEffect(() => {
    getSubject();
  }, []);
  useEffect(() => {
    handleAttachments();
  }, [attachment]);

  const submittable =
    supportDetails?.subject &&
    supportDetails?.title &&
    supportDetails?.description;

  return (
    <>
      <ToastContainer />

      <form onSubmit={handleSubmit}>
        <div className="card small-box">
          <div className="card-body">
            <div className="support-issue">
              {/* {type === "order" && <SupportOrderProduct />} */}

              {order ? (
                <div className="orders">
                  <div className="order-item">
                    <div className="image-block">
                      <img
                        src={order?.products[0]?.thumb_image?.file}
                        alt="ProductImg"
                      />
                    </div>
                    <div className="content-block">
                      <h6>{order?.products[0]?.name}</h6>
                      <div className="company-name">
                        <img src={order?.to_user?.company?.logo} alt="" />
                        {order?.to_user?.company?.name}
                      </div>
                      <ul>
                        <li>
                          <label>Order ID</label>:<span>#{order?.id}</span>
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
              <div className="section-title">
                <h6>Help with your {type}</h6>
                <button
                  className="button button-primary"
                  onClick={() => {
                    navigate("/support-chat");
                  }}
                >
                  View Tickets
                </button>
              </div>
              <div className="form-field">
                <label className="form-label">
                  Select Subject About Your Issue
                </label>
                <select
                  disabled={contentLoading}
                  className="form-input"
                  name="subject"
                  onChange={handleChange}
                  value={supportDetails?.subject}
                >
                  {content?.map((item) => (
                    <>
                      <option>Select Subject</option>
                      <option value={item?.content}>{item?.title}</option>
                    </>
                  ))}
                </select>
              </div>
              <div className="form-field">
                <label className="form-label">Questions Title</label>
                <input
                  value={supportDetails?.title}
                  type="text"
                  onChange={handleChange}
                  name="title"
                  id="title"
                  className="form-input"
                  placeholder="Enter Your Questions Title"
                />
              </div>
              <div className="form-field">
                <label className="form-label">Query Description</label>
                <input
                  value={supportDetails?.description}
                  type="text"
                  name="description"
                  onChange={handleChange}
                  id="description"
                  className="form-input"
                  placeholder="Enter Your Query Description"
                />
              </div>
              <div className="form-field">
                <label className="form-label">
                  Upload any Query Related Attachment{" "}
                </label>
                <div className="file-upload">
                  <input
                    type="file"
                    id="upload_attachement"
                    multiple
                    onChange={handleAttachment}
                  />
                  <label htmlFor="upload_attachement">
                    Select Attachment
                    <span className="icon">
                      <AttachmentIcon />
                    </span>
                  </label>
                </div>
                <div className="attached-files">
                  {attachedFileNames.map((fileName, index) => (
                    <div key={index} className="attached-file">
                      {fileName}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button className="button button-secondary">Cancel</button>
            <button
              className="button button-primary"
              type="submit"
              disabled={loading || !submittable}
            >
              {loading ? <Spinners /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SupportForm;
