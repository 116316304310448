export const IconEdit = ({ width = 12, height = 12, fill = "#000" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6 2.57206L9.428 5.40072L2.828 12.0001H0V9.17139L6.6 2.57139V2.57206ZM7.54267 1.62939L8.95667 0.214722C9.08169 0.0897416 9.25122 0.0195312 9.428 0.0195312C9.60478 0.0195312 9.77432 0.0897416 9.89933 0.214722L11.7853 2.10072C11.9103 2.22574 11.9805 2.39528 11.9805 2.57206C11.9805 2.74883 11.9103 2.91837 11.7853 3.04339L10.3707 4.45739L7.54267 1.62939Z"
      fill={fill}
    />
  </svg>
);

export const IconVisitCard = ({ width = 28, height = 22, fill = "black" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.7174 0.5H3.84401C2.13239 0.5 0.741211 1.9385 0.741211 3.70833V18.2917C0.741211 20.0615 2.13239 21.5 3.84401 21.5H24.7174C26.429 21.5 27.8202 20.0615 27.8202 18.2917V3.70833C27.8202 1.9385 26.429 0.5 24.7174 0.5ZM9.2034 5.16667C10.7582 5.16667 12.0241 6.47566 12.0241 8.08333C12.0241 9.691 10.7582 11 9.2034 11C7.64861 11 6.38267 9.691 6.38267 8.08333C6.38267 6.47566 7.64861 5.16667 9.2034 5.16667ZM14.2807 15.9583C14.2807 16.4413 13.9016 16.8333 13.4345 16.8333H4.9723C4.50519 16.8333 4.12609 16.4413 4.12609 15.9583V15.375C4.12609 13.6052 5.51727 12.1667 7.22889 12.1667H11.1779C12.8895 12.1667 14.2807 13.6052 14.2807 15.375V15.9583ZM23.5891 16.8333H17.3835C16.9164 16.8333 16.5373 16.4413 16.5373 15.9583C16.5373 15.4753 16.9164 15.0833 17.3835 15.0833H23.5891C24.0562 15.0833 24.4353 15.4753 24.4353 15.9583C24.4353 16.4413 24.0562 16.8333 23.5891 16.8333ZM23.5891 12.1667H17.3835C16.9164 12.1667 16.5373 11.7747 16.5373 11.2917C16.5373 10.8087 16.9164 10.4167 17.3835 10.4167H23.5891C24.0562 10.4167 24.4353 10.8087 24.4353 11.2917C24.4353 11.7747 24.0562 12.1667 23.5891 12.1667ZM23.5891 7.5H17.3835C16.9164 7.5 16.5373 7.108 16.5373 6.625C16.5373 6.142 16.9164 5.75 17.3835 5.75H23.5891C24.0562 5.75 24.4353 6.142 24.4353 6.625C24.4353 7.108 24.0562 7.5 23.5891 7.5Z"
      fill={fill}
    />
  </svg>
);

export const IconCheckmark = ({ width = 19, height = 19, fill = "black" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    id="Layer_1"
  >
    <path
      d="M26,52A26,26,0,1,1,52,26,26,26,0,0,1,26,52ZM26,4A22,22,0,1,0,48,26,22,22,0,0,0,26,4Z"
      fill={fill}
    />
    <path
      d="M23,37.6A2,2,0,0,1,21.59,37l-9.2-9.19A2,2,0,0,1,15.22,25l9.19,9.19A2,2,0,0,1,23,37.6Z"
      fill={fill}
    />
    <path
      d="M23,37.6a2,2,0,0,1-1.41-3.42L36.78,19a2,2,0,0,1,2.83,2.83L24.41,37A2,2,0,0,1,23,37.6Z"
      fill={fill}
    />
  </svg>
);

export const IconFlag = ({ width = 18, height = 19, fill = "#363636" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H9.382C9.56766 9.91486e-05 9.74963 0.0518831 9.90753 0.149552C10.0654 0.247222 10.193 0.386919 10.276 0.553L11 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V14C18 14.2652 17.8946 14.5196 17.7071 14.7071C17.5196 14.8946 17.2652 15 17 15H10.618C10.4323 14.9999 10.2504 14.9481 10.0925 14.8504C9.93458 14.7528 9.80699 14.6131 9.724 14.447L9 13H2V19H0V0Z"
      fill={fill}
    />
  </svg>
);

export const IconBlock = ({ width = 22, height = 22, fill = "#363636" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476617 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM2.00001 11C1.99921 8.95917 2.69607 6.97938 3.975 5.389L16.611 18.025C15.2885 19.085 13.6933 19.7496 12.0094 19.942C10.3254 20.1344 8.6214 19.8468 7.0939 19.1123C5.56639 18.3779 4.27765 17.2266 3.37635 15.7912C2.47505 14.3558 1.99792 12.6949 2.00001 11ZM18.025 16.611L5.389 3.975C7.11623 2.59041 9.29363 1.89184 11.504 2.01317C13.7144 2.13449 15.8022 3.06716 17.3675 4.63247C18.9328 6.19779 19.8655 8.28565 19.9868 10.496C20.1082 12.7064 19.4096 14.8838 18.025 16.611Z"
      fill={fill}
    />
  </svg>
);

export const IconUploadCloud = ({
  width = 32,
  height = 32,
  stroke = "#000000",
  fill = "none",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M23.995,13.089C23.996,13.059,24,13.03,24,13c0-4.418-3.582-8-8-8c-3.814,0-6.998,2.671-7.8,6.242C5.208,12.038,3,14.757,3,18c0,3.866,3.134,7,7,7h13c3.314,0,6-2.686,6-6C29,16.026,26.834,13.564,23.995,13.089z"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="16"
      y1="11"
      x2="16"
      y2="21"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <polyline
      points="12,15 16,11 20,15"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);
