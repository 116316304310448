import React from "react";
import logoSmall from "../../assets/images/logo-small.svg";
import { ReactComponent as MoreIcon } from "../../assets/images/more-icon.svg";
import { Link } from "react-router-dom";

const SupportChatHeader = () => {
  return (
    <div className="chat-header">
      <div className="logo">
        <img src={logoSmall} alt="Logo" />
        BusiMeet Support
      </div>
      <div className="more-btn">
        <div className="nav-item dropdown account-dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to=""
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <MoreIcon />
          </Link>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <Link className="dropdown-item" to="">
              Report
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportChatHeader;
