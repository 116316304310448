import React, { useCallback, useEffect, useRef, useState } from "react";
import "../common/scss/pages/settings.scss";
import "../common/scss/pages/support.scss";
import Header from "../common/header";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { Link, useLocation } from "react-router-dom";
import SupportOrderListing from "../components/Support/SupportOrderListing";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { toast } from "react-toastify";
import SupportLeftSidebar from "../components/Support/SupportLeftSidebar";

function SupportOther() {
  const [orderListing, setOrderListing] = useState({
    has_more: false,
    items: [],
  });
  const [page, setPage] = useState(0);

  const [searching, setSearching] = useState("");

  const searchShortOrder = (e) => {
    setSearching(e.target.value);
  };

  const shortOrderListing = async () => {
    await get(
      `/short_order/listing?page=${page + 1}&search=${searching}`,
      getAuthConfig()
    )
      .then((res) => {
        if (page === 0) {
          if (res?.data?.data?.data.length > 0) {
            setOrderListing((p) => ({
              ...p,
              items: res?.data?.data?.data,
            }));
          } else {
            setOrderListing((p) => ({
              ...p,
              items: [],
            }));
          }
        } else {
          setOrderListing((p) => ({
            ...p,
            items: orderListing.items.concat(res?.data?.data?.data),
          }));
        }
        if (res?.data?.data?.has_more === true) {
          setOrderListing((p) => ({ ...p, has_more: true }));
        } else {
          setOrderListing((p) => ({ ...p, has_more: false }));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListing?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [orderListing?.has_more]
  );

  useEffect(() => {
    if (searching !== "") {
      shortOrderListing();
    }
  }, [searching]);

  useEffect(() => {
    if (page >= 0) {
      shortOrderListing();
    }
  }, [page]);

  const location = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
  }, [location]);

  return (
    <>
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="page-title">
            <h6>BusiMeet Help Center | 24x7 Customer Care Support</h6>
          </div>
          <div className="layout-grid-box-column2 ">
            <SupportLeftSidebar />
            <div className="layout-grid layout-grid--main">
              <div className="card small-box">
                <div className="card-body">
                  <div className="support-issue">
                    <div className="section-title">
                      <h6>Select Your Order and Other</h6>
                    </div>
                    <div className="support-other">
                      <div className="tabs tabs--solid">
                        <ul>
                          <li className="active">
                            <Link to={""}>Orders</Link>
                          </li>
                          <li>
                            <Link to="">Ads</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Search"
                          value={searching}
                          onChange={searchShortOrder}
                        />
                        <button className="search-button">
                          <SearchIcon />
                        </button>
                      </div>
                    </div>
                    {orderListing?.items?.map((order) => (
                      <div
                        className="orders"
                        key={order?.id}
                        ref={lastBookElementRef}
                      >
                        <SupportOrderListing order={order} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportOther;
