import React from 'react'
import '../common/scss/pages/short-order.scss'
import '../common/scss/pages/ads.scss'
import Header from '../common/header'
import { ReactComponent as BusiMeetPremiumIcon } from '../assets/images/busimeet-premium.svg'
import { Link, useNavigate } from 'react-router-dom';
function AdsSubscription () {
  return (
    <>
      <Header home />

      <div className='grey-bg'>
        <div className='container-fluid ads-page'>
          <div className='page-title'>
            <h6>Campaign Manager</h6>
            <div className='btn-wrap'>
              <button className='button button-primary'>Create Campaign Ad</button>
              <button className='button button-primary'>Create Sponsored Ad</button>
              <button className='button button-primary'>Buy Subscription</button>
            </div>
          </div>
          <div className="tabs tabs--solid">
                  <ul>
                    <li>
                      <Link to={'/ads'}>Ads</Link>
                    </li>
                    <li>
                      <Link to={''}>Sponsored Ads</Link>
                    </li>
                    <li className="active">
                      <Link to={'/ads-subscription'}>View Subscription</Link>
                    </li>
                  </ul>
                </div>
          <div className='card ads-selected ads-campaign ads-campaign--expire'>
            <div className='ads-campaign-head'>
              <div className="tabs tabs--transparent">
                <ul>
                  <li className="active">
                    <Link to={''}>Current Subscription</Link>
                  </li>
                  <li>
                    <Link to={''}>Past Subscription</Link>
                  </li>
                </ul>
              </div>
              <div className="short">
                  <select className="form-input"><option>Sort By</option><option>Oldest by Date</option><option>Newst By Date</option></select>
                </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
               <div className='subscription'>
                <div className='subscription-head'>
                  <BusiMeetPremiumIcon /> <strong>BusiMeet Premium</strong>
                </div>
                <p>Pro account gives you freedom choose Campaign week slot in advanced for yearly, give primium badge and also give you 24/7 customer support.</p>
                <div className='subscription-price'>1199<span>/Year</span></div>
                <div className='subscription-plan'>
                  <ul>
                    <li>You can avail one slot for campaign and one slot for featured ads</li>
                    <li>Cancel anytime</li>
                  </ul>
                </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default AdsSubscription
